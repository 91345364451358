import { useCallback, useEffect, useState } from "react";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import {
  useCartContext,
  COLLECT_SUCCESS,
  COLLECT_SUCCESS_CLEAR,
} from "react-app/src/components/Cart/CartState";
import { useTracking } from "react-app/src/contexts/Tracking";
import {
  addMultipackBundleToCart,
  type addMulitpackBundleToCartConfigProps,
} from "react-app/src/components/Cart/Services/addMultipackBundleToCart";
import {
  getPackageUnitName,
  isSoldInPackages,
  transformSizeIdToStyle,
} from "react-app/src/components/Product/product-helper";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { dispatchAddToCartEvent } from "react-app/src/utils/xxl-event";
import type { FrontendProductData } from "react-app/src/hooks/useProductData/useProductData.types";
import type { Unit } from "@xxl/pim-api";
import { QUANTITY_ONE } from "react-app/src/constants";

type AddToCartProps = {
  ean: string;
  quantity?: number;
  storeId?: string;
  ticket?: string;
};

const BusyState = {
  LOADING_REGULAR_ADD_TO_CART: "LOADING_REGULAR_ADD_TO_CART",
  LOADING_CC_ADD_TO_CART: "LOADING_CC_ADD_TO_CART",
  READY: "READY",
  COLLECT_SUCCESS,
  COLLECT_SUCCESS_CLEAR,
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
};

export type BusyState = keyof typeof BusyState;

const BUNDLE_MULTIPACK_PERSONALIZED_KEY = "bundle_multipack";
const BUTTON_SUCCESS_TIMEOUT = 2000;

export const useAddToCart = ({
  productData,
  selectedProductCode,
}: {
  productData: FrontendProductData;
  selectedProductCode?: string;
}) => {
  const { state, dispatch } = useCartContext();
  const { isAddingToCart, isCollectSuccess, isAddToCartSuccess } = state;

  const [addToCartState, setAddToCartState] = useState<BusyState>("READY");
  const {
    configuration: {
      amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
    },
    featureToggles: { toggle_products_as_package_quantity },
    pageType,
  } = useSharedData().data;
  const trackers = useTracking();
  const trackingListName = `${pageType}_${BUNDLE_MULTIPACK_PERSONALIZED_KEY}`;
  const {
    name,
    brand,
    categoryCode,
    multipackEan,
    priceDisplay,
    primaryImage: primaryImageUrl,
    productType,
    sizeOptions,
    units,
  } = productData;
  const isMultiPackProduct = productType === "MULTIPACK";
  const isSizeCode =
    sizeOptions?.some(({ code }) => code === selectedProductCode) ?? false;
  const styleCode = isNotEmpty(selectedProductCode)
    ? isSizeCode
      ? transformSizeIdToStyle(selectedProductCode)
      : selectedProductCode
    : undefined;

  const unit =
    isSoldInPackages(units) &&
    toggle_products_as_package_quantity &&
    sizeOptions?.length === 1
      ? getPackageUnitName(units as Unit[])
      : undefined;

  useEffect(() => {
    if (!isAddingToCart) {
      setAddToCartState("READY");
    }
  }, [isAddingToCart]);

  useEffect(() => {
    if (isCollectSuccess === true) {
      setAddToCartState(COLLECT_SUCCESS);

      setTimeout(function () {
        setAddToCartState("READY");
        dispatch({
          type: COLLECT_SUCCESS_CLEAR,
        });
      }, BUTTON_SUCCESS_TIMEOUT);
    }
  }, [isCollectSuccess]);

  useEffect(() => {
    if (isAddToCartSuccess === true) {
      setAddToCartState("ADD_TO_CART_SUCCESS");
      setTimeout(function () {
        setAddToCartState("READY");
        dispatch({
          type: COLLECT_SUCCESS_CLEAR,
        });
      }, BUTTON_SUCCESS_TIMEOUT);
    }
  }, [isAddToCartSuccess]);

  const addToCart = useCallback(
    ({ ean, quantity, storeId, ticket }: AddToCartProps) => {
      setAddToCartState(
        storeId === undefined
          ? "LOADING_REGULAR_ADD_TO_CART"
          : "LOADING_CC_ADD_TO_CART"
      );

      if (
        isMultiPackProduct &&
        isNotNullOrUndefined(multipackEan) &&
        isNotNullOrUndefined(units)
      ) {
        const options: addMulitpackBundleToCartConfigProps = {
          bundleEan: multipackEan,
          bundledProductEan: ean,
          units,
          dispatch,
          trackers,
          graphqlEndpoint: aws_appsync_graphqlEndpoint,
          graphqlApiKey: aws_appsync_apiKey,
          trackingListName,
          quantity,
        };
        void addMultipackBundleToCart(options);
      } else {
        const sizeData = sizeOptions?.find(
          ({ ean: sizeEan }) => sizeEan === ean
        );

        dispatchAddToCartEvent({
          brandName: brand?.name ?? "",
          categoryCode,
          eanCode: ean,
          imageUrl: primaryImageUrl ?? "",
          productName: name ?? "",
          quantity: quantity ?? QUANTITY_ONE,
          salesPrice: priceDisplay.salesPrice,
          salesPriceFormatted: priceDisplay.salesPriceFormatted,
          sizeName: sizeData?.size,
          sizeCode: sizeData?.code,
          storeId,
          styleCode: styleCode ?? "",
          unit,
          ticket,
        });
      }
    },
    [
      brand,
      categoryCode,
      priceDisplay,
      primaryImageUrl,
      name,
      sizeOptions,
      styleCode,
    ]
  );

  return {
    addToCart,
    addToCartState,
    isAddingToCart,
    setAddToCartState,
    unit,
    isAddToCartSuccess,
    isCollectSuccess,
  };
};
