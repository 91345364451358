import { isNotNullOrUndefined } from "@xxl/common-utils";
import React, { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import { useRelinkValue } from "react-relink";
import { CART_BASE_PATH } from "../../constants";
import { useSessionSource } from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import type { TranslationsContext } from "../../contexts/Translations/TranslationsContext";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { type CartItemType } from "../../generated/graphql-code-generator";
import { useClient } from "../../hooks/useClient/useClient";
import { usePaymentProvider } from "../../hooks/usePaymentProvider/usePaymentProvider";
import type { TranslationKey } from "../../translations";
import { setInnerHtml } from "../../utils/xxl-set-inner-html";
import { XXLLoader } from "../XXLLoader";
import {
  PaymentType,
  SUCCESS_RESPONSE_STATUS,
  updateCheckoutSnippet,
} from "./Api/CartAPI";
import type { CartItem } from "./Api/types";
import {
  getCartBuyableForCnC4GoodsOnlyInStore,
  getDefaultCustomerType,
} from "./cart-page-helper";
import {
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  UPDATE_CART_COUNT,
  UPDATE_CART_ERRORS,
  UPDATE_CART_ID,
  UPDATE_CART_ITEMS,
  UPDATE_PAYMENT_PROVIDER,
  UPDATE_PAYMENT_SNIPPET_HTML,
  useCartContext,
} from "./CartState";
import { BackButton } from "./Components/BackButton";
import { CartProducts } from "./Components/CartProducts";
import {
  LOCAL_STORAGE_SHOP_AS_KEY,
  type LocalStorageShopAsOptionType,
  ShopAsSwitch,
} from "./Components/ShopAsSwitch";
import { initializeCheckout } from "./Services/checkout";
import { getCartErrorMessages } from "./Services/getCartErrorMessages";
import {
  BackButtonStyled,
  BackButtonWrapper,
  CartErrorCollectStoreNotBuyable,
  CheckoutWrapper,
  Container,
  EmptyPageContainer,
  EmptyPageHeading,
  EmptyPageImage,
  ErrorItem,
  ErrorsList,
  KlarnaWrapper,
  OutOfStockWrapper,
  PageSection,
  ReloadButtonWrapper,
} from "./Styles/CartContent.styled";
import { Title } from "./Styles/CartProducts.styled";
import { WalleyCheckoutEvents } from "./constants";

export type CartContentProps = {
  isTeamAdmin: boolean;
  isTeamsalesAdmin?: boolean;
};

type WalleyValidationError = {
  articleNumber: string;
  ean: string;
  itemId: {
    id: number;
    type: CartItemType;
  };
  quantity: number;
  stockLevel: number;
};

type WalleyValidationResponse = {
  payload?: {
    invalidItems?: WalleyValidationError[];
  };
};

const getCheckoutErrors = ({
  errors,
  t,
}: {
  errors: string[] | undefined;
  t: TranslationsContext["t"];
}) => (
  <>
    <ErrorsList>
      {errors?.map((item, index) => (
        <ErrorItem key={`error-${index}`}>
          {t(item as TranslationKey)}
        </ErrorItem>
      ))}
    </ErrorsList>
    <a
      href={CART_BASE_PATH}
      className="button button--accent button--large button--full-width"
    >
      {t("cart.go.to.checkout")}
    </a>
  </>
);

export const CartContent: React.FunctionComponent<CartContentProps> = ({
  isTeamAdmin,
  isTeamsalesAdmin,
}) => {
  const { state, dispatch } = useCartContext();
  const { configuration } = useSharedData().data;
  const defaultPaymentProvider = usePaymentProvider(PaymentType.CHECKOUT);
  const [cartHasProducts, setCartHasProducts] = useState(
    Boolean(state.displayCart?.items.length)
  );
  const isClient = useClient();
  const loadingSnippet = useRef(false);
  const { t } = useTranslations();
  const queryParameters = new URLSearchParams(
    isClient ? window.location.search : ""
  );
  const errorMessages = queryParameters.get("errorMessages");
  const [cartHasRequestErrors, setCartHasRequestErrors] = useState(false);
  const cartNotBuyable = getCartBuyableForCnC4GoodsOnlyInStore(
    state.cart?.data ?? undefined
  );
  const [walleyValidationErrors, setWalleyValidationErrors] = useState<
    WalleyValidationError[]
  >([]);
  const isLoggedIn = useRelinkValue(useSessionSource);

  if (
    errorMessages !== null &&
    errorMessages.length > 0 &&
    state.displayCart !== undefined &&
    (state.displayCart.errors === undefined ||
      state.displayCart.errors.length === 0)
  ) {
    dispatch({
      type: UPDATE_CART_ERRORS,
      payload: getCartErrorMessages(
        state.displayCart,
        errorMessages.split(",")
      ),
    });
  }
  const hasErrors =
    state.displayCart !== undefined &&
    state.displayCart.errors !== undefined &&
    state.displayCart.errors.length > 0;

  const reloadCart = () => {
    dispatch({
      type: UPDATE_CART_COUNT,
    });
  };

  useEffect(() => {
    setCartHasRequestErrors(
      state.displayCart !== undefined &&
        state.displayCart.errors !== undefined &&
        state.displayCart.errors.length > 0 &&
        state.displayCart.items.length === 0
    );
  }, [state.displayCart]);

  useEffect(() => {
    const checkoutWrapper: HTMLElement | null = document.querySelector(
      ".js-checkout-snippet"
    );
    if (
      checkoutWrapper !== null &&
      isNotNullOrUndefined(state.paymentSnippetHtml) &&
      isWalley(state.paymentProvider)
    ) {
      setInnerHtml(checkoutWrapper, state.paymentSnippetHtml, true);
    }
  }, [state.paymentSnippetHtml]);

  useEffect(() => {
    const checkoutWrapper: HTMLElement | null = document.querySelector(
      ".js-checkout-snippet"
    );
    if (
      state.notEnoughQuantityEntryNumber === undefined &&
      state.displayCart !== undefined &&
      state.displayCart.items.length > 0 &&
      (state.displayCart.errors === undefined ||
        state.displayCart.errors.length === 0) &&
      (isWalley(state.paymentProvider)
        ? !(typeof window.walley?.checkout.api.suspend === "function")
        : !isKlarnaCheckoutSnippetLoaded()) // don't reload the snippet if it's already loaded, instead refreshes it on content change
    ) {
      const getCheckoutSnippet = async () => {
        let customerTypeInput = state.customerType;
        if (customerTypeInput === undefined) {
          const localStorageCustomerType = localStorage.getItem(
            LOCAL_STORAGE_SHOP_AS_KEY
          );
          const dateNow = new Date();
          if (isNotNullOrUndefined(localStorageCustomerType)) {
            const { shopAs, expires } = JSON.parse(
              localStorageCustomerType
            ) as LocalStorageShopAsOptionType;
            const expirationDate = new Date(expires);

            if (dateNow > expirationDate) {
              customerTypeInput = getDefaultCustomerType(isTeamsalesAdmin);
            } else {
              customerTypeInput = shopAs;
            }
          } else {
            customerTypeInput = getDefaultCustomerType(isTeamsalesAdmin);
          }
        }
        const {
          cartId,
          status,
          snippet,
          invalidEans,
          paymentProvider,
          customerTypeOptions,
          customerType,
        } = await updateCheckoutSnippet(
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey,
          customerTypeInput
        );
        if (status === SUCCESS_RESPONSE_STATUS && snippet !== undefined) {
          if (!isWalley(paymentProvider) && checkoutWrapper !== null) {
            setInnerHtml(checkoutWrapper, snippet, true);
          }
          initializeCheckout(isLoggedIn).initialize();
          dispatch({
            type: UPDATE_PAYMENT_PROVIDER,
            payload: {
              paymentProvider: paymentProvider ?? defaultPaymentProvider,
              customerTypeOptions,
              customerType,
            },
          });
          dispatch({
            type: UPDATE_PAYMENT_SNIPPET_HTML,
            payload: snippet,
          });
          dispatch({
            type: UPDATE_CART_ID,
            payload: cartId,
          });
        } else {
          if (state.displayCart !== undefined && invalidEans !== undefined) {
            dispatch({
              type: UPDATE_CART_ERRORS,
              payload: getCartErrorMessages(
                state.displayCart,
                invalidEans.map((ean) => `cart.entry.out.of.stock-${ean}`)
              ),
            });
          }
          render(
            getCheckoutErrors({ errors: ["checkout.cart.failure"], t }),
            checkoutWrapper
          );
        }
      };
      if (loadingSnippet.current === false) {
        loadingSnippet.current = true; // don't call the graphQL twice when one request is still pending
        void getCheckoutSnippet();
      }
    }
  }, [
    cartHasProducts,
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    configuration.amplifyConfig.aws_appsync_apiKey,
    state.displayCart,
    state.notEnoughQuantityEntryNumber,
    t,
  ]);

  useEffect(() => {
    setCartHasProducts(Boolean(state.displayCart?.items.length));
  }, [state.displayCart]);

  useEffect(() => {
    const handleStockErrors = (
      event: CustomEvent<WalleyValidationResponse>
    ) => {
      if (
        isNotNullOrUndefined(event.detail.payload?.invalidItems) &&
        event.detail.payload.invalidItems.length > 0
      ) {
        setWalleyValidationErrors(event.detail.payload.invalidItems);
      }
    };
    document.addEventListener(
      WalleyCheckoutEvents.orderValidationFailed,
      handleStockErrors as EventListener
    );

    return () => {
      document.removeEventListener(
        WalleyCheckoutEvents.orderValidationFailed,
        handleStockErrors as EventListener
      );
    };
  }, [state.paymentProvider]);

  useEffect(() => {
    if (
      walleyValidationErrors.length > 0 &&
      isNotNullOrUndefined(state.displayCart)
    ) {
      const cartItemsWithErrors: CartItem[] = state.displayCart.items.map(
        (item) => {
          const error = walleyValidationErrors.find(
            (errorItem) =>
              errorItem.itemId.id === item.entryNumber &&
              errorItem.itemId.type === item.type
          );

          if (isNotNullOrUndefined(error)) {
            return {
              ...item,
              errors: [
                `${error.stockLevel > 0 ? "cart.entry.partially.out.of.stock" : "cart.entry.out.of.stock"}`,
              ],
              maxCartStockAvailable: error.stockLevel,
            };
          }
          return item;
        }
      );

      dispatch({
        type: UPDATE_CART_ITEMS,
        payload: cartItemsWithErrors,
      });
    }
  }, [walleyValidationErrors]);

  if (state.cart === undefined) {
    return null;
  }

  return (
    <PageSection>
      {cartHasProducts ? (
        <Container className="container">
          {hasErrors ? (
            <OutOfStockWrapper isEditing={false}>
              {getCheckoutErrors({ errors: state.displayCart?.errors, t })}
            </OutOfStockWrapper>
          ) : (
            <KlarnaWrapper isEditing={state.isCartEditing}>
              <Title>{t("checkout.klarna.snippet.title")}</Title>
              {cartNotBuyable ? (
                <CartErrorCollectStoreNotBuyable>
                  {t("cart.error.collect.store.not.buyable")}
                </CartErrorCollectStoreNotBuyable>
              ) : (
                <>
                  {state.shouldReloadOnError ? (
                    <CartErrorCollectStoreNotBuyable>
                      {t("cart.request.connection.error")}
                      <ReloadButtonWrapper>
                        <BackButtonStyled
                          type="button"
                          className="button button--secondary button--small button--with-icon"
                          isCentered={true}
                          isMiniCart={false}
                          onClick={reloadCart}
                        >
                          {t("cart.request.reload.button")}
                        </BackButtonStyled>
                      </ReloadButtonWrapper>
                    </CartErrorCollectStoreNotBuyable>
                  ) : (
                    <>
                      {state.paymentProvider !== undefined &&
                        isWalley(state.paymentProvider) && (
                          <ShopAsSwitch isTeamsalesAdmin={isTeamsalesAdmin} />
                        )}
                      <CheckoutWrapper
                        className="checkout__snippet js-checkout-snippet js-test-checkout__snippet"
                        data-content-url={null}
                      >
                        <XXLLoader />
                      </CheckoutWrapper>
                    </>
                  )}
                </>
              )}
            </KlarnaWrapper>
          )}
          <CartProducts isTeamAdmin={isTeamAdmin} />
        </Container>
      ) : (
        <EmptyPageContainer className="container">
          <EmptyPageHeading data-testid="cart-empty-message">
            {cartHasRequestErrors
              ? t("cart.request.connection.error")
              : t("cart.empty.message")}
          </EmptyPageHeading>
          <EmptyPageImage>
            <use href="#cart" xlinkHref="#cart" />
          </EmptyPageImage>
          <BackButtonWrapper className="container">
            {cartHasRequestErrors ? (
              <BackButtonStyled
                type="button"
                className="button button--primary button--small button--with-icon"
                isCentered={true}
                isMiniCart={false}
                onClick={reloadCart}
              >
                {t("cart.request.reload.button")}
              </BackButtonStyled>
            ) : (
              <BackButton isCentered={true} />
            )}
          </BackButtonWrapper>
        </EmptyPageContainer>
      )}
    </PageSection>
  );
};
