import type { MegaMenuContentData } from "@xxl/frontend-api";
import {
  Configuration as MegaMenuApiConfig,
  MegamenucontentApi,
} from "@xxl/frontend-api";
import React, { useEffect, useMemo, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  addXXLEventListener,
  dispatchEvent,
  removeXXLEventListener,
} from "../../../utils/xxl-event";
import { getData, hasSubLinks } from "../Desktop/mega-menu-helper";
import { tabletMediaQuery } from "../../../utils/xxl-screen";
import { useMediaQuery } from "@mui/material";
import type { HeaderLink } from "../../../global";
import { Navigation } from "./Navigation";
import { ANIMATION_DURATION } from "./constants";
import { windowAccess } from "../../../utils/Window";
import {
  getNonUndefinedHeaderLinks,
  isTargetMegaMenuButton,
} from "./mega-menu-helper";
import isArray from "lodash/isArray";
import { useSharedData } from "../../../contexts/SharedData";
import {
  ContentApi,
  Configuration as ContentApiConfig,
} from "@xxl/content-api";
import { SEOCategoryLinks } from "../../Header/SEOCategoryLinks";
import { MOBILE_HEIGHT_WITH_HEADER_LINKS } from "../../Header/constants";
import { legacySiteUidToSiteUid } from "../../../utils/xxl-shared-data";
import { logError } from "../../../utils/xxl-log";

type MegaMenuProps = {
  campaignHubUrl: string;
  headerCode: string;
  initialHeaderLinks?: HeaderLink[];
  initialMenuData: MegaMenuContentData;
  isTeamsales: boolean;
  showSEOLinks?: boolean;
};

const MegaMenu: React.FunctionComponent<MegaMenuProps> = ({
  campaignHubUrl,
  headerCode,
  initialHeaderLinks,
  initialMenuData,
  showSEOLinks = true,
  isTeamsales,
}) => {
  const [headerLinks, setHeaderLinks] = useState<HeaderLink[] | undefined>(
    initialHeaderLinks
  );
  const [menuData, setMenuData] = useState<MegaMenuContentData | undefined>(
    initialMenuData
  );
  const [isOpen, setIsOpen] = useState(false);

  const [distanceFromWindowTop, setDistanceFromWindowTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const isTabletOrAbove = useMediaQuery(tabletMediaQuery);
  const { configuration, siteUid } = useSharedData().data;
  const [megaMenuApi] = React.useState(
    new MegamenucontentApi(new MegaMenuApiConfig(configuration.frontendApi))
  );
  const [contentApi] = React.useState(
    new ContentApi(new ContentApiConfig(configuration.contentApi))
  );

  const handleOutsideClick = useMemo(
    () =>
      ({ target }: Event) => {
        if (isTargetMegaMenuButton(target)) {
          return;
        }
        dispatchEvent("XXL_MEGAMENU_CLOSE");
      },
    []
  );

  useEffect(() => {
    const openMenuEventListener = () => setIsOpen(true);
    const closeMenuEventListener = () => setIsOpen(false);
    addXXLEventListener("XXL_MEGAMENU_OPEN", openMenuEventListener);
    addXXLEventListener("XXL_MEGAMENU_CLOSE", closeMenuEventListener);

    return () => {
      removeXXLEventListener("XXL_MEGAMENU_OPEN", openMenuEventListener);
      removeXXLEventListener("XXL_MEGAMENU_CLOSE", closeMenuEventListener);
    };
  }, [headerCode, initialMenuData, initialHeaderLinks]);

  useEffect(() => {
    const fetchMenuData = async (): Promise<void> =>
      setMenuData(await getData(megaMenuApi));
    if (hasSubLinks(initialMenuData.links ?? [])) {
      return;
    }
    try {
      void fetchMenuData();
    } catch (error) {
      logError(error);
    }
  }, [initialMenuData, megaMenuApi]);

  useEffect(() => {
    const fetchHeaderLinks = async () => {
      const { data } = await contentApi.getHeader(
        legacySiteUidToSiteUid(siteUid),
        headerCode
      );
      const [headerData] = data.result ?? [];
      const { headerlinks = [] } = headerData;
      setHeaderLinks(getNonUndefinedHeaderLinks(headerlinks));
    };
    if (isArray(initialHeaderLinks)) {
      return;
    }
    try {
      void fetchHeaderLinks();
    } catch (error) {
      logError(error);
    }
  }, [contentApi, headerCode, initialHeaderLinks, siteUid]);

  useEffect(() => {
    const stickyHeaderElement = document.getElementById("js-sticky-header");
    const headerHeight =
      stickyHeaderElement !== null
        ? stickyHeaderElement.offsetHeight
        : MOBILE_HEIGHT_WITH_HEADER_LINKS;

    stickyHeaderElement?.classList.toggle("header--fixed", isOpen);

    if (isOpen) {
      windowAccess().LockScreen.lock();
    } else {
      windowAccess().megaMenu?.closeMenu();
      windowAccess().LockScreen.unlock();
      setTimeout(() => {
        ref.current
          ?.querySelectorAll("ul")
          .forEach((list) => list.scrollTo({ behavior: "smooth", top: 0 }));
      }, ANIMATION_DURATION);
    }
    setDistanceFromWindowTop(headerHeight);
  }, [isOpen]);

  useEffect(() => {
    if (isTabletOrAbove) {
      setIsOpen(false);
    }
  }, [isTabletOrAbove]);

  return (
    <div ref={ref}>
      {showSEOLinks && (
        <SEOCategoryLinks
          levelOneLinks={menuData?.links ?? []}
          isMobile={true}
        />
      )}
      {menuData !== undefined && (
        <OutsideClickHandler
          disabled={!isOpen}
          onOutsideClick={handleOutsideClick}
          useCapture={true}
        >
          <Navigation
            distanceFromWindowTop={distanceFromWindowTop}
            headerLinks={headerLinks}
            menuData={menuData}
            isOpen={isOpen}
            urlToCampaignHub={campaignHubUrl}
            isTeamsales={isTeamsales}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

export { MegaMenu };
