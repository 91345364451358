import type { SiteIdentifier } from "@xxl/common-utils";
import { DefaultPostalCodes } from "../../constants";
import type { PriceDisplayVariant, StockStatus } from "../../utils/data-types";
import type { ProductData } from "@xxl/recommendations-api";
import type { ProductType } from "@xxl/pim-api";
import type { SetFieldType } from "type-fest";

export type ProductAddedToCartData = {
  brand: string;
  imageUrl: string;
  name: string;
  salesPrice: number;
  salesPriceFormatted: string;
  size: string;
};

export type CrossSalesProps = {
  category: string;
  onClickClose: () => void;
  price: string;
  productCode: string;
  productData: ProductAddedToCartData;
};

export type FreeDelivery =
  | {
      isEligible: false;
      cartTotal?: number;
      threshold?: number;
    }
  | {
      isEligible: true;
      cartTotal: number;
      threshold: number;
    };

export const siteIdToPostalCode = (id: SiteIdentifier) => {
  switch (id) {
    case "xxl-at":
      return DefaultPostalCodes.austria;
    case "xxl-dk":
      return DefaultPostalCodes.denmark;
    case "xxl-fi":
      return DefaultPostalCodes.finland;
    case "xxl-no":
      return DefaultPostalCodes.norway;
    case "xxl-se":
      return DefaultPostalCodes.sweden;
    default:
      throw Error("Invalid id.", id);
  }
};

export type Style = {
  code?: string;
  colorName?: string;
  name?: string;
  primaryImage?: string;
  stockStatus?: StockStatus;
  url?: string;
};

export type Size = {
  code?: string;
  ean?: string;
  size?: string;
  stockStatus?: StockStatus;
};

export type CrossSalesProductWithFixedProductType = SetFieldType<
  ProductData,
  "productType",
  ProductType | undefined
>;

export type CrossSalesProduct = {
  baseProductCode?: string;
  brand?: {
    name?: string;
  };
  categoryName?: string;
  code?: string;
  ean?: string[];
  name?: string;
  price: {
    salesPriceFormatted?: string;
    salesPrice?: number;
    type?: PriceDisplayVariant;
  };
  primaryImage?: string;
  productType?: ProductType;
  sizeOptions?: Size[];
  styleOptions?: Style[];
  url?: string;
};
