import React, { useEffect, useState } from "react";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { CartItemIdInput } from "../../../generated/graphql-code-generator";
import { getServices } from "../../Services/API/ServicesAPI";
import { ServiceProductsWrapper } from "../../Services/ServiceProductsWrapper";
import type { CartItem as CartItemData, TypeProps } from "../Api/types";
import { UPDATE_CART_CONTENT, useCartContext } from "../CartState";
import { logError } from "../../../utils/xxl-log";
import {
  SERVICES_REQUEST_SUCCESS,
  useServicesContext as useCartServicesContext,
} from "../CartServiceProductsState";
import {
  CartPageButtonsWrapper,
  CloseButton,
} from "../Styles/CartServiceProducts.styled";
import { useTracking } from "../../../contexts/Tracking";

type CartServiceProductsProps = {
  product?: string;
  category?: string;
  productSize?: string;
  productEntryNumber: string;
  price?: string;
  productQuantity: number;
  isOpen: boolean;
  onClose: () => void;
  onOpen: (value: boolean) => void;
  cartServices: CartItemData[];
  parentProductItemId: {
    id: number;
    type: TypeProps;
  };
};

const CartServiceProducts: React.FunctionComponent<
  CartServiceProductsProps
> = ({
  productSize,
  productEntryNumber,
  product,
  category,
  price,
  productQuantity,
  isOpen,
  onClose,
  onOpen,
  cartServices,
  parentProductItemId,
}) => {
  const { t } = useTranslations();
  const { state, dispatch } = useCartServicesContext();
  const { dispatch: dispatchCart } = useCartContext();
  const { sendAddOnCloseAccessoryCartEvent, sendAddOnEditCartEvent } =
    useTracking();
  const {
    configuration: {
      recommendationsApi: { basePath = "" },
    },
    siteUid,
  } = useSharedData().data;
  if (basePath.length === 0) {
    throw Error("Empty base path");
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  const handleDialogBoxClose = (): void => {
    if (actionInProgress) {
      return;
    }
    onClose();

    if (cartServices.length !== state.servicesQuantity) {
      dispatchCart({
        type: UPDATE_CART_CONTENT,
      });
    }

    setIsModalOpen(false);
    sendAddOnCloseAccessoryCartEvent();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    onOpen(true);
    sendAddOnEditCartEvent();
    const _getServices = async (): Promise<void> => {
      try {
        const ApiResponse = await getServices({ basePath, siteUid })(
          product as string,
          category as string,
          price ?? ""
        );

        const { data } = ApiResponse;
        dispatch({
          type: SERVICES_REQUEST_SUCCESS,
          payload: {
            data,
            cartServices,
            productQuantity,
            parentEntryNumber: productEntryNumber,
            parentId: parentProductItemId as CartItemIdInput,
          },
        });
        setIsModalOpen(isOpen);
        onOpen(false);
      } catch (err) {
        logError("Cannot get services", err);
      }
    };

    void _getServices();
  }, [
    product,
    category,
    price,
    dispatch,
    isModalOpen,
    productSize,
    productEntryNumber,
    productQuantity,
    cartServices,
    isOpen,
    onOpen,
  ]);

  return (
    <ServiceProductsWrapper
      category={category}
      actionInProgress={actionInProgress}
      dialogBoxHeaderText={t(`services.popup.header.cart.page`)}
      isModalOpen={isModalOpen}
      onDialogBoxClose={handleDialogBoxClose}
      setActionInProgress={setActionInProgress}
      renderFooterButtons={() => (
        <CartPageButtonsWrapper>
          <CloseButton
            onClick={handleDialogBoxClose}
            disabled={actionInProgress}
          >
            {t("sorts.close.button.text")}
          </CloseButton>
        </CartPageButtonsWrapper>
      )}
      showOverlayOnActionInProgress={true}
    />
  );
};

export { CartServiceProducts };
