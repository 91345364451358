import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import { BannerSize } from "./price-display-portals-helper";
import { xxlTheme } from "../../../src/styles/xxl-theme";

const {
  colors: { xxlRed },
} = xxlTheme;

const OtherPriceWrapper = styled.p<{ isSmall?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: 3px 0 0;
  ${MQ("tablet")} {
    margin-top: 6px;
  }
  font-size: ${({ isSmall = false }) => (isSmall ? "12px" : "18px")};

  ${MQ("tablet")} {
    font-size: ${({ isSmall = false }) => (isSmall ? "15px" : "24px")};
  }
`;

const OtherPrice = styled.span<{
  hasLowerProductConfiguratorPriceThanSalesPrice?: boolean;
  hasUndeductedRewardDiscount: boolean;
  isInvertedPrice: boolean;
}>`
  ${({
    hasLowerProductConfiguratorPriceThanSalesPrice = false,
    hasUndeductedRewardDiscount,
    isInvertedPrice,
  }) => `
    text-decoration: ${
      isInvertedPrice || hasLowerProductConfiguratorPriceThanSalesPrice
        ? "none"
        : "line-through"
    };
    color: ${
      hasLowerProductConfiguratorPriceThanSalesPrice
        ? "inherit"
        : hasUndeductedRewardDiscount
          ? xxlRed
          : "inherit"
    };
  `}
`;

const OtherPriceDisclaimer = styled.span``;

const SalesPrice = styled.p<{
  bannerSize?: BannerSize;
  hasUndeductedRewardDiscount: boolean;
  isDiscountedMainPrice: boolean;
}>`
  ${typographyToCss({
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1,
  })}

  font-size: ${({ bannerSize }) => {
    switch (bannerSize) {
      case BannerSize.XS:
      case BannerSize.SMALL:
        return "18px";
      default:
        return "36px";
    }
  }};

  ${MQ("tablet")} {
    font-size: ${({ bannerSize }) => {
      switch (bannerSize) {
        case BannerSize.XS:
        case BannerSize.SMALL:
          return "24px";
        default:
          return "60px";
      }
    }};
  }
  display: flex;
  flex-direction: column;
  margin: 0;
  color: ${({ hasUndeductedRewardDiscount, isDiscountedMainPrice }) =>
    hasUndeductedRewardDiscount
      ? "inherit"
      : isDiscountedMainPrice
        ? xxlRed
        : "inherit"};
`;

export { OtherPrice, OtherPriceDisclaimer, OtherPriceWrapper, SalesPrice };
