import { replaceUrlInHistoryState } from "./History";

export const urlToRelativeHref = (url: URL): string =>
  `${url.href.substring(url.origin.length)}`;

export const getLastLevelOfUrl = (url: URL): string => {
  const lastSlashIndex = url.href.lastIndexOf("/") + 1;
  return url.href.replace(url.search, "").substring(lastSlashIndex);
};

export const getUrlParameter = function (name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

if (typeof window !== "undefined") {
  window.Url = {
    getUrlParameter,
  };
}

export const hasQueryParam = (
  paramName: string,
  url = window.location.href
): boolean => new URL(url).searchParams.has(paramName);

export const removeQueryParamFromUrl = (
  paramName: string,
  url = window.location.href
): URL => {
  const parsedUrl = new URL(url);
  parsedUrl.searchParams.delete(paramName);
  replaceUrlInHistoryState(parsedUrl);
  return parsedUrl;
};
