import type { ButtonHTMLAttributes } from "react";
import React, { forwardRef } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { interactionFeedback } from "../../../styles/helpers";
import { resolutionSizes } from "../../../config";
import type * as AllIcons from "@xxl/icons";
import { Icon } from "../../Icon/Icon";
import { xxlTheme } from "../../../styles/xxl-theme";
import { color } from "@xxl/theme";

export const Variant = {
  Ghost: "ghost",
  GhostWhite: "ghostWhite",
  Outlined: "outlined",
  Primary: "primary",
  PrimaryInBanner: "primaryInBanner",
  Secondary: "secondary",
  SecondaryInBanner: "secondaryInBanner",
  Tertiary: "tertiary",
  TertiaryInBanner: "tertiaryInBanner",
  OutlinedTransparent: "outlinedTransparent",
  RewardsBannerTransparent: "rewardsBannerTransparent",
  RewardsBannerSecondary: "rewardsBannerSecondary",
} as const;

const ButtonWidth = {
  Auto: "auto",
  Full: "full",
} as const;

type ButtonWidthType = (typeof ButtonWidth)[keyof typeof ButtonWidth];

export type VariantType = (typeof Variant)[keyof typeof Variant];

type LinkProps = {
  href?: string;
  target?: string;
  rel?: string;
  isNestedLink?: boolean;
};

type CustomButtonProps = {
  width?: ButtonWidthType;
  variant?: VariantType;
  size?: ButtonSizeType;
  loading?: boolean;
  icon?: keyof typeof AllIcons;
  iconSize?: number;
  iconTransform?: {
    translateX: number;
    translateY: number;
  };
} & LinkProps;

const ButtonSize = {
  Large: "large",
  LargeInBanner: "largeInBanner",
  Small: "small",
} as const;

export type ButtonSizeType = (typeof ButtonSize)[keyof typeof ButtonSize];

export const DESKTOP_BUTTON_SIZE = {
  [ButtonSize.Large]: "66px",
  [ButtonSize.LargeInBanner]: "48px",
  [ButtonSize.Small]: "50px",
} as const;

export const MOBILE_BUTTON_SIZE = {
  [ButtonSize.Large]: "58px",
  [ButtonSize.LargeInBanner]: "42px",
  [ButtonSize.Small]: "44px",
} as const;

const BUTTON_WIDTH = {
  [ButtonWidth.Auto]: "auto",
  [ButtonWidth.Full]: "100%",
} as const;

const getButtonSizeStyles = ({ size = "large" }: { size: ButtonSizeType }) => {
  return css`
    @media (min-width: ${resolutionSizes.laptop}px) {
      height: ${DESKTOP_BUTTON_SIZE[size]};
      font-size: ${xxlTheme.typography.mediumBold.fontSize}px;
      font-family: ${xxlTheme.typography.mediumMedium.fontFamily};
    }
    height: ${MOBILE_BUTTON_SIZE[size]};
    font-size: ${xxlTheme.typography.smallRegular.fontSize}px;
    font-family: ${xxlTheme.typography.smallMedium.fontFamily};
  `;
};

const StyledButton = styled.button<{
  size: ButtonSizeType;
  width: ButtonWidthType;
}>`
  touch-action: manipulation;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  padding: 0 ${xxlTheme.spaces.large};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: ${({ width }) => BUTTON_WIDTH[width]};
  color: ${color.webBlack.hex};
  outline-color: ${color.green.hex};
  outline-offset: ${xxlTheme.spaces.micro};
  text-decoration: none;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${interactionFeedback(`
    background-color: ${color.webBlack.hex};
    color: ${color.white.hex};
  `)}

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${getButtonSizeStyles}
`;

export const primaryButtonInteractionFeedbackCss = css`
  background-color: ${color.webBlack.hex};
  color: ${color.green.hex};
`;

const PrimaryButton = styled(StyledButton)`
  background-color: ${color.green.hex};
  color: ${color.webBlack.hex};
  ${interactionFeedback(primaryButtonInteractionFeedbackCss)}
`;

export const secondaryButtonInteractionFeedbackCss = css`
  background-color: ${color.green.hex};
  color: ${color.webBlack.hex};
`;

const SecondaryButton = styled(StyledButton)`
  background-color: ${color.webBlack.hex};
  color: ${color.white.hex};
  box-sizing: border-box;
  ${interactionFeedback(secondaryButtonInteractionFeedbackCss)}
`;

export const tertiaryButtonInteractionFeedbackCss = css`
  background-color: ${color.green.hex};
  color: ${color.webBlack.hex};
`;

const TertiaryButton = styled(StyledButton)`
  background-color: ${color.white.hex};
  color: ${color.webBlack.hex};
  ${interactionFeedback(tertiaryButtonInteractionFeedbackCss)}
`;

export const ghostButtonInteractionFeedbackCss = css`
  background-color: transparent;
  color: ${color.green.hex};
`;

export const GhostButton = styled(StyledButton)`
  color: ${color.webBlack.hex};
  ${interactionFeedback(ghostButtonInteractionFeedbackCss)}
`;

export const GhostWhiteButton = styled(StyledButton)`
  color: ${color.white.hex};
  ${interactionFeedback(ghostButtonInteractionFeedbackCss)}
`;

const OutlinedButton = styled(StyledButton)`
  background-color: ${color.white.hex};
  border: 1px solid ${color.mediumGray.hex};
  color: ${color.webBlack.hex};

  ${interactionFeedback(
    `background-color: ${color.green.hex};color: ${color.webBlack.hex};`
  )}
`;

const OutlinedTransparentButton = styled(OutlinedButton)`
  background-color: transparent;
`;

const RewardsBannerTransparentButton = styled(OutlinedTransparentButton)`
  ${interactionFeedback(
    `background-color: ${color.black.hex};color: ${color.white.hex};`
  )}
`;

const RewardsBannerSecondaryButton = styled(SecondaryButton)`
  border: 1px solid ${color.black.hex};
  ${interactionFeedback(`border: 1px solid ${color.black.hex};`)}
`;

export const PrimaryButtonInBanner = styled(PrimaryButton)`
  ${getButtonSizeStyles({ size: "largeInBanner" })}
`;

export const SecondaryButtonInBanner = styled(SecondaryButton)`
  ${getButtonSizeStyles({ size: "largeInBanner" })}
  height: auto;
`;

export const TertiaryButtonInBanner = styled(TertiaryButton)`
  ${getButtonSizeStyles({ size: "largeInBanner" })}
`;

const ButtonComponentMap = {
  [Variant.Primary]: PrimaryButton,
  [Variant.PrimaryInBanner]: PrimaryButtonInBanner,
  [Variant.Secondary]: SecondaryButton,
  [Variant.SecondaryInBanner]: SecondaryButtonInBanner,
  [Variant.Tertiary]: TertiaryButton,
  [Variant.TertiaryInBanner]: TertiaryButtonInBanner,
  [Variant.Ghost]: GhostButton,
  [Variant.GhostWhite]: GhostWhiteButton,
  [Variant.Outlined]: OutlinedButton,
  [Variant.OutlinedTransparent]: OutlinedTransparentButton,
  [Variant.RewardsBannerTransparent]: RewardsBannerTransparentButton,
  [Variant.RewardsBannerSecondary]: RewardsBannerSecondaryButton,
};

const LoaderIconSizeMap = {
  [ButtonSize.Large]: 38,
  [ButtonSize.LargeInBanner]: 38,
  [ButtonSize.Small]: 28,
};

const Centered = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 40px;
`;

export type XxlButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  CustomButtonProps;
export const XxlButton = forwardRef<HTMLButtonElement, XxlButtonProps>(
  (props, ref) => {
    const {
      width = ButtonWidth.Full,
      variant = Variant.Primary,
      loading = false,
      children,
      target,
      title,
      icon,
      iconSize,
      size = "large",
      iconTransform: { translateX, translateY } = {
        translateX: 0,
        translateY: 0,
      },
      isNestedLink = false,
      ...baseProps
    } = props;

    const buttonChildren = (
      <>
        <span
          style={{
            gap: xxlTheme.spaces.mini,
            opacity: loading ? 0 : 1,
            fontSize: 15,
            width: "100%",
          }}
          className={props.className}
        >
          {icon !== undefined && (
            <Icon
              name={icon}
              size={iconSize}
              transform={{
                translateX,
                translateY,
              }}
            />
          )}
          {children}
        </span>
        {loading && (
          <Centered>
            <Icon name="Loader" size={LoaderIconSizeMap[size]} />
          </Centered>
        )}
      </>
    );

    const buttonProps = {
      ...baseProps,
      ref,
      title,
      target,
      size,
      children: buttonChildren,
      width,
      ...(loading && { "data-loading": true }),
    } as const;

    const isLink = baseProps.href !== undefined;

    const ButtonComponent = ButtonComponentMap[variant];

    return (
      <ButtonComponent
        {...buttonProps}
        as={isNestedLink ? "span" : isLink ? "a" : "button"}
        role={isNestedLink ? "button" : undefined}
      />
    );
  }
);

XxlButton.displayName = "XxlButton";
