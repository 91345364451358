import { NextImage } from "@/next-js-app/components/common/NextImage/NextImage";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled/macro";
import { fadeInAnimation } from "../../../styled/mixins";

type FetchPriorityImageProps = {
  isVisible: boolean;
};

export const Image = styled(NextImage, {
  shouldForwardProp: isPropValid,
})<FetchPriorityImageProps>`
  opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
  ${({ isVisible }) =>
    isVisible ? fadeInAnimation("productImageFadeIn", "200ms", 0.5) : ""};
`;
