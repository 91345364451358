import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import type { Breakpoint } from "./breakpoints.config";
import Breakpoints from "./breakpoints.config";
import { xxlTheme } from "./xxl-theme";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const { colors, alpha } = xxlTheme;

type Typography = {
  fontFamily?: string;
  fontStyle?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
  textTransform?: string;
  textAlign?: string;
  textDecoration?: string;
  whiteSpace?: string;
  textOverflow?: string;
  letterSpacing?: number;
};

type MediaQueryTypography = Typography & {
  smallMobile?: Typography;
  mobile?: Typography;
  smallTablet?: Typography;
  tablet?: Typography;
  tabletHorizontal?: Typography;
  laptop?: Typography;
  desktop?: Typography;
  bigDesktop?: Typography;
  fullHD?: Typography;
};

const MQ = (n: Breakpoint): string => {
  const bpArray = Object.keys(Breakpoints).map((key) => {
    const value = Breakpoints[key as Breakpoint];
    return [key, value];
  });

  const [result] = bpArray.reduce<string[]>((acc, [name, size]) => {
    if (n === name) {
      return [...acc, `@media (min-width: ${size}px)`];
    }
    return acc;
  }, []);

  return result;
};

const typographySettings = (settings: MediaQueryTypography) => css`
  ${isNotNullOrUndefined(settings.fontFamily) &&
  `font-family: ${settings.fontFamily};`}
  ${isNotNullOrUndefined(settings.fontStyle) &&
  `font-style: ${settings.fontStyle};`}
  ${isNotNullOrUndefined(settings.fontSize) &&
  `font-size: ${settings.fontSize / 16}rem;`}
  ${isNotNullOrUndefined(settings.fontWeight) &&
  `font-weight: ${settings.fontWeight};`}
  ${isNotNullOrUndefined(settings.lineHeight) &&
  `line-height: ${settings.lineHeight};`}
  ${isNotNullOrUndefined(settings.textTransform) &&
  `text-transform: ${settings.textTransform};`}
  ${isNotNullOrUndefined(settings.textAlign) &&
  `text-align: ${settings.textAlign};`}
  ${isNotNullOrUndefined(settings.textDecoration) &&
  `text-decoration: ${settings.textDecoration};`}
  ${isNotNullOrUndefined(settings.whiteSpace) &&
  `white-space: ${settings.whiteSpace};`}
  ${isNotNullOrUndefined(settings.textOverflow) &&
  `white-space: ${settings.textOverflow};`}
  ${isNotNullOrUndefined(settings.letterSpacing) &&
  `letter-spacing: ${settings.letterSpacing / 16}rem;`}
`;

const typographyToCss = (
  settings: MediaQueryTypography
): SerializedStyles => css`
  ${typographySettings(settings)}
  ${isNotNullOrUndefined(settings.smallMobile)
    ? css`
        ${MQ("smallMobile")} {
          ${typographySettings(settings.smallMobile)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.mobile)
    ? css`
        ${MQ("mobile")} {
          ${typographySettings(settings.mobile)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.smallTablet)
    ? css`
        ${MQ("smallTablet")} {
          ${typographySettings(settings.smallTablet)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.tablet)
    ? css`
        ${MQ("tablet")} {
          ${typographySettings(settings.tablet)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.tabletHorizontal)
    ? css`
        ${MQ("tabletHorizontal")} {
          ${typographySettings(settings.tabletHorizontal)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.laptop)
    ? css`
        ${MQ("laptop")} {
          ${typographySettings(settings.laptop)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.desktop)
    ? css`
        ${MQ("desktop")} {
          ${typographySettings(settings.desktop)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.bigDesktop)
    ? css`
        ${MQ("bigDesktop")} {
          ${typographySettings(settings.bigDesktop)};
        }
      `
    : null}
  ${isNotNullOrUndefined(settings.fullHD)
    ? css`
        ${MQ("fullHD")} {
          ${typographySettings(settings.fullHD)};
        }
      `
    : null}
`;

const outlineForTabs = (
  size = "2px",
  color = colors.xxlGreen,
  outline = "5px"
): SerializedStyles => css`
  outline: ${size} solid ${color};
  outline-offset: ${outline};
`;

const interactionFeedback = (content: string | SerializedStyles) => css`
  @media (hover: hover) {
    &:hover {
      ${content}
    }
  }
  @media (hover: none) {
    &:active {
      ${content}
    }
  }
  &:focus-visible {
    ${content}
  }
`;

const noTopMarginForFirstOfType = (elements: string[]) => {
  let styles = "";
  elements.forEach((element) => {
    styles += `
      &&& ${element}:first-of-type {
        margin-top: 0;
      }
    `;
  });
  return styles;
};

const noScrollbars = () => css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

// Take into consideration @mixin xxl-scrollbar($width) when changing xxlScrollbars (_mixins.scss)
const xxlScrollbars = (width: string) => css`
  scrollbar-color: ${colors.xxlWebBlack} ${colors.xxlMediumGrey};
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background: ${colors.xxlWebBlack};
  }
  &::-webkit-scrollbar-track {
    background: ${colors.xxlMediumGrey};
  }

  &::-webkit-scrollbar {
    width: ${width};
    height: ${width};
  }
`;

type AlphaKeys = keyof typeof alpha;

const xxlHexa = (color: string, opacity: number): string => {
  const value = `${opacity * 100}%` as AlphaKeys;

  return `${color}${alpha[value]}`;
};

export {
  interactionFeedback,
  typographyToCss,
  MQ,
  outlineForTabs,
  noTopMarginForFirstOfType,
  noScrollbars,
  xxlScrollbars,
  xxlHexa,
};
export type { MediaQueryTypography, Typography };
