import { isNotNullOrUndefined } from "@xxl/common-utils";
import { FavoritesFilled, Loader } from "@xxl/icons";
import type { MouseEventHandler } from "react";
import React, { useEffect, useState } from "react";
import { useSharedData } from "../../contexts/SharedData";
import type {
  AddToFavoritesMutation,
  RemoveFromFavoritesMutation,
} from "../../generated/graphql-code-generator";
import { callGraphQL } from "../../graphql/graphqlApi";
import { isBundlePage } from "../../utils/xxl-page-type";
import {
  addToFavoritesMutation,
  removeFromFavoritesMutation,
} from "./Api/graphqlQueries";
import {
  getFavoritesCountFromLocalStorage,
  isFavorite,
  saveFavoritesToLocalStorage,
  setFavoritesCountInLocalStorage,
} from "./Favorites.helper";
import { FavoriteButton } from "./Favorites.styled";
import {
  FAVORITES_TRACKING_KEY,
  FavoritesConfirmationEnum,
  UPDATE_FAVORITES_LIST,
  useFavoritesContext,
} from "./FavoritesState";
import {
  FavoritesSource,
  FavoritesConfirmationSource,
} from "./MiniFavoritesState";
import { useSetRelinkState, useRelinkValue } from "react-relink";
import { useSessionSource } from "../../contexts/Session";
import { INFO_TIMEOUT } from "../../constants";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { setStorageItem } from "../../utils/LocalStorage/local-storage";
import { transformSizeIdToStyle } from "../Product/product-helper";

const ADD_TO_FAVORITES_KEY = "addToFavorites";

type FavoriteProductHeartProps = {
  productStyle: string;
  isInPDP?: boolean;
};
export const FavoriteProductHeart = ({
  productStyle,
  isInPDP = false,
}: FavoriteProductHeartProps) => {
  const {
    data: {
      configuration: {
        amplifyConfig: { aws_appsync_graphqlEndpoint },
      },
      featureToggles: { toggle_elevate_search },
    },
  } = useSharedData();
  const { t } = useTranslations();
  const transformedProductStyle = transformSizeIdToStyle(productStyle);
  const [isProductFavorite, setIsProductFavorite] = useState(
    isFavorite(transformedProductStyle)
  );
  const { dispatch } = useFavoritesContext();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const setFavoritesCount = useSetRelinkState(FavoritesSource);
  const setFavoritesConfirmation = useSetRelinkState(
    FavoritesConfirmationSource
  );
  const [isLoading, setIsLoading] = useState(false);
  const trackingEvt = useTracking();

  const showFavoritesConfirmation = (isAdded: boolean): void => {
    void setFavoritesConfirmation(
      isAdded
        ? FavoritesConfirmationEnum.Added
        : FavoritesConfirmationEnum.Removed
    );
    setTimeout(() => {
      void setFavoritesConfirmation(FavoritesConfirmationEnum.Hidden);
    }, INFO_TIMEOUT);
  };

  const trackAddToFavorites = () => {
    trackingEvt.sendAddToFavoritesEvent({
      product: transformedProductStyle,
      list: `add_to_${FAVORITES_TRACKING_KEY}`,
      isElevateEnabled: toggle_elevate_search,
    });
  };

  const trackRemoveFromFavorites = () => {
    trackingEvt.sendRemoveFromFavoritesEvent({
      product: transformedProductStyle,
      list: `remove_from_${FAVORITES_TRACKING_KEY}`,
      isElevateEnabled: toggle_elevate_search,
    });
  };

  const addToFavorites = async (styleId: string) => {
    setIsProductFavorite(true);
    setIsLoading(true);
    const { data, errors } = await callGraphQL<AddToFavoritesMutation>(
      {
        query: addToFavoritesMutation,
        variables: { styleId },
      },
      aws_appsync_graphqlEndpoint
    );

    if (errors !== undefined) {
      setIsProductFavorite(false);
      setIsLoading(false);
      throw Error("Unable to add to favorites");
    }

    if (isNotNullOrUndefined(data)) {
      saveFavoritesToLocalStorage(data.addToFavorites);
      const favoritesCountFromLocalStorage =
        getFavoritesCountFromLocalStorage();
      const favoritesCountValue = isNotNullOrUndefined(
        favoritesCountFromLocalStorage
      )
        ? favoritesCountFromLocalStorage + 1
        : data.addToFavorites.length;

      void setFavoritesCount(favoritesCountValue);
      setIsLoading(false);
      showFavoritesConfirmation(true);
      setFavoritesCountInLocalStorage(favoritesCountValue);
      trackAddToFavorites();
    }
  };

  const removeFromFavorites = async (styleId: string) => {
    setIsProductFavorite(false);
    setIsLoading(true);
    const { data, errors } = await callGraphQL<RemoveFromFavoritesMutation>(
      {
        query: removeFromFavoritesMutation,
        variables: { styleId },
      },
      aws_appsync_graphqlEndpoint
    );

    if (errors !== undefined) {
      setIsProductFavorite(true);
      setIsLoading(false);
      throw Error("Unable to remove from favorites");
    }

    if (isNotNullOrUndefined(data)) {
      saveFavoritesToLocalStorage(data.removeFromFavorites);
      dispatch({
        type: UPDATE_FAVORITES_LIST,
      });
      const favoritesCountFromLocalStorage =
        getFavoritesCountFromLocalStorage();
      const favoritesCountValue = isNotNullOrUndefined(
        favoritesCountFromLocalStorage
      )
        ? favoritesCountFromLocalStorage - 1
        : data.removeFromFavorites.length;

      void setFavoritesCount(favoritesCountValue);
      setIsLoading(false);
      showFavoritesConfirmation(false);
      setFavoritesCountInLocalStorage(favoritesCountValue);
      trackRemoveFromFavorites();
    }
  };

  const onClickFavorite: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isLoggedIn) {
      isProductFavorite
        ? void removeFromFavorites(transformedProductStyle)
        : void addToFavorites(transformedProductStyle);
    } else {
      const loginButton = document.getElementById("xxl-react-login-link");
      if (isNotNullOrUndefined(loginButton)) {
        setStorageItem(ADD_TO_FAVORITES_KEY, transformedProductStyle);
        loginButton.click();
      }
    }
  };

  useEffect(() => {
    const productToAdd = localStorage.getItem(ADD_TO_FAVORITES_KEY);
    if (
      isLoggedIn &&
      productToAdd !== null &&
      productToAdd === transformedProductStyle &&
      !isFavorite(productToAdd)
    ) {
      void addToFavorites(productToAdd);
    }
    localStorage.removeItem(ADD_TO_FAVORITES_KEY);
    if (isLoggedIn && isFavorite(transformedProductStyle)) {
      setIsProductFavorite(true);
    }
  }, [isLoggedIn]);

  if (isBundlePage()) {
    return null;
  }

  return (
    <FavoriteButton
      onClick={onClickFavorite}
      isFavorite={isLoggedIn && isProductFavorite}
      isInPDP={isInPDP}
      className="favorite-button"
      aria-label={t("favorites.add")}
    >
      {isLoading ? <Loader /> : <FavoritesFilled />}
    </FavoriteButton>
  );
};
