import { hasQueryParam, removeQueryParamFromUrl } from "../../utils/xxl-url";
import * as XxlEvent from "../../utils/xxl-event";
import { RelinkSource } from "react-relink";

export const StickyHeaderStatus = new RelinkSource<boolean>({
  key: "stickyHeaderStatus",
  default: false,
});

const redirectFromQueryParam = (fallbackRedirect = "/") => {
  const newUrl = new URL(window.location.href);
  const redirect = newUrl.searchParams.get("redirect") ?? fallbackRedirect;
  newUrl.pathname = redirect;
  newUrl.searchParams.delete("redirect");
  window.location.href = newUrl.toString();
};

const handleLoginEvent = (
  param: string,
  isLoggedIn: boolean,
  state: XxlEvent.ViewState
): void => {
  if (isLoggedIn) {
    removeQueryParamFromUrl(param);
    redirectFromQueryParam("/account");
  } else {
    XxlEvent.dispatchOpenLoginEvent(state);
  }
};

const handleRegisterLoginRedirectParams = (isLoggedIn: boolean): void => {
  if (hasQueryParam("login")) {
    handleLoginEvent("login", isLoggedIn, XxlEvent.ViewState.LOGIN);
  } else if (hasQueryParam("register")) {
    handleLoginEvent("register", isLoggedIn, XxlEvent.ViewState.REGISTER);
  } else if (hasQueryParam("redirect")) {
    redirectFromQueryParam();
  }
};

export { handleRegisterLoginRedirectParams };
