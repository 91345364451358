import React from "react";

export type ImageProps = {
  url: string;
  altText?: string;
};
const Image: React.FunctionComponent<ImageProps> = ({
  url,
  altText,
}): JSX.Element => {
  return (
    <>
      <source
        media="(max-width: 767px)"
        srcSet={`${url}?w=360&max-h=245`}
      ></source>
      <source
        media="(max-width: 1024px)"
        srcSet={`${url}?w=500&max-h=700`}
      ></source>
      <source
        media="(max-width: 1395px)"
        srcSet={`${url}?w=500&max-h=400`}
      ></source>
      <source
        media="(min-width: 1395px)"
        srcSet={`${url}?w=485&max-h=330`}
      ></source>
      <img src={`${url}?w=485&max-h=330`} alt={altText} />
    </>
  );
};

export { Image };
