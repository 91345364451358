import { useSharedData } from "../../contexts/SharedData";
import styled from "@emotion/styled/macro";
import { MyXxlLogIn } from "@xxl/icons";
import { xxlTheme } from "../../styles/xxl-theme";
import { useSessionSource } from "../../contexts/Session";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { MouseEvent } from "react";
import { useEffect } from "react";
import { removeTrailingSlash } from "../../utils/xxl-string";
import { Link as BaseLink } from "./Header.styled";
import { typographyToCss } from "../../styles/helpers";
import * as XxlEvent from "../../utils/xxl-event";
import { useRelinkValue } from "react-relink";
import { ViewState } from "../../utils/xxl-event";

const { spaces } = xxlTheme;

const Link = styled(BaseLink)`
  color: var(--color-xxl-white);
  align-items: center;
  display: flex;
  gap: ${spaces.line};
  ${typographyToCss({
    fontSize: 15,
  })}
`;

const MyAccountLink = () => {
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { t } = useTranslations();
  const { requestMapping } = useSharedData().data;
  const { account, login } = requestMapping;
  const myAccountLink = isLoggedIn ? account : login;
  const handleClickMyAccountLink = (event: MouseEvent) => {
    if (!isLoggedIn) {
      event.preventDefault();
      XxlEvent.dispatchOpenLoginEvent();
    }
  };

  useEffect(() => {
    const { pathname } = window.location;
    const isLoginPage = removeTrailingSlash(pathname) === login;
    if (isLoginPage) {
      XxlEvent.dispatchOpenLoginEvent();
    }
  }, [login]);

  useEffect(() => {
    const onSocialSecurityNumberOpen = () => {
      XxlEvent.dispatchOpenLoginEvent(ViewState.MISSING_SOCIAL_SECURITY_NUMBER);
    };

    XxlEvent.addEventListener(
      XxlEvent.type.XXL_SOCIAL_SECURITY_NUMBER_OPEN,
      onSocialSecurityNumberOpen
    );

    return () => {
      XxlEvent.removeEventListener(
        XxlEvent.type.XXL_SOCIAL_SECURITY_NUMBER_OPEN,
        onSocialSecurityNumberOpen
      );
    };
  }, []);

  return (
    <>
      <Link
        id="xxl-react-login-link" // TODO: Refactor in https://xxlsports.atlassian.net/browse/XD-10364
        href={myAccountLink}
        data-testid="login-button"
        onClick={handleClickMyAccountLink}
      >
        <MyXxlLogIn fontSize={24} />
        <span>{t(isLoggedIn ? "header.my.account" : "header.login")}</span>
      </Link>
    </>
  );
};

export { MyAccountLink };
