import React from "react";
import type { PropsWithChildren } from "react";
// eslint-disable-next-line no-restricted-imports
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { logError } from "../../../utils/xxl-log";

type ErrorBoundaryProps = {
  fallback?: JSX.Element;
  onError?: (error: Error) => void;
};

const ErrorBoundary = (props: PropsWithChildren<ErrorBoundaryProps>) => {
  const { children, fallback, onError = logError } = props;

  return (
    <ReactErrorBoundary fallback={fallback ?? <></>} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary };
