import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Field } from "formik";
import * as React from "react";

export const inputStyle = css`
  width: calc(100% - 30px);
  padding: 12px 14px;
  color: #000;
  font-family: var(--font-family-regular);
  line-height: 19px;
  background-color: var(--color-xxl-white);
  border: 1px solid var(--color-xxl-medium-gray);
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
`;
export const Input = styled(
  (
    props: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  ) => <input {...props} />
)`
  ${inputStyle}
`;

export const FormikField = styled(Field)`
  ${inputStyle}
`;

export const InputContainer = styled.div`
  margin-bottom: var(--space-small-regular);
`;
