import { isNotEmpty } from "@xxl/common-utils";
import { CreateClientApi } from "../Reviews/api/client";
import { useEffect, useState } from "react";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { SizeFitness } from "react-app/src/generated/graphql-code-generator";

const useSizeRecommendation = (productId: string) => {
  const {
    configuration: {
      amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
    },
  } = useSharedData().data;

  const [sizeRecommendation, setSizeRecommendation] = useState<{
    percentage: number;
    sizeFitness: SizeFitness;
  }>();

  useEffect(() => {
    if (productId === "") {
      setSizeRecommendation(undefined);
      return;
    }
    const fetchSizeRecommendation = async () => {
      try {
        const api = CreateClientApi(
          aws_appsync_apiKey,
          aws_appsync_graphqlEndpoint
        );

        const SizeRecommendationArr =
          await api.ReviewService.GetSizeRating(productId);

        if (isNotEmpty(SizeRecommendationArr)) {
          const [mostRecommendedSize] = SizeRecommendationArr.sort(
            (a, b) => b.percentage - a.percentage
          );
          setSizeRecommendation({
            percentage: mostRecommendedSize.percentage,
            sizeFitness: mostRecommendedSize.sizeFitness,
          });
        } else {
          setSizeRecommendation(undefined);
        }
      } catch (error) {
        console.error("Error when fetching size recommendation", error);
      }
    };

    void fetchSizeRecommendation();
  }, [aws_appsync_apiKey, aws_appsync_graphqlEndpoint, productId]);

  return sizeRecommendation;
};

export { useSizeRecommendation };
