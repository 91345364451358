import type {
  CustomData,
  DistinctFacet,
  EntityCollection,
  ProductData,
  QueryCollection,
  RangeFacet,
  ScopedQueryResult,
  SimpleEntityCollection,
  UnScopedQueryResult,
} from "@xxl/search-api";

export type SearchResponseAugmented = {
  /**
   * Additional attributes returned for queries (used by Loop54)
   * @type {CustomData}
   * @memberof SearchResponse1
   */
  customData: CustomData | null;
  /**
   * Whether or not the engine understood the search query.
   * @type {boolean}
   * @memberof SearchResponse1
   */
  makesSense: boolean;
  /**
   * A collection of suggestions for queries that are related to the provided query.
   * @type {QueryCollection}
   * @memberof SearchResponse1
   */
  relatedQueries: QueryCollection | null;
  /**
   * Any additional results that, while not matching, are relevant to the query.
   * @type {EntityCollection}
   * @memberof SearchResponse1
   */
  relatedResults: EntityCollection | null;
  /**
   * The results that match the query.
   * @type {EntityCollection}
   * @memberof SearchResponse1
   */
  results: EntityCollection | null;
  /**
   * A collection of suggestions for alternate spellings of the query.
   * @type {QueryCollection}
   * @memberof SearchResponse1
   */
  spellingSuggestions: QueryCollection | null;
};

export type EntityCollectionAugmented = {
  /**
   * Facets from the request that were accepted
   * @type {Array<string>}
   * @memberof EntityCollection
   */
  acceptedFacets: string[];
  /**
   * The number of entities in this collection.
   * @type {number}
   * @memberof EntityCollection
   */
  count: number;
  /**
   * The facets found for this collection.
   * @type {Array<DistinctFacet | RangeFacet>}
   * @memberof EntityCollection
   */
  facets: (DistinctFacet | RangeFacet)[];
  /**
   * The entities.
   * @type {Array<ProductData>}
   * @memberof EntityCollection
   */
  items: ProductData[];
};

export type AutoCompleteResponseAugmented = {
  /**
   * Results from app query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  appResults: SimpleEntityCollection | null;
  /**
   * Results from brand query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  brandResults: SimpleEntityCollection | null;
  /**
   * Results from campaignHub query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  campaignHubResults: SimpleEntityCollection | null;
  faqHubResults: SimpleEntityCollection | null;
  /**
   * Results from guide query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  guideResults: SimpleEntityCollection | null;
  /**
   * Results from search query.
   * @type {EntityCollection}
   * @memberof AutoCompleteResponse1
   */
  productResults: EntityCollection | null;
  /**
   * A collection of query suggestions for the request query.
   * @type {QueryCollection}
   * @memberof AutoCompleteResponse1
   */
  queries: QueryCollection | null;
  /**
   * Query for given response.
   * @type {string}
   * @memberof AutoCompleteResponse1
   */
  queryString: string | null;
  /**
   * Results from reward query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  rewardResults: SimpleEntityCollection | null;
  /**
   * The query the engine deemed most relevant, will get a result with scopes.
   * @type {UnScopedQueryResult | ScopedQueryResult}
   * @memberof AutoCompleteResponse1
   */
  scopedQuery: UnScopedQueryResult | ScopedQueryResult | null;
  /**
   * Results from store query.
   * @type {SimpleEntityCollection}
   * @memberof AutoCompleteResponse1
   */
  storeResults: SimpleEntityCollection | null;
};

export enum AVAILABILITY {
  ONLINE = "ONLINE",
  STORE = "STORE",
}

export const isAvailability = (
  availability: unknown
): availability is AVAILABILITY[] =>
  Array.isArray(availability) &&
  availability.every(
    (value) => value === AVAILABILITY.STORE || value === AVAILABILITY.ONLINE
  );
