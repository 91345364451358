import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

const HAMBURGER_HEIGHT = spaces.regular;
const HAMBURGER_WIDTH = spaces.large;

const Hamburger = styled.i<{ open: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  span {
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      top: 14px;
    }

    ${({ open }) =>
      open &&
      css`
        &:nth-of-type(1) {
          top: 7px;
          transform: rotate(45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          top: 7px;
          transform: rotate(-45deg);
        }
      }
    `}
  }
`;

const Line = styled.span`
  position: absolute;
  left: 0;
  display: block;
  width: ${HAMBURGER_WIDTH};
  height: 2px;
  background-color: ${colors.xxlWhite};
  transition:
    transform 0.2s ease-out,
    opacity 0.1s ease-out,
    top 0.2s ease-out;
`;

const Link = styled.a`
  display: block;
  width: ${HAMBURGER_WIDTH};
  height: ${HAMBURGER_HEIGHT};
`;

export { Hamburger, Line, Link };
