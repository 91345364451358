import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  Wrapper,
  Heading,
  Description,
  Label,
  Fieldset,
  DialogBoxHeading,
  SubmitButtonWrapper,
} from "../Styles/TeamAdminBox.styled";
import { Check } from "@xxl/icons";
import { DialogBox } from "../../DialogBox";
import {
  createRequisitionFunction,
  getTeamAdminAccountInfo,
} from "../Api/CartAPI";
import { useSharedData } from "../../../contexts/SharedData";
import type {
  CreateRequisitionInput,
  TeamAdminAccountInfoQuery,
} from "../../../generated/graphql-code-generator";
import { Field, type FieldProps, Form, Formik } from "formik";
import { FormErrorMessage, Input, Textarea } from "../../../styled";
import * as yup from "yup";
import { TeamAdminBoxConfirmation } from "./TeamAdminBoxConfirmation";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { ACCOUNT_BASE_PATH } from "../../../constants";
import { XxlButton } from "../../Common/XxlButton";

type AccountData = Pick<TeamAdminAccountInfoQuery, "account">["account"];

export const TeamAdminBox: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const [isDialogBoxVisible, setIsDialogBoxVisible] = useState(false);
  const [accountData, setAccountData] = useState<AccountData | undefined>(
    undefined
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const {
    configuration: {
      amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
    },
  } = useSharedData().data;

  const getAccountInfo = async () => {
    try {
      if (accountData === undefined) {
        const response = await getTeamAdminAccountInfo(
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey
        );

        if (response.data.data?.account !== undefined) {
          setAccountData(response.data.data.account);
        }
      }
    } catch (err) {
      console.error("Cannot get account info", err);
    }
  };

  const createRequisition = async (
    values: CreateRequisitionInput,
    setSubmittingFunction: (isSubmitting: boolean) => void
  ) => {
    try {
      const response = await createRequisitionFunction(
        values,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      if (isNotNullOrUndefined(response.data.data)) {
        setShowCheckmark(true);
        setIsSent(true);
        setTimeout(() => {
          setShowCheckmark(false);
          setShowForm(false);
          setShowConfirmation(true);
        }, 500);
      }
    } catch (err) {
      console.error("Cannot create requisition", err);
    } finally {
      setSubmittingFunction(false);
    }
  };

  const toggleDialogBox = () => {
    setIsDialogBoxVisible(!isDialogBoxVisible);
    if (isSent) {
      document.location.href = ACCOUNT_BASE_PATH;
    }
  };

  useEffect(() => {
    void getAccountInfo();
  }, []);

  const teamAdminBoxSchema = yup.object({
    firstname: yup
      .string()
      .min(2, t("cart.team.admin.name.length.error"))
      .required(t("cart.team.admin.name.empty.error")),
    lastname: yup
      .string()
      .min(3, t("cart.team.admin.surname.length.error"))
      .required(t("cart.team.admin.surname.empty.error")),
    phone: yup.string().required(t("cart.team.admin.phone.empty.error")),
    email: yup
      .string()
      .email(t("cart.team.admin.email.format.error"))
      .required(t("cart.team.admin.email.empty.error")),
    invoice: yup.string().required(t("cart.team.admin.invoice.empty.error")),
  });

  return (
    <>
      <Wrapper>
        <Heading>{t("cart.team.admin.heading")}</Heading>
        <Description>{t("cart.team.admin.description")}</Description>
        <XxlButton variant="secondary" onClick={toggleDialogBox} size="large">
          {t("cart.team.admin.button")}
        </XxlButton>
      </Wrapper>
      <DialogBox
        isDialogBoxOpen={isDialogBoxVisible}
        hasPadding={true}
        dialogBoxSize={"md"}
        handleDialogBoxClosing={toggleDialogBox}
      >
        {showForm && (
          <>
            <DialogBoxHeading>{t("cart.team.admin.heading")}</DialogBoxHeading>
            <Formik
              initialValues={{
                firstname: accountData?.firstName ?? "",
                lastname: accountData?.lastName ?? "",
                email: accountData?.email ?? "",
                phone: accountData?.mobilePhone ?? "",
                invoice: "",
              }}
              validationSchema={teamAdminBoxSchema}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                teamAdminBoxSchema
                  .validate(values)
                  .then((valid) => {
                    return void createRequisition(valid, actions.setSubmitting);
                  })
                  .catch((error) => {
                    console.error("Schema is not valid", error);
                  });
              }}
            >
              {({ isSubmitting, errors, touched }) => {
                if (Object.keys(errors).length > 0 && !isSubmitDisabled) {
                  setIsSubmitDisabled(true);
                } else if (
                  Object.keys(errors).length === 0 &&
                  isSubmitDisabled &&
                  touched.invoice === true
                ) {
                  setIsSubmitDisabled(false);
                }
                return (
                  <Form>
                    <Fieldset disabled={isSubmitting}>
                      <Label>{t("cart.team.admin.name")}</Label>
                      <Field name="firstname">
                        {
                          (({ field, meta }) => (
                            <>
                              <Input
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.name.placeholder"
                                )}
                                required={true}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <Label>{t("cart.team.admin.surname")}</Label>
                      <Field name="lastname">
                        {
                          (({ field, meta }) => (
                            <>
                              <Input
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.surname.placeholder"
                                )}
                                required={true}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <Label>{t("cart.team.admin.phone")}</Label>
                      <Field name="phone">
                        {
                          (({ field, meta }) => (
                            <>
                              <Input
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.phone.placeholder"
                                )}
                                disabled={true}
                                required={true}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <Label>{t("cart.team.admin.email")}</Label>
                      <Field name="email">
                        {
                          (({ field, meta }) => (
                            <>
                              <Input
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.email.placeholder"
                                )}
                                disabled={true}
                                required={true}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <Label>{t("cart.team.admin.invoice")}</Label>
                      <Field name="invoice">
                        {
                          (({ field, meta }) => (
                            <>
                              <Input
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.invoice.placeholder"
                                )}
                                required={true}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <Label htmlFor="message">
                        {t("cart.team.admin.message")}
                      </Label>
                      <Field name="message">
                        {
                          (({ field, meta }) => (
                            <>
                              <Textarea
                                {...field}
                                placeholder={t(
                                  "cart.team.admin.message.placeholder"
                                )}
                                rows={6}
                                maxLength={500}
                              />
                              <FormErrorMessage>
                                {meta.touched && Boolean(meta.error)
                                  ? meta.error
                                  : null}
                              </FormErrorMessage>
                            </>
                          )) as (props: FieldProps) => React.ReactNode
                        }
                      </Field>
                      <SubmitButtonWrapper>
                        <XxlButton
                          type="submit"
                          variant="secondary"
                          size="large"
                          disabled={isSubmitDisabled}
                          loading={isSubmitting}
                          style={{
                            minWidth: "300px",
                            width: "fit-content",
                          }}
                        >
                          {showCheckmark ? (
                            <Check />
                          ) : (
                            <>{t("cart.team.admin.send.for.approval")}</>
                          )}
                        </XxlButton>
                      </SubmitButtonWrapper>
                    </Fieldset>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        {showConfirmation && (
          <TeamAdminBoxConfirmation closeTrigger={toggleDialogBox} />
        )}
      </DialogBox>
    </>
  );
};
