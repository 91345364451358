import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../../styles/xxl-theme";
import { focusVisibleStyles } from "./ProductItem.styled";

const { spaces, colors } = xxlTheme;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: ${spaces.regular};
  position: relative;
`;

export const Form = styled.form`
  padding: 0 ${spaces.smallRegular} ${spaces.smallRegular};
`;

type InputProps = {
  hasError: boolean;
};

export const Input = styled.input<InputProps>(
  ({ hasError }) => css`
    width: 100%;
    height: 50px;
    border: 1px solid ${hasError ? colors.xxlError : colors.xxlGrey};
    display: flex;
    align-items: center;
    padding: 0 ${spaces.regular};
    box-sizing: border-box;
    margin: ${spaces.smallRegular} 0;
    ${focusVisibleStyles};
  `
);

export const ErrorMessage = styled.p`
  margin: 0 0 ${spaces.smallRegular};
  color: ${colors.xxlError};
`;
