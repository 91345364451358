import type {
  AuthCookieData as RawAuthCookieData,
  AuthApi,
} from "@xxl/frontend-api";
import { logError } from "../utils/xxl-log";

export type AuthCookieData = Required<
  Pick<
    RawAuthCookieData,
    "accessToken" | "cookieVersion" | "expires" | "lastModified"
  >
> &
  RawAuthCookieData;

/**
 * @deprecated browser-js shouldn't access tokens
 *
 * Returns auth data for logged-in users, undefined for guests
 */
export const getAuthData = async (
  authApi: AuthApi
): Promise<AuthCookieData | undefined> => {
  try {
    const axiosResponse = await authApi.authControllerGetAccessTokenGET();
    const {
      accessToken,
      cookieVersion,
      expires,
      lastModified,
      ...optionalFields
    } = axiosResponse.data;
    if (
      accessToken !== undefined &&
      cookieVersion !== undefined &&
      expires !== undefined &&
      lastModified !== undefined
    ) {
      return {
        accessToken,
        cookieVersion,
        expires,
        lastModified,
        ...optionalFields,
      };
    } else {
      return undefined;
    }
  } catch (err) {
    logError("Unable to get access token", err);
    return Promise.reject();
  }
};
