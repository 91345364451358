import { CONTENT_NAMES, type ContentName } from "@/react-app/api/elevate-api";
import { EMPTY_STRING, FILTER_SEPARATOR } from "@/react-app/constants";
import type { TranslationKey } from "@/react-app/translations";
import type { TabProps } from "@/react-components/Common/Tabs";
import {
  type AVAILABILITY,
  isAvailability,
} from "@/react-components/Search/SearchFetchProductsHelper.types";
import type {
  BrandsDataProps,
  FaqDataProps,
  OpeningHours,
  StoresDataProps,
} from "@/react-components/Search/SearchState";
import type { SearchSuggestionsType } from "@/react-components/Search/SearchSuggestions";
import type { StoreWithId } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect";
import type { GuidePreviewContentDataWithTrackingTicket } from "@/react-components/guideCarousel/GuideTeaser";
import {
  cookieNames,
  parseCookie,
  type PreferredStoreIds,
} from "@/react-utils/Cookie";
import {
  hasNoValue,
  isEmpty,
  isNotEmpty,
  isNotNullOrUndefined,
} from "@xxl/common-utils";
import type {
  ContentData,
  ContentListData,
  FacetData,
} from "@xxl/product-search-api";
import type { IncomingMessage, ServerResponse } from "http";
import isString from "lodash/isString";
import type { EcomSiteUidLegacy, XXLCookie } from "react-app/src/global";
import { URL_PARAMETERS } from "../../components/ProductListV2/constants";
import { getStores } from "../apis/content-stores";
import {
  COOKIE_EXPIRATION_IN_SECONDS,
  createCookieString,
  createXxlCookie,
} from "../cookies/server-side-cookie-helper";
import type { queryParamType } from "../page-helper";
import {
  getCachedStoreData,
  setCachedStoreData,
} from "../server-side-cache/server-side-cache";
import { UNSUPPORTED_FACET_IDS, UNSUPPORTED_FACET_TYPES } from "./constants";

const {
  brand,
  faqEntry,
  guide,
  products,
  store: storeContentTabName,
} = CONTENT_NAMES;
const SUPPORTED_TABS = [brand, faqEntry, guide, products, storeContentTabName];

const isValidSearchQuery = (query: queryParamType): query is string =>
  isString(query) && isNotEmpty(query);

type TabWithTranslationKeyAndContent = Omit<TabProps, "name" | "id"> & {
  name: TranslationKey;
  id: ContentName;
  items: ContentData[];
};

const getTranslationKeyToContentName = (
  contentName: string
): TranslationKey => {
  switch (contentName) {
    case CONTENT_NAMES.brand:
      return "header.search.suggestions.brands" as TranslationKey;
    case CONTENT_NAMES.category:
      return "header.search.suggestions.categories" as TranslationKey;
    case CONTENT_NAMES.faqEntry:
      return "header.search.suggestions.faq" as TranslationKey;
    case CONTENT_NAMES.guide:
      return "header.search.suggestions.guides" as TranslationKey;
    case CONTENT_NAMES.store:
      return "header.search.suggestions.stores" as TranslationKey;
    default:
      return "header.search.suggestions.other" as TranslationKey;
  }
};

const getSelectedFiltersFromUrlParameters = (urlParameters: {
  [key: string]: queryParamType;
}) => {
  const selectedFilters = Object.fromEntries(
    Object.entries(urlParameters)
      .filter(([key]) => key.includes(URL_PARAMETERS.facet.name))
      .filter((filter): filter is [string, string] => isString(filter.at(1)))
  );

  const selectedFiltersFormatted = Object.fromEntries(
    Object.entries(selectedFilters).map(([key, value]) => [
      key.replace(URL_PARAMETERS.facet.name, EMPTY_STRING),
      value.split(FILTER_SEPARATOR),
    ])
  );

  return {
    selectedFilters,
    selectedFiltersFormatted,
  };
};

const convertContentListsToTabs = (
  contentLists: ContentListData[],
  numberOfProducts = 0
): TabWithTranslationKeyAndContent[] => {
  const generatedTabList = contentLists
    .filter(({ id }) => SUPPORTED_TABS.some((tab) => tab === id))
    .map((contentList) => ({
      count: contentList.totalHits,
      id: contentList.id as ContentName,
      isActive: false,
      items: isNotEmpty(contentList.items) ? contentList.items : [],
      name: getTranslationKeyToContentName(contentList.id),
    }));

  return numberOfProducts > 0
    ? [
        {
          count: numberOfProducts,
          id: CONTENT_NAMES.products,
          isActive: false,
          items: [],
          name: "header.search.suggestions.products",
        },
        ...generatedTabList,
      ]
    : generatedTabList;
};

type RelatedSearchType = { q?: string }[] | undefined;
const prepareSearchSuggestions = (
  initialRelatedSearches: RelatedSearchType
): SearchSuggestionsType => {
  if (initialRelatedSearches === undefined) {
    return [];
  }

  return initialRelatedSearches
    .filter((relatedSearch) => Boolean(relatedSearch.q))
    .map((relatedSearch) => relatedSearch.q) as SearchSuggestionsType;
};

const getItemsImageBaseUrl = (item: ContentData) => {
  if (
    isEmpty(item.image) ||
    !Array.isArray(item.image) ||
    isEmpty(item.image[0].sources) ||
    !Array.isArray(item.image[0].sources) ||
    isEmpty(item.image[0].sources[0].url)
  ) {
    return "";
  }
  return item.image[0].sources[0].url;
};

const getCorrectTabNameOrFirstOrNull = (
  tabName: queryParamType,
  tabs: TabWithTranslationKeyAndContent[],
  noProductsFound: boolean
): ContentName | null => {
  if (
    isString(tabName) &&
    isNotEmpty(tabName) &&
    Object.values(CONTENT_NAMES).includes(tabName as ContentName)
  ) {
    return tabName as ContentName;
  }
  if (!noProductsFound) {
    return CONTENT_NAMES.products;
  }
  return tabs.find(({ items }) => isNotEmpty(items))?.id ?? null;
};

const getGuidesFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): GuidePreviewContentDataWithTrackingTicket[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.guide)
    ?.items.map((item) => ({
      image: {
        ...item.image,
        baseUrl: getItemsImageBaseUrl(item),
        link: item.link,
      },
      metaDescription: item.description,
      pageTitle: item.title,
      preamble: item.description,
      title: item.title,
      url: item.link,
      ticket: item.ticket,
    })) ?? [];

const getBrandsFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): BrandsDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.brand)
    ?.items.map((item) => ({
      brandUrl: item.link,
      code: false,
      id: item.title,
      logo: { alt: item.title, url: getItemsImageBaseUrl(item) },
      name: item.title,
      ticket: item.ticket,
    })) ?? [];

const getFaqsFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): FaqDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.faqEntry)
    ?.items.map((item) => ({
      id: item.title,
      title: item.title,
      url: item.link,
      preamble: item.description,
      ticket: item.ticket,
    })) ?? [];

const getStoresFromTabs = (
  tabs: TabWithTranslationKeyAndContent[]
): StoresDataProps[] =>
  tabs
    .find((tab) => tab.id === CONTENT_NAMES.store)
    ?.items.map((item) => ({
      id: item.title,
      name: item.title,
      openingHours:
        (item.custom?.contentCustomAttributes?.[0]?.openingHours as
          | OpeningHours[]
          | undefined) ?? [],
      link: item.link,
      ticket: item.ticket,
    })) ?? [];

const setCookies = async ({
  cookies,
  req,
  res,
}: {
  cookies: Partial<{ [key: string]: string }>;
  req: IncomingMessage & {
    cookies: Partial<{
      [key: string]: string;
    }>;
  };
  res: ServerResponse;
}) => {
  const xxlCookie = cookies[cookieNames.XXL];
  const cookiesToSet = [];
  const { host } = req.headers;
  // const domain = host?.split(".").slice(1).join(".").split(":")[0]; // reverts this change to check
  const domain =
    isNotNullOrUndefined(host) && host.startsWith("localhost.")
      ? host.replace("localhost.", "")
      : host;
  const sessionKey = cookies[cookieNames.SESSION_KEY] ?? null;
  const customerKey = parseCookie<XXLCookie>(
    xxlCookie,
    cookieNames.XXL
  )?.customerKey;
  const userKeys = {
    customerKey: isNotNullOrUndefined(customerKey) ? customerKey : "",
    sessionKey: isNotNullOrUndefined(sessionKey) ? sessionKey : "",
  };

  if (userKeys.sessionKey.length === 0) {
    userKeys.sessionKey = crypto.randomUUID();
    cookiesToSet.push(
      createCookieString({
        cookie: userKeys.sessionKey,
        domain,
        maxAge: "Session",
        name: cookieNames.SESSION_KEY,
      })
    );
  }

  if (userKeys.customerKey.length === 0) {
    const _xxlCookie = await createXxlCookie();
    userKeys.customerKey = _xxlCookie.customerKey;
    cookiesToSet.push(
      createCookieString({
        cookie: _xxlCookie,
        doubleEncode: true,
        domain,
        maxAge: COOKIE_EXPIRATION_IN_SECONDS,
        name: cookieNames.XXL,
      })
    );
  }

  if (cookiesToSet.length > 0) {
    res.setHeader("Set-Cookie", cookiesToSet);
  }

  return userKeys;
};

const convertUrlParamToStringArray = (
  parameter: queryParamType
): string[] | null => {
  const fallback: string[] = [];

  if (parameter === undefined || typeof parameter !== "string") {
    return null;
  }

  try {
    const convertedIds = JSON.parse(parameter) as string[];

    if (Array.isArray(convertedIds)) {
      return convertedIds;
    }

    return fallback;
  } catch (error) {
    return fallback;
  }
};

const convertStoreIdsFromUrl = (
  storeIds: queryParamType
): PreferredStoreIds | null => convertUrlParamToStringArray(storeIds);

const convertAvailabilityFromUrl = (
  availability: queryParamType
): AVAILABILITY[] | null => {
  const convertedAvailability = convertUrlParamToStringArray(availability);

  if (
    isAvailability(convertedAvailability) &&
    convertedAvailability.length > 0
  ) {
    return convertedAvailability;
  }

  return null;
};

const getCachedOrFetchedStoreData = async (siteUid: EcomSiteUidLegacy) => {
  let cachedStoresData: Required<StoreWithId>[] | null = null;
  try {
    cachedStoresData = await getCachedStoreData();
  } catch (error) {
    console.error("Failed to retrieve cached stores data", error);
  }
  const stores = cachedStoresData ?? (await getStores(siteUid));

  if (cachedStoresData === null) {
    void setCachedStoreData(stores);
  }

  return stores.filter((store) => store.elevateEnabled);
};

const sanitizeFacetsForDisplay = (initialFacets: FacetData[]) => {
  if (isEmpty(initialFacets)) {
    return [];
  }

  return initialFacets
    .filter(
      (facet) =>
        isNotNullOrUndefined(facet) &&
        !UNSUPPORTED_FACET_TYPES.some(
          (unsupportedType) => unsupportedType === facet.type
        ) &&
        !UNSUPPORTED_FACET_IDS.some(
          (unsupportedId) => unsupportedId === facet.id
        )
    )
    .map(({ id, label, type, ...initialFacet }) => {
      if (hasNoValue(id) || hasNoValue(label) || hasNoValue(type)) {
        return null;
      }
      const facet = {
        ...initialFacet,
        id,
        label,
        type,
      };
      if ("sizeTypes" in facet) {
        const { sizeTypes = [], ...restOfFacet } = facet;
        const values = sizeTypes.flatMap(({ formats = [] }) =>
          formats.flatMap(({ values: v }) => v)
        );
        return {
          ...restOfFacet,
          values,
        };
      }
      return facet;
    })
    .filter(isNotNullOrUndefined);
};

export {
  convertAvailabilityFromUrl,
  convertContentListsToTabs,
  convertStoreIdsFromUrl,
  getBrandsFromTabs,
  getCachedOrFetchedStoreData,
  getCorrectTabNameOrFirstOrNull,
  getFaqsFromTabs,
  getGuidesFromTabs,
  // eslint-disable-next-line import/no-unused-modules -- used in tests
  getItemsImageBaseUrl,
  getSelectedFiltersFromUrlParameters,
  getStoresFromTabs,
  isValidSearchQuery,
  prepareSearchSuggestions,
  sanitizeFacetsForDisplay,
  setCookies,
  type TabWithTranslationKeyAndContent,
};
