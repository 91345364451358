import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { CategoryData, PriceDisplayData } from "@xxl/frontend-api";
import { Star } from "@xxl/icons";
import React from "react";
import { useSessionSource } from "../../contexts/Session";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { getFrontendPriceDisplayData } from "../../utils/PriceDisplay/price-display";
import { CategoryIcon } from "../MegaMenu/Mobile/CategoryIcon";
import { ExpandableDescription } from "../Common/ExpandableDescription/ExpandableDescription";
import {
  RecommendationBanner,
  ServiceBrandName,
  ServiceDescription,
  ServiceElement,
  ServiceIcon,
  ServiceIconNameAndPrice,
  ServiceName,
} from "./ServiceProduct.styled";
import { useSharedData } from "../../contexts/SharedData";
import { useRelinkValue } from "react-relink";
import { useTracking } from "../../contexts/Tracking";
import { ProductType } from "@xxl/pim-api";
import { XxlStack } from "../../components/Common/XxlStack";
import { PriceWithLabels } from "../Product/PriceWithLabels/PriceWithLabels";

type ServiceProductDumbProps = {
  brand?: CategoryData | string;
  category?: string;
  code?: string;
  description?: string;
  isLoading?: boolean;
  isProductPage?: boolean;
  isRecommended: boolean;
  isSelected: boolean;
  name?: string;
  price?: PriceDisplayData;
  serviceDetailsRef?: React.RefObject<HTMLLIElement>;
  productType?: ProductType;
  PurchaseDetails: React.ReactNode;
};

export const ServiceProductDumb: React.FunctionComponent<
  ServiceProductDumbProps
> = ({
  brand,
  category,
  code,
  description,
  isProductPage = false,
  isRecommended,
  isSelected,
  name,
  price,
  serviceDetailsRef,
  productType,
  PurchaseDetails,
}) => {
  const { t } = useTranslations();
  const tracking = useTracking();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { siteUid } = useSharedData().data;
  if (price === undefined) {
    return null;
  }

  const brandName = typeof brand === "string" ? brand : brand?.name ?? "";
  const transformedPrice = getFrontendPriceDisplayData({
    priceDisplay: price,
    t,
    isLoggedIn,
    siteUid,
  });

  const trackReadMoreButtonClick = (
    isProductPage: boolean,
    label: string
  ): void => {
    if (isProductPage) {
      tracking.sendReadMoreServicePDPClickEvent(label);
    } else {
      tracking.sendReadMoreServiceCartClickEvent(label);
    }
  };

  return (
    <ServiceElement
      ref={serviceDetailsRef}
      data-name={name}
      data-id={code}
      data-brand={brandName}
      data-price={transformedPrice.salesPrice}
      data-variant={code}
      className={isSelected ? "is-selected" : undefined}
    >
      {isRecommended ? (
        <RecommendationBanner>
          {t("services.popup.recommended.service.label")}
          <Star />
        </RecommendationBanner>
      ) : null}
      <ServiceIconNameAndPrice>
        <XxlStack>
          {category !== undefined && (
            <ServiceIcon>
              <CategoryIcon code={category} size={18} />
            </ServiceIcon>
          )}
          <ServiceBrandName data-testid="service-brand-name">
            {brandName}
          </ServiceBrandName>
          <ServiceName data-testid="service-name">{name}</ServiceName>
        </XxlStack>
        <PriceWithLabels
          version={1}
          priceDisplay={price}
          productType={productType ?? ProductType.NORMAL}
          t={t}
          selectedColumnsNumber={6}
          isCompact={true}
        />
      </ServiceIconNameAndPrice>
      {isNotNullOrUndefined(description) && (
        <ServiceDescription>
          <ExpandableDescription
            description={description}
            descriptionTestId="service-description"
            numberOfLinesToShow={3}
            showMoreButtonTestId="service-show-more-button"
            trackingCb={(trackingLabel) =>
              trackReadMoreButtonClick(isProductPage, trackingLabel)
            }
          />
        </ServiceDescription>
      )}
      {PurchaseDetails}
    </ServiceElement>
  );
};
