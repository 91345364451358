import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { SubCategoryListItem } from "./SubCategoryListItem";
import { LinkListItem } from "./LinkListItem";
import { ClubLogo } from "./Navigation.styled";
import { CLUB_ASSORTMENT_LINKS_CODE, ICON_SIZE_PX } from "./constants";
import type { ClubAssortmentItemProps } from "./types";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import stringFormat from "string-format";
import { useSharedData } from "../../../contexts/SharedData";

const ONE_CLUB_SIZE = 1;

const getUrlWithSize = (url: string, size: number) =>
  `${url}&h=${size}&w=${size}`;

const ClubAssortmentItem: React.FunctionComponent<ClubAssortmentItemProps> = ({
  clubAssortmentLinks,
  isActive,
  onClick,
}) => {
  const { t } = useTranslations();
  const { requestMapping } = useSharedData().data;
  const { clubAssortment } = requestMapping;

  if (clubAssortmentLinks.length === ONE_CLUB_SIZE) {
    const [clubAssortmentLink] = clubAssortmentLinks;
    const { logoUrl, uid } = clubAssortmentLink;
    const href = stringFormat(clubAssortment, uid);
    return (
      <LinkListItem href={href}>
        {isNotNullOrUndefined(logoUrl) && (
          <ClubLogo
            srcSet={`
              ${getUrlWithSize(logoUrl, ICON_SIZE_PX)},
              ${getUrlWithSize(logoUrl, ICON_SIZE_PX * 1.5)} 1.5x,
              ${getUrlWithSize(logoUrl, ICON_SIZE_PX * 2)} 2x,
            `}
            src={getUrlWithSize(logoUrl, ICON_SIZE_PX)}
          />
        )}
        {t("teamsales.account.nav.assortment")}
      </LinkListItem>
    );
  }
  return (
    <SubCategoryListItem
      displayName={t("teamsales.account.nav.assortment")}
      icon={null}
      id={CLUB_ASSORTMENT_LINKS_CODE}
      isActive={isActive}
      onClick={onClick}
    />
  );
};

export { ClubAssortmentItem };
