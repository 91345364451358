import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { NextImage } from "../../common/NextImage/NextImage";
import { color } from "@xxl/theme";

const THUMBNAIL_SIZE = 50;

export const SlideLink = styled.a<{ disabled: boolean }>`
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: ${THUMBNAIL_SIZE}px;
  gap: ${xxlTheme.spaces.mini};
  word-wrap: break-word;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

export const StyledImage = styled(NextImage)<{ selected: boolean }>`
  outline: 1px solid
    ${({ selected }) => (selected ? color.webBlack.hex : color.gray.hex)};
  outline-offset: -1px;
  max-width: calc(100% - 2px);
  max-height: calc(100% - 2px);
`;
