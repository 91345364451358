import React from "react";
import Image, { type ImageProps } from "next/image";
import { imageLoader } from "../../../utils/image-loader/image-loader";

type Props = ImageProps & {
  hotspot?: {
    x: number;
    y: number;
  };
  padding?: number;
};

export const NextImage = ({ hotspot, padding, ...props }: Props) => {
  return <Image loader={imageLoader(hotspot, padding)} {...props} />;
};
