import { EventEventNameEnum } from "@xxl/deliverystream-api";
import type {
  CartProductProperties,
  Context,
  CreateEventResponse,
  Event,
  EventstreamEvent,
  ProductProperties,
  OrderProperties,
} from "@xxl/deliverystream-api";
import { uuidv4 } from "../xxl-uuid";
import {
  cookieNames,
  getCookieRaw,
  getXXLCookie,
  isMarketingCookieEnabled,
  setCookie,
} from "../Cookie";
import type { DeliveryStreamContext, EventProperties } from "./types";
import { getApprovedConsents } from "./gcm";
import { logError } from "../xxl-log";

const getAnonymizedWindowHref = () =>
  window.location.href.replace(/\/[^/]+@[^/]+/g, "/--");

const createSessionId = (): string => {
  const sessionId = uuidv4();
  setCookie(cookieNames.EVENTSTREAM_SESSION, sessionId);
  return sessionId;
};

const getSessionId = (): string | null =>
  getCookieRaw(cookieNames.EVENTSTREAM_SESSION);

class DeliveryStream {
  private readonly context: DeliveryStreamContext;

  constructor(context: DeliveryStreamContext) {
    this.context = context;
  }

  private getEventContext = async (): Promise<Context> => {
    const { siteUid, pageType } = this.context;
    const xxlCookie = await getXXLCookie();
    const perfData = window.performance.timing;
    return {
      siteUid,
      pageType,
      consents: getApprovedConsents(),
      site: document.location.hostname.replace("www.", ""),
      url: getAnonymizedWindowHref(),
      loopId: xxlCookie?.loopId,
      userAgent: navigator.userAgent,
      fbp: getCookieRaw(cookieNames.FBP) ?? undefined,
      fbc: getCookieRaw(cookieNames.FBC) ?? undefined,
      referrer: document.referrer,
      ...perfData,
      secureConnectionStart:
        document.location.protocol === "https:" ? perfData.fetchStart : 0,
    };
  };

  private createEvent = async (
    eventName: EventEventNameEnum,
    properties: EventProperties
  ): Promise<Event> => ({
    eventName,
    eventId: uuidv4(),
    sentAt: Date.now(),
    context: await this.getEventContext(),
    properties,
  });

  private createEventWrapper = (event: Event): EventstreamEvent => ({
    eventList: [event],
    sessionId: getSessionId() ?? createSessionId(),
    userId: getCookieRaw(cookieNames.MEMBER_NUMBER),
  });

  private sendEvent = async (
    eventName: EventEventNameEnum,
    properties: EventProperties,
    enrich?: (p: EventProperties) => Promise<EventProperties>
  ): Promise<CreateEventResponse | void> => {
    try {
      if (!isMarketingCookieEnabled()) {
        return;
      }
      const props =
        enrich !== undefined ? await enrich(properties) : properties;
      const event = await this.createEvent(eventName, props);

      const { data } = await this.context.api.createEvent(
        this.createEventWrapper(event)
      );
      return data;
    } catch (error) {
      logError("Failed sending event to deliverystream.", error);
    }
  };

  private enrichWithCustomerDetails = async (
    properties: EventProperties
  ): Promise<EventProperties> => ({
    ...properties,
    customer: await this.context.getCustomerDetails(),
  });

  public sendAddToCartEvent = async (
    properties: CartProductProperties
  ): Promise<CreateEventResponse | void> => {
    return this.sendEvent(EventEventNameEnum.AddToCart, properties);
  };

  public sendViewEvent = async (
    properties?: EventProperties
  ): Promise<CreateEventResponse | void> => {
    return this.sendEvent(EventEventNameEnum.view, properties ?? {});
  };

  public sendViewEventForCheckout = async ({
    totalPrice,
    lines,
  }: Pick<
    OrderProperties,
    "totalPrice" | "lines"
  >): Promise<CreateEventResponse | void> => {
    return this.sendEvent(
      EventEventNameEnum.view,
      // customer will be fetched by enrichWithCustomerDetails from graphql if needed
      {
        totalPrice: {
          value: totalPrice?.value,
          currency: this.context.siteCurrency,
        },
        lines,
        customer: {},
      },
      this.enrichWithCustomerDetails
    );
  };

  public sendOrderConfirmationEvent = async (
    EventStreamConfirmationData: OrderProperties
  ): Promise<CreateEventResponse | void> => {
    return this.sendEvent(
      EventEventNameEnum.checkout,
      EventStreamConfirmationData
    );
  };

  public sendClickEvent = async (
    properties: ProductProperties
  ): Promise<CreateEventResponse | void> => {
    return this.sendEvent(EventEventNameEnum.click, properties);
  };
}

export { DeliveryStream };
