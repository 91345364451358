import styled from "@emotion/styled/macro";
import { typographyToCss, MQ } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
import text from "./ChangeModal.typography";

const { spaces, colors, zIndex } = xxlTheme;

interface FormLabelProps {
  isRequired?: boolean;
}

export const StyledOverlay = styled.div`
  width: 100%;
  min-height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: ${zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${colors.xxlWhite};
  padding: ${spaces.large} ${spaces.smallRegular};
  position: relative;
  overflow-y: auto;

  ${MQ("tablet")} {
    min-width: 400px;
    min-height: 0;
    width: 50%;
    height: auto;
    padding: ${spaces.large};
  }

  ${MQ("tabletHorizontal")} {
    max-width: 480px;
  }
`;

export const CloseButton = styled.button`
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${spaces.regular};
  right: ${spaces.smallRegular};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  ${MQ("tablet")} {
    top: ${spaces.smallLarge};
    right: ${spaces.smallLarge};
  }
`;

export const StyledCloseIcon = styled.svg`
  width: 100%;
  height: 100%;
  color: ${colors.xxlBlack};
  pointer-events: none;
`;

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
`;

export const StyledFormHeading = styled.h3`
  margin: 0;
`;

export const StyledFormDescription = styled.p`
  ${typographyToCss(text.description)};
  margin: 0;
  margin-top: ${spaces.smallRegular};
`;

export const StyledIFrameContainer = styled.div`
  ${typographyToCss(text.description)};
  line-height: 0.8;
  margin: 0;
  margin-top: ${spaces.smallRegular};
`;

export const StyledFormMain = styled.section`
  margin-top: ${spaces.large};
  min-height: 100px;
`;

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${spaces.smallRegular};
`;

export const StyledFormLabel = styled.label<FormLabelProps>`
  ${typographyToCss(text.form)};
  margin-bottom: ${spaces.mini};

  ${({ isRequired = false }) =>
    isRequired
      ? ` &::after {
          content: "*";
      }`
      : ""};
`;

export const StyledFormInput = styled.input`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid ${colors.xxlLightGrey};
  padding: ${spaces.smallRegular};
  ${typographyToCss(text.form)};
  color: ${colors.xxlWebBlack};

  &::placeholder {
    color: ${colors.xxlGrey};
  }
`;

export const StyledFormButton = styled.button`
  min-width: 100px;
  height: 50px;
  margin-top: ${spaces.large};
  position: relative;
  width: auto;
  margin-left: auto;
`;

export const StyledFormButtonClose = styled.button`
  min-width: 100px;
  height: 50px;
  margin-top: ${spaces.large};
  position: relative;
  width: 100%;
  grid-column: 2;
`;

export const StyledFormError = styled.span`
  ${typographyToCss(text.form)};
  color: ${colors.xxlError};
  margin-top: ${spaces.mini};
`;

export const StyledFormFooter = styled.section`
  ${MQ("tablet")} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${spaces.smallRegular};
  }
`;

export const Container = styled.div`
  padding-top: ${spaces.large};
`;

export const TwoColumnFieldsContainer = styled.div`
  margin-bottom: 36px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spaces.large};
  ${MQ("tablet")} {
    grid-template-columns: 1fr 1fr;
  }
`;
