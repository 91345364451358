import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { CaretIcon } from "../../Common/Icons/Caret";
import {
  SUCCESS_RESPONSE_STATUS,
  removeCouponsFromEGCart,
  submitCouponsToEGCart,
} from "../Api/CartAPI";
import { logError } from "../../../utils/xxl-log";
import type { CouponNotFoundError, GenericGraphQLError } from "../Api/types";
import {
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  CART_EDITION_START,
  CART_EDITION_STOP,
  UPDATE_CART_CONTENT,
  isKlarnaCheckoutSnippetLoaded,
  isWalley,
  useCartContext,
} from "../CartState";
import { CHECKOUT_SNIPPET_UPDATE_TIMEOUT } from "../Services/updateCartQuantity";
import { ErrorMessage, Form, Input } from "../Styles/Coupons.styled";
import {
  HeadingButton,
  HeadingButtonAnimationWrapper,
  Wrapper,
} from "../Styles/PersonalOffers.styled";
import { ERROR_DISPLAY_TIMEOUT } from "./CartItem";

export const Coupons: React.FunctionComponent = () => {
  const { state, dispatch } = useCartContext();
  const { t } = useTranslations();
  const { configuration } = useSharedData().data;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [coupon, setCoupon] = useState("");
  const hasAppliedCoupon =
    state.displayCart?.appliedCoupons !== undefined &&
    state.displayCart.appliedCoupons.length > 0 &&
    state.displayCart.appliedCoupons[0]?.code !== undefined;
  const expandForm = () => {
    setIsExpanded(!isExpanded);
    setIsAnimating(true);
  };

  const unlockCheckoutSnippet = () => {
    if (isKlarnaCheckoutSnippetLoaded()) {
      window._klarnaCheckout((api) => {
        api.resume();
      });
    }
    if (isWalley(state.paymentProvider)) {
      window.walley?.checkout.api.resume();
    }
    setTimeout(() => {
      dispatch({
        type: CART_CONTENT_EDITING_UNLOCK,
      });
    }, CHECKOUT_SNIPPET_UPDATE_TIMEOUT);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
  };

  const closeOverlay = () => {
    setErrorText(undefined);
    setIsErrorMessageVisible(false);
    setCoupon("");
  };

  const _submitCoupon = async (code: string) => {
    const response = await submitCouponsToEGCart(
      code,
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      configuration.amplifyConfig.aws_appsync_apiKey
    );
    try {
      if (response.status === SUCCESS_RESPONSE_STATUS) {
        const { data } = response;

        if (
          data.data !== undefined &&
          data.data !== null &&
          data.data.addCouponToCart.coupons.length > 0
        ) {
          dispatch({
            type: CART_EDITION_STOP,
          });
          dispatch({
            type: UPDATE_CART_CONTENT,
          });
        }
      } else {
        const couponNotFoundError = (
          (response.data.errors ?? []) as GenericGraphQLError[]
        ).some(
          (error): error is CouponNotFoundError =>
            error.errorType === "COUPON_NOT_FOUND"
        );
        setErrorText(
          t(
            couponNotFoundError
              ? "coupon.message.invalid"
              : "checkout.coupon.unknown.error"
          )
        );
        setIsErrorMessageVisible(true);
        unlockCheckoutSnippet();
        dispatch({
          type: CART_EDITION_STOP,
        });
        setTimeout(() => {
          closeOverlay();
        }, ERROR_DISPLAY_TIMEOUT);
      }
    } catch (err) {
      logError("Cannot get coupons", err);
    }
  };

  const _removeCoupon = async () => {
    const code =
      state.displayCart?.appliedCoupons !== undefined &&
      state.displayCart.appliedCoupons.length > 0
        ? state.displayCart.appliedCoupons[0]?.id
        : 0;
    const response = await removeCouponsFromEGCart(
      String(code),
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      configuration.amplifyConfig.aws_appsync_apiKey
    );
    if (response.status === SUCCESS_RESPONSE_STATUS) {
      dispatch({
        type: CART_EDITION_STOP,
      });
      setCoupon("");
      setIsExpanded(false);
      dispatch({
        type: UPDATE_CART_CONTENT,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isKlarnaCheckoutSnippetLoaded()) {
      dispatch({
        type: CART_CONTENT_EDITING_LOCK,
      });
      window._klarnaCheckout((api) => {
        api.suspend({
          autoResume: {
            enabled: false,
          },
        });
      });
    }
    if (isWalley(state.paymentProvider)) {
      dispatch({
        type: CART_CONTENT_EDITING_LOCK,
      });
      window.walley?.checkout.api.suspend();
    }
    dispatch({
      type: CART_EDITION_START,
    });

    if (hasAppliedCoupon) {
      void _removeCoupon();
    } else {
      void _submitCoupon(coupon.split(";")[0]);
    }
  };

  useEffect(() => {
    const appliedCoupon =
      state.displayCart?.appliedCoupons !== undefined &&
      state.displayCart.appliedCoupons.length > 0 &&
      state.displayCart.appliedCoupons[0]?.code !== undefined
        ? state.displayCart.appliedCoupons[0].code
        : undefined;
    if (appliedCoupon !== undefined) {
      setCoupon(appliedCoupon);
      setIsExpanded(true);
    }
  }, [state.displayCart?.appliedCoupons]);

  return (
    <Wrapper isLoading={state.isCartContentLocked}>
      <HeadingButton type="button" onClick={expandForm}>
        {t("checkout.coupon.section.title")}
        <CaretIcon direction={isExpanded ? "up" : "down"} />
        <HeadingButtonAnimationWrapper
          isAnimating={isAnimating}
          onAnimationEnd={() => {
            setIsAnimating(false);
          }}
        />
      </HeadingButton>
      <AnimateHeight height={isExpanded ? "auto" : 0}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Input
            type="text"
            value={coupon}
            placeholder={t("checkout.coupon.input.text")}
            onChange={handleChange}
            hasError={isErrorMessageVisible}
          />
          {isErrorMessageVisible && <ErrorMessage>{errorText}</ErrorMessage>}
          <button
            type="submit"
            className="button button--primary button--small button--full-width"
            disabled={coupon.length <= 0}
          >
            {hasAppliedCoupon ? (
              <span>{t("checkout.coupon.remove.button")}</span>
            ) : (
              <span>{t("checkout.coupon.confirm.button")}</span>
            )}
          </button>
        </Form>
      </AnimateHeight>
    </Wrapper>
  );
};
