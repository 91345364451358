import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useRelinkValue } from "react-relink";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import type { PriceDisplayVariant } from "../../../utils/data-types";
import {
  getFrontendPriceDisplayData,
  hasUndeductedRewardDiscount,
} from "../../../utils/PriceDisplay/price-display";
import { AlternatePrice } from "../../Product/Price/SellingPrice/Alternate";
import { SellingPrice } from "../../Product/Price/SellingPrice/Selling";
import { PriceWrapper } from "../Styles/ProductItem.styled";
import type { ProductType } from "@xxl/pim-api";

type SmallProductPriceProps = {
  priceDisplay?: {
    invertedPrice?: number;
    invertedPriceFormatted?: string;
    type?: PriceDisplayVariant;
    previousPriceFormatted?: string;
    productConfiguratorPrice?: number;
    productConfiguratorPriceFormatted?: string;
    priceDisclaimer?: string;
    priceSplash?: string;
    userGroupId?: string;
    salesPrice?: number;
    salesPriceFormatted?: string;
  };
  productType: ProductType | null;
};

const InnerWrapper = styled.div(css`
  display: flex;
  gap: var(--space-regular);
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 44px;
`);

export const SmallProductPrice = ({
  priceDisplay,
  productType,
}: SmallProductPriceProps) => {
  const { t } = useTranslations();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { siteUid } = useSharedData().data;

  if (!isNotNullOrUndefined(priceDisplay)) {
    return null;
  }

  const {
    isDiscountedMainPrice,
    otherPrice,
    otherPriceDisclaimer,
    salesPrice,
    salesPriceDisclaimer,
  } = getFrontendPriceDisplayData({
    priceDisplay,
    t,
    isLoggedIn,
    siteUid,
  });
  const { invertedPrice, invertedPriceFormatted, type: variant } = priceDisplay;
  const isMultiPackProduct = productType === "MULTIPACK";
  const hasUndeductedDiscount = hasUndeductedRewardDiscount({
    invertedPrice,
    invertedPriceFormatted,
    isMultiPackProduct,
    variant,
  });

  return (
    <PriceWrapper>
      <InnerWrapper>
        <SellingPrice
          isDiscounted={isDiscountedMainPrice}
          priceFormatted={salesPrice}
          salesPriceDisclaimer={salesPriceDisclaimer}
        />
        {isNotNullOrUndefined(otherPrice) && (
          <AlternatePrice
            hasUndeductedDiscount={hasUndeductedDiscount}
            isSellingPriceDiscounted={isDiscountedMainPrice}
            priceFormatted={otherPrice}
            label={otherPriceDisclaimer}
          />
        )}
      </InnerWrapper>
    </PriceWrapper>
  );
};
