import { isNotNullOrUndefined } from "@xxl/common-utils";
import axios from "axios";
import type {
  KlarnaCheckout,
  KlarnaUserData,
  LoadDataProps,
  ShippingOptionDataProps,
} from "../../../global";
import { setStorageItem } from "../../../utils/LocalStorage/local-storage";
import * as XxlEvent from "../../../utils/xxl-event";

type AccountResponseData = {
  SK?: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  email?: string;
  acceptsEmail?: boolean;
};

type AccountData = {
  account: AccountResponseData;
};

type AccountResponse = {
  data: AccountData;
};

export const initializeCheckout = (isLoggedIn = false) => {
  const runAfterElementExists = function (
    selector: string,
    callback: () => void
  ) {
    const checker = window.setInterval(() => {
      if (
        isNotNullOrUndefined(document) &&
        document.querySelectorAll(selector).length > 0
      ) {
        //stop checking for the existence of this element
        clearInterval(checker);

        //call the passed in function via the parameter above
        callback();
      }
    }, 200);
  };

  const updateShippingOption = function (shippingOptionData: {
    price: number;
  }) {
    XxlEvent.dispatchEvent(
      XxlEvent.type.XXL_SHIPPING_UPDATE,
      shippingOptionData
    );
  };

  const getAccountEmail = async (): Promise<string | undefined> => {
    try {
      const response: AccountResponse = await axios.post(
        window._sharedData.configuration.amplifyConfig
          .aws_appsync_graphqlEndpoint,
        JSON.stringify({
          query: `
            query getAccount {
              email
            }
          `,
        }),
        {
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (isNotNullOrUndefined(response.data)) {
        const { email } = response.data.account;

        return email;
      }
      return undefined;
    } catch (error) {
      console.error("Error getting account info!", error);
      return undefined;
    }
  };

  const storeCustomerData = async function (changeData: KlarnaUserData) {
    const isKlarnaEmailEmpty =
      changeData.email === undefined || changeData.email === "";
    const email =
      isKlarnaEmailEmpty && isLoggedIn
        ? await getAccountEmail()
        : changeData.email;

    if (
      email !== undefined &&
      (isLoggedIn ||
        (window.CookieInformation?.getConsentGivenFor("cookie_cat_marketing") ??
          false))
    ) {
      setStorageItem(
        "checkout_customer",
        JSON.stringify({
          email,
          firstName: changeData.given_name,
          lastName: changeData.family_name,
          postalCode: changeData.postal_code,
          city: changeData.city,
          country: changeData.country,
        }),
        "session"
      );
    }
  };

  const addKlarnaEvents = function () {
    runAfterElementExists("#klarna-checkout-iframe", () => {
      window._klarnaCheckout((api: KlarnaCheckout) => {
        api.on({
          shipping_option_change(shippingOptionData: ShippingOptionDataProps) {
            updateShippingOption(shippingOptionData);
          },
          change(changeData: KlarnaUserData) {
            void storeCustomerData(changeData);
          },
          load(loadData: LoadDataProps) {
            void storeCustomerData(loadData.shipping_address);
            updateShippingOption(loadData.shipping_option_change);
          },
        });
      });
    });
  };

  const initialize = function () {
    if (document.querySelectorAll(".js-checkout-snippet").length < 1) {
      return;
    }
    addKlarnaEvents();
  };

  return {
    initialize,
  };
};
