import styled from "@emotion/styled/macro";
import { useMediaQuery } from "@mui/material";
import { DESKTOP_HEIGHT_WITH_HEADER_LINKS } from "react-app/src/components/Header/constants";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";

export const SectionWithHeaderOffset = styled.span`
  scroll-margin: ${() =>
      `${
        useMediaQuery(mobileMediaQuery) ? 0 : DESKTOP_HEIGHT_WITH_HEADER_LINKS
      }px`}
    0 0;
`;
