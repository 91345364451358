import type { Link, Image } from "@xxl/content-api";
import { ContentApi } from "@xxl/content-api";
import {
  getContentApiConfiguration,
  getSiteUid,
} from "../environment-variables";

const contentApi = new ContentApi(getContentApiConfiguration());

type HeaderContent = {
  campaignHubLink: string | null;
  headerLinks: Link[] | null;
  headerLogo: Image | null;
};

const getHeaderContent = async (): Promise<HeaderContent> => {
  const response = await contentApi.getHeader(getSiteUid(), "header");
  const {
    campaignHubLink,
    headerlinks: headerLinks,
    logo: headerLogo,
  } = {
    campaignHubLink: null,
    headerlinks: null,
    logo: null,
    ...response.data.result?.[0],
  };

  return {
    campaignHubLink,
    headerLinks,
    headerLogo,
  };
};

export { getHeaderContent };
export type { HeaderContent };
