import React from "react";
import * as AllIcons from "@xxl/icons";
import { color as themeColor } from "@xxl/theme";

type Color = keyof typeof themeColor;
type Props = {
  name: keyof typeof AllIcons;
  size?: number;
  color?: Color | "currentColor";
  transform?: {
    translateX: number;
    translateY: number;
  };
};

const defaultTransform = {
  translateX: 0,
  translateY: 0,
};

/**
 * Renders an icon from the @xxl/icons library with optional size, color, and transform.
 * @param {number} [size=18] - The size of the icon in pixels. defaults to `18`
 * @param {string} [color="currentColor"] - The color of the icon. Can be a named color from the `@xxl/theme` library or `currentColor`.
 * @param {Object} [transform] - An object containing the x and y translation values for the icon.
 * @param {number} [transform.translateX=0] - The x translation value in pixels.
 * @param {number} [transform.translateY=0] - The y translation value in pixels.
 * @returns {JSX.Element} - The rendered icon component.
 */
const Icon = ({
  name,
  size = 18,
  color = "currentColor",
  transform = defaultTransform,
}: Props) => {
  const Component = AllIcons[name];
  const transformStyle = `translate(${transform.translateX}px, ${transform.translateY}px)`;

  return (
    <Component
      style={{ transform: transformStyle }}
      fontSize={size}
      color={color === "currentColor" ? "currentColor" : themeColor[color].hex}
    />
  );
};

export { Icon };
