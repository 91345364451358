import type { PropsWithChildren } from "react";
import React from "react";
import { STOCK_STATUS } from "react-app/src/constants";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import {
  DrawerLi,
  DrawerUl,
  SizeContainer,
} from "./ProductSizeSelector.styled";
import type { SizeData } from "@xxl/pim-api";
import { StockStatus } from "@xxl/pim-api";
import { Drawer } from "react-app/src/components/Drawer/Drawer";
import noop from "lodash/noop";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { SizeRecommendationComponent } from "./SizeRecommendation";
import type { ProductSizesStockStatus } from "./useProductSizesStockStatus";
import { useProductSizesStockStatus } from "./useProductSizesStockStatus";
import type { TranslationKey } from "react-app/src/translations";
import { StockLevels } from "react-app/src/components/ClickAndCollect/api/api";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { getPreferredStoresCookie } from "react-app/src/utils/Cookie";
import { StockStatusComponent } from "./StoreStockComponent";
import { RemindMeComponent } from "./RemindMeComponent";
import { useStores } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect/useStores";

const getStockStatus = (
  stockStatusArray: ProductSizesStockStatus[],
  ean: string
) => {
  const sizeStatus = stockStatusArray.find((status) => status.ean === ean);

  if (sizeStatus?.onlineStockStatus !== undefined) {
    return {
      onlineStockStatus: StockLevels[sizeStatus.onlineStockStatus],
      storeStockStatus: StockLevels[sizeStatus.storeStockStatus ?? "IN_STOCK"],
    };
  }
  return {
    onlineStockStatus: StockLevels.IN_STOCK,
    storeStockStatus: StockLevels.IN_STOCK,
  };
};

type SizeSelectDrawerProps = {
  isOpen: boolean;
  isSizeGuideAvailable?: boolean;
  handleSizeOptionClick: (sizeOption: SizeData) => void;
  hasReminder: (ean: string) => boolean;
  onClose: () => void;
  onRemindMeClick: (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    sizeOption: SizeData
  ) => void;
  sizeOptions: SizeData[];
  shouldBePossibleToAddReminder?: boolean;
  shouldBePossibleToSelectOutOfStock: boolean;
  styleCode: string;
  storeId?: string;
  unit?: string;
  isClickAndCollectEnabled: boolean;
};

const SizeSelectDrawer = ({
  isOpen,
  onClose,
  sizeOptions,
  handleSizeOptionClick,
  hasReminder,
  onRemindMeClick,
  styleCode,
  shouldBePossibleToAddReminder = true,
  shouldBePossibleToSelectOutOfStock,
  unit,
  children,
  isClickAndCollectEnabled,
}: PropsWithChildren<SizeSelectDrawerProps>) => {
  const { storesData: stores } = useStores();
  const { t } = useTranslations();
  const [productId] = styleCode.split("_");

  const preferredStoresCookie = getPreferredStoresCookie();
  const preferredStoreIds = preferredStoresCookie?.ids ?? [];
  const preferredStoreId = preferredStoreIds.at(0);
  const preferredStoreName = stores.find(
    ({ id }) => id === preferredStoreId
  )?.name;

  const { productSizesStockStatus, isLoadingProductSizesStockStatus } =
    useProductSizesStockStatus(styleCode, preferredStoreId);

  const body = (
    <XxlStack spacing={"6px"} data-testid="size-selector-drawer">
      {children}
      <DrawerUl>
        {sizeOptions.map((option) => {
          const { ean, size, stockStatus } = option;
          const isOutOfStock = stockStatus === STOCK_STATUS.OUTOFSTOCK;
          if (ean === undefined) {
            throw Error("EAN is undefined.");
          }

          const storesWithStockCount = productSizesStockStatus.find(
            (i) => i.ean === ean
          )?.storesWithStockCount;

          const isDisabled =
            !shouldBePossibleToSelectOutOfStock && isOutOfStock;

          const { onlineStockStatus, storeStockStatus } = getStockStatus(
            productSizesStockStatus,
            ean
          );

          const shouldShowRemindMe =
            onlineStockStatus === StockStatus.OUTOFSTOCK ||
            stockStatus === STOCK_STATUS.OUTOFSTOCK;

          const ariaLabel = isOutOfStock
            ? `${t("product.details.storestock.out")}, ${t(
                "general.remind.me"
              )}`
            : undefined;

          return (
            <DrawerLi
              key={ean}
              disabled={isDisabled}
              onClick={isDisabled ? noop : () => handleSizeOptionClick(option)}
              data-testid={`size-${size ?? "missing"}`}
              aria-disabled={isDisabled}
              aria-label={ariaLabel}
            >
              <SizeContainer>
                {isNotNullOrUndefined(unit)
                  ? t(`product.unit.amount.${unit}` as TranslationKey)
                  : size}
              </SizeContainer>
              <XxlStack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <StockStatusComponent
                  onlineStockStatus={onlineStockStatus}
                  storeStockStatus={storeStockStatus}
                  isLoading={isLoadingProductSizesStockStatus}
                  preferredStoreName={preferredStoreName}
                  preferredStoreIds={preferredStoreIds}
                  storesWithStockCount={storesWithStockCount}
                  isClickAndCollectEnabled={isClickAndCollectEnabled}
                  generalStockStatus={stockStatus ?? "INSTOCK"}
                >
                  <RemindMeComponent
                    shouldShowRemindMe={shouldShowRemindMe}
                    hasReminderBoolean={hasReminder(ean)}
                    onClick={(
                      event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                    ) => onRemindMeClick(event, option)}
                    shouldBePossibleToAddReminder={
                      shouldBePossibleToAddReminder
                    }
                  />
                </StockStatusComponent>
              </XxlStack>
            </DrawerLi>
          );
        })}
      </DrawerUl>
    </XxlStack>
  );

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      isLoading={false}
      heading={t("product.details.select.size")}
      customPadding={`${xxlTheme.spaces.smallRegular}`}
    >
      <SizeRecommendationComponent productId={productId} />
      {body}
    </Drawer>
  );
};

export { SizeSelectDrawer };
