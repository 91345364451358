export const NR_OF_PRODUCTS_PER_PAGE = 36;

const URL_PARAMETER_NAMES = {
  availability: "availability",
  facet: "f.",
  page: "pages",
  query: "query",
  selectedStores: "selectedStores",
  sort: "sort",
  tab: "tab",
} as const;

export type UrlParameterName =
  (typeof URL_PARAMETER_NAMES)[keyof typeof URL_PARAMETER_NAMES];

export const URL_PARAMETERS = {
  availability: {
    name: URL_PARAMETER_NAMES.availability,
    default: "",
  },
  facet: {
    name: URL_PARAMETER_NAMES.facet,
    default: "",
  },
  page: {
    name: URL_PARAMETER_NAMES.page,
    default: "1",
  },
  query: {
    name: URL_PARAMETER_NAMES.query,
    default: "",
  },
  sort: {
    name: URL_PARAMETER_NAMES.sort,
    default: "", // API will respond with default value here
  },
  tab: {
    name: URL_PARAMETER_NAMES.tab,
    default: "",
  },
  selectedStores: {
    name: URL_PARAMETER_NAMES.selectedStores,
    default: "",
  },
} as const;
