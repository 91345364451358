import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

export const DialogBoxCloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 15px;
  height: 16px;
  cursor: pointer;

  ${MQ("tablet")} {
    top: 25px;
    right: 25px;
  }
`;

export const HeaderContainer = styled.div<{ hideShadow?: boolean }>(
  ({ hideShadow = false }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${colors.xxlWebBlack};
    padding: ${spaces.large};
    box-shadow: ${hideShadow
      ? `none`
      : `0 5px 5px -5px ${xxlTheme.colors.xxlMediumGrey}`};

    padding: ${spaces.large} 0 ${spaces.large} ${spaces.smallRegular};

    ${MQ("tablet")} {
      padding: ${spaces.large} ${spaces.mini} ${spaces.large} ${spaces.large};
    }
  `
);

type DialogBoxContentProps = {
  hasPadding?: boolean;
  isFullHeight?: boolean;
};

const dialogBoxPadding = spaces.smallRegular;

export const DialogBoxContent = styled.div<DialogBoxContentProps>(
  ({ hasPadding = false, isFullHeight = false }) => {
    const paddingCss = hasPadding
      ? css`
          padding: 0 ${spaces.smallRegular} ${spaces.smallRegular};

          ${MQ("tablet")} {
            padding: 0 ${spaces.large} ${spaces.large};
          }
        `
      : css`
          padding: 0;
        `;

    const displayCss = isFullHeight
      ? css`
          display: flex;
        `
      : css`
          display: block;
        `;

    return css`
      flex-grow: 1;
      ${displayCss};
      ${paddingCss};
    `;
  }
);

export const DialogBoxHeaderWrapper = styled.div`
  display: flex;
  align-items: start;
  h3 {
    flex-grow: 1;
  }
`;

export const DialogBoxHeaderCloseButton = styled(DialogBoxCloseButton)`
  position: static;
`;

export { dialogBoxPadding };
