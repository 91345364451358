import {
  type BreakpointProps,
  BREAKPOINTS,
} from "react-app/src/hooks/useBreakpoint/useBreakpoint";

type SliderStyle = {
  perView: number;
  spacing: number;
};

export const prepareSliderStyles = (
  breakpoint: BreakpointProps
): SliderStyle => {
  switch (breakpoint) {
    case BREAKPOINTS.mobile:
      return {
        perView: 6,
        spacing: 5,
      };
    case BREAKPOINTS.tablet:
      return {
        perView: 10,
        spacing: 10,
      };
    case BREAKPOINTS.laptop:
      return {
        perView: 6,
        spacing: 10,
      };
    case BREAKPOINTS.desktop:
      return {
        perView: 8,
        spacing: 10,
      };
    default:
      return {
        perView: 10,
        spacing: 10,
      };
  }
};
