import { CATEGORY_ROUTE_PREFIX } from "./Constants";
import type { LongTailData } from "@xxl/frontend-api";

import type {
  DistinctFacetParameter,
  Filter,
  RangeFacetParameter,
} from "../../utils/data-types";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const isRangeFacetParameter = (
  facet: DistinctFacetParameter | RangeFacetParameter
): facet is RangeFacetParameter => facet.type === "range";
export const isDistinctFacetParameter = (
  facet: DistinctFacetParameter | RangeFacetParameter
): facet is DistinctFacetParameter => facet.type === "distinct";

export const CATEGORY_LONG_TAIL_PATTERN = "/(?:[^/]*/)*?(?:([^/]+)/)?";

export const addLongTailPatternToCategoryUrl = (url: string): string =>
  url.replace(CATEGORY_ROUTE_PREFIX, `/${CATEGORY_LONG_TAIL_PATTERN}c/`);

export class UrlPath {
  PREFIX_SEPARATOR = "-";
  FACETS_SEPARATOR = "--";
  VALUES_SEPARATOR = ":";
  MAX_ALLOWED_VALUES_FOR_INDEX = 1;
  url: URL;
  selectedFilters: Filter[];
  longTailFacets?: LongTailData[];
  longTailPattern?: string;

  constructor(
    url: URL,
    selectedFilters: Filter[],
    longTailFacets?: LongTailData[],
    longTailPattern?: string
  ) {
    this.url = url;
    this.selectedFilters = selectedFilters;
    this.longTailFacets = longTailFacets;
    this.longTailPattern = longTailPattern;
  }

  updateFilters(): URL {
    this.updateLongTailFilters();
    this.updateQueryParameterFilters();
    return this.url;
  }

  private appendParams = (name: string, value: string) => {
    this.url.searchParams.append(name, value);
  };

  private updateQueryParameterFilters(): void {
    const longTailFacetIds: string[] =
      this.longTailFacets?.map((facet) => facet.id) ?? [];
    this.selectedFilters
      .filter(
        (selectedFilter) =>
          !longTailFacetIds.includes(selectedFilter.attributeName)
      )
      .forEach((selectedFilter) => {
        if (isDistinctFacetParameter(selectedFilter)) {
          selectedFilter.selected?.forEach((selectedValue) => {
            this.appendParams(
              selectedFilter.attributeName,
              selectedValue.toString()
            );
          });
        }

        if (isRangeFacetParameter(selectedFilter)) {
          this.appendParams(
            selectedFilter.attributeName,
            JSON.stringify(selectedFilter.selected)
          );
        }
      });
  }

  private updateLongTailFilters(): void {
    this.url.pathname = this.getUpdatedLongTailPath();
  }

  getUpdatedLongTailPath(): string {
    if (this.longTailPattern !== null && this.longTailPattern !== undefined) {
      return this.longTailPattern.replace(
        CATEGORY_LONG_TAIL_PATTERN,
        this.getLongTailPortion()
      );
    }
    return this.url.pathname;
  }

  shouldFacetBeIndexed(facet: string): boolean {
    const isLongTailFacet =
      this.longTailFacets?.some(({ id }) => facet === id) ?? false;
    const hasMoreThanOneSelectedLongTailFilter = this.longTailFacets?.some(
      (longTail) => {
        return this.selectedFilters.some((selectedFilter) => {
          return (
            longTail.id === selectedFilter.attributeName &&
            (selectedFilter.selected as string[]).length > 1
          );
        });
      }
    );
    return Boolean(
      isLongTailFacet && hasMoreThanOneSelectedLongTailFilter !== true
    );
  }

  getLongTailPortion(): string {
    const longTailFacetSorting = function (
      element1: LongTailData,
      element2: LongTailData
    ): number {
      return element1.prio > element2.prio ? 1 : -1;
    };
    if (this.longTailFacets === undefined) {
      return "";
    }
    let longTailResult: string = this.longTailFacets
      .sort(longTailFacetSorting)
      .map((facet) =>
        this.selectedFilters.find(
          (selectedFilter) => selectedFilter.attributeName === facet.id
        )
      )
      .map((selectedFilter) => {
        if (
          selectedFilter === undefined ||
          !isDistinctFacetParameter(selectedFilter)
        ) {
          return "";
        }

        return this.getLongTail(selectedFilter);
      })
      .join(this.FACETS_SEPARATOR);
    this.longTailFacets.forEach(() => {
      if (longTailResult.endsWith(this.FACETS_SEPARATOR)) {
        longTailResult = longTailResult.slice(0, -2);
      }
    });
    return longTailResult === "" ? longTailResult : longTailResult + "/";
  }

  getLongTail(selectedFilter: DistinctFacetParameter): string {
    return selectedFilter.selected?.length === 0
      ? ""
      : `${this.getLongTailPrefix(selectedFilter)}${this.getLongTailValues(
          selectedFilter
        ).join(this.VALUES_SEPARATOR)}`;
  }

  private getLongTailValues(selectedFilter: Filter): string[] {
    const longTailFacetIds: string[] =
      this.longTailFacets?.map((facet) => facet.id) ?? [];

    if (
      isNotNullOrUndefined(selectedFilter.attributeName) &&
      longTailFacetIds.includes(selectedFilter.attributeName)
    ) {
      return selectedFilter.selected as string[];
    }
    return [];
  }

  getLongTailPrefix(selectedFilter: Filter): string {
    const prefix: LongTailData | undefined = this.longTailFacets?.find(
      (facet) => selectedFilter.attributeName === facet.id
    );
    if (prefix?.prefix) {
      return prefix.name + this.PREFIX_SEPARATOR;
    }
    return "";
  }
}
