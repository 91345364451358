import type { CampaignPageData } from "@xxl/content-api";
import { ContentModuleTypeEnum } from "@xxl/content-api";

const getCampaignData = ({
  campaignDescription,
  contentModules = [],
  pageTitle = "",
}: CampaignPageData) => {
  const heroWithCountdown = contentModules
    .filter(({ _type }) => _type === ContentModuleTypeEnum.heroComponent)
    .find(({ countdownTimerSettings }) => countdownTimerSettings !== undefined);

  return {
    description: campaignDescription ?? null,
    title: pageTitle,
    timer: heroWithCountdown?.countdownTimerSettings ?? null,
  };
};

export { getCampaignData };
