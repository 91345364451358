import { Minus, Plus, Trash } from "@xxl/icons";
import { default as React, useEffect, useState } from "react";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { getFrontendPriceDisplayData } from "../../../utils/PriceDisplay/price-display";
import { handleNumKeyDown } from "../../../utils/xxl-input";
import {
  PurchaseDetailsStretchedWithMargin,
  PurchaseDetailsWithMargin,
} from "../../Services/ServiceProduct.styled";
import { ServiceProductDumb } from "../../Services/ServiceProductDumb";
import type { CartItem } from "../Api/types";
import {
  SERVICES_DECREASE_QUANTITY,
  SERVICES_INCREASE_QUANTITY,
  SERVICES_REMOVED,
  useServicesContext,
} from "../CartServiceProductsState";
import { useCartContext } from "../CartState";
import {
  _updateCartQuantity,
  parseQuantity,
} from "../Services/updateCartQuantity";
import {
  QuantityButton,
  QuantityDisplay,
  QuantityWrapper,
  RemoveButton,
} from "../Styles/ProductItem.styled";
import { useRelinkValue } from "react-relink";
import { AddServiceToCartButton } from "./AddServiceToCartButton";
import { useTracking } from "../../../contexts/Tracking";
import { windowAccess } from "../../../utils/Window";
import { usePaymentProvider } from "../../../hooks/usePaymentProvider/usePaymentProvider";
import { PaymentType } from "../Api/CartAPI";

type CartServiceProductProps = {
  item: CartItem;
  category?: string;
  actionInProgress: boolean;
  handleChange: (value: boolean) => void;
  isRecommended: boolean;
};

const MISSING_ENTRY_NUMBER = -1;

export const CartServiceProduct: React.FunctionComponent<
  CartServiceProductProps
> = ({ item, category, handleChange, actionInProgress, isRecommended }) => {
  const { t } = useTranslations();
  const [isMaxServicesQuantity, setIsMaxServicesQuantity] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [entryNumber, setEntryNumber] = useState(item.entryNumber);
  const [entryType, setEntryType] = useState(item.type);
  const serviceDetailsRef = React.useRef<HTMLLIElement>(null);
  const { state, dispatch } = useServicesContext();
  const { state: stateCart, dispatch: dispatchCart } = useCartContext();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { configuration, siteUid } = useSharedData().data;
  const { sendAddServiceToCartEvent, sendRemoveServiceOnCartEvent } =
    useTracking();
  const productListName =
    windowAccess()._sharedData.gtmData.productListName ?? "";
  const [graphQLEndpointUrl] = React.useState(
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint
  );
  const [graphQLApiKey] = React.useState(
    configuration.amplifyConfig.aws_appsync_apiKey
  );
  const defaultPaymentProvider = usePaymentProvider(PaymentType.CHECKOUT);

  const [quantityInputValue, setQuantityInputValue] = useState<string>();
  const quantityInputRef = React.useRef<HTMLInputElement>(null);

  const { description, title: name, displayPrice, brand, productCode } = item;

  if (displayPrice === undefined) {
    throw Error("Missing display price cart2");
  }
  const transformedPrice = getFrontendPriceDisplayData({
    priceDisplay: displayPrice,
    t,
    isLoggedIn,
    siteUid,
  });
  const { hasMiniCart } = stateCart;

  const handleTrackingAddToCart = (): void => {
    sendAddServiceToCartEvent({
      product: {
        name: name ?? "",
        id: productCode,
        price: transformedPrice.salesPrice,
        priceType: transformedPrice.otherPriceType as string,
      },
      list: productListName,
    });
  };

  const handleTrackingRemoveFromCart = (): void => {
    if (serviceDetailsRef.current === null) {
      return;
    }
    sendRemoveServiceOnCartEvent({
      product: { name: name ?? "", id: productCode },
      list: "lightbox",
    });
  };

  useEffect(() => {
    if (state.cartServices !== undefined && state.cartServices.length > 0) {
      const service = state.cartServices.find(
        (serviceItem) =>
          serviceItem.productCode === item.productCode ||
          (serviceItem.ean !== undefined &&
            item.ean !== undefined &&
            serviceItem.ean[0] === item.ean[0])
      );
      setEntryNumber(service?.entryNumber ?? MISSING_ENTRY_NUMBER);
      setEntryType(service?.type ?? "SERVICE");
      setQuantity(service?.quantity ?? 0);
      setQuantityInputValue(
        service?.quantity !== undefined ? service.quantity.toString() : "0"
      );
    } else {
      setQuantity(0);
    }
  }, [state.cartServices, item.productCode, item.ean]);

  useEffect(() => {
    setQuantityInputValue(quantity.toString());
  }, [quantity]);

  useEffect(() => {
    setIsMaxServicesQuantity(
      !((state.servicesQuantity ?? 0) < (state.productQuantity ?? 0))
    );
  }, [state.productQuantity, state.servicesQuantity]);

  const handleDecreaseServiceQuantity = () => {
    if (state.cartServices !== undefined && state.cartServices.length > 0) {
      const newQuantity = quantity - 1;
      handleChange(true);

      void _updateCartQuantity({
        quantity: newQuantity,
        entryNumber,
        entryType,
        dispatch: dispatchCart,
        hasMiniCart,
        hasErrors: false,
        isServicesModalOpened: false,
        graphQLEndpointUrl,
        graphQLApiKey,
        deliveryPointOfServiceName: item.selectedStore?.name,
        paymentProvider: stateCart.paymentProvider ?? defaultPaymentProvider,
      });
      dispatch({
        type: SERVICES_DECREASE_QUANTITY,
      });
      setQuantity(newQuantity);
      handleChange(false);
      handleTrackingRemoveFromCart();
    }
  };

  const handleRemoveService = async () => {
    handleChange(true);
    await _updateCartQuantity({
      quantity: 0,
      entryNumber,
      entryType,
      dispatch: dispatchCart,
      hasMiniCart,
      hasErrors: false,
      isServicesModalOpened: true,
      graphQLEndpointUrl,
      graphQLApiKey,
      deliveryPointOfServiceName: item.selectedStore?.name,
      paymentProvider: stateCart.paymentProvider ?? defaultPaymentProvider,
    });
    dispatch({
      type: SERVICES_REMOVED,
    });
    dispatch({
      type: SERVICES_DECREASE_QUANTITY,
    });
    setQuantity(0);
    setIsMaxServicesQuantity(false);
    handleChange(false);
    handleTrackingRemoveFromCart();
  };

  const handleIncreaseServiceQuantity = () => {
    if (state.cartServices?.length === undefined || actionInProgress) {
      return;
    }

    handleChange(true);
    void _updateCartQuantity({
      quantity: quantity + 1,
      entryNumber,
      entryType,
      dispatch: dispatchCart,
      hasMiniCart,
      hasErrors: false,
      isServicesModalOpened: false,
      graphQLEndpointUrl,
      graphQLApiKey,
      deliveryPointOfServiceName: item.selectedStore?.name,
      paymentProvider: stateCart.paymentProvider ?? defaultPaymentProvider,
    });
    dispatch({
      type: SERVICES_INCREASE_QUANTITY,
    });
    setQuantity(quantity + 1);
    handleChange(false);
    handleTrackingAddToCart();
  };

  const changeQuantity = (quantityNumber: number) => {
    if (quantityNumber < 0) {
      return;
    }

    handleChange(true);
    void _updateCartQuantity({
      quantity: quantityNumber,
      entryNumber: item.entryNumber,
      entryType,
      dispatch: dispatchCart,
      hasMiniCart,
      hasErrors: false,
      isServicesModalOpened: false,
      graphQLEndpointUrl:
        configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
      graphQLApiKey: configuration.amplifyConfig.aws_appsync_apiKey,
      deliveryPointOfServiceName: item.selectedStore?.name,
      paymentProvider: stateCart.paymentProvider ?? defaultPaymentProvider,
    });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuantityInputValue(e.target.value);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantityNumber = parseQuantity(e.target.value);
    setQuantityInputValue(quantityNumber.toString());
    changeQuantity(quantityNumber);
  };

  return (
    <ServiceProductDumb
      brand={brand}
      category={category}
      code={productCode}
      description={description}
      isProductPage={false}
      isRecommended={isRecommended}
      isSelected={quantity > 0}
      name={name}
      price={displayPrice}
      serviceDetailsRef={serviceDetailsRef}
      productType={undefined}
      PurchaseDetails={
        quantity > 0 ? (
          <PurchaseDetailsStretchedWithMargin>
            <RemoveButton
              type="button"
              onClick={() => {
                void handleRemoveService();
              }}
            >
              <Trash />
            </RemoveButton>
            <QuantityWrapper>
              <QuantityButton
                type="button"
                data-testid="decrease-quantity-button"
                disabled={quantity <= 1 || actionInProgress}
                onClick={handleDecreaseServiceQuantity}
              >
                <Minus />
              </QuantityButton>
              <QuantityDisplay
                value={quantityInputValue}
                onChange={handleQuantityChange}
                onKeyDown={(e) => handleNumKeyDown(e, quantityInputRef)}
                onBlur={handleBlur}
                disabled={isMaxServicesQuantity || actionInProgress}
                data-testid="quantity"
                type="number"
              />
              <QuantityButton
                type="button"
                data-testid="increase-quantity-button"
                disabled={isMaxServicesQuantity || actionInProgress}
                onClick={handleIncreaseServiceQuantity}
              >
                <Plus />
              </QuantityButton>
            </QuantityWrapper>
          </PurchaseDetailsStretchedWithMargin>
        ) : (
          <PurchaseDetailsWithMargin>
            <AddServiceToCartButton
              item={item}
              actionInProgress={actionInProgress}
              disabled={isMaxServicesQuantity}
              onClick={setQuantity}
              onChange={handleChange}
              onAddToCart={handleTrackingAddToCart}
            />
          </PurchaseDetailsWithMargin>
        )
      }
    />
  );
};
