import styled from "@emotion/styled/macro";
import { Button } from "../../../../src/styled";
import { borderCss } from "../../../styled/Common.styled";
import spaces from "../../../styles/theme/spaces";
import { color } from "@xxl/theme";
import { MQ } from "../../../styles/helpers";

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.smallRegular};
  min-height: 100dvh;
  overflow-y: auto;

  ${MQ("tablet")} {
    overflow-y: auto;
    padding: ${spaces.large};
    padding-top: ${spaces.smallRegular};
    height: 790px;
    width: 960px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spaces.large};
`;

export const StoreStockDialogBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spaces.large};
  row-gap: ${spaces.large};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StoreInput = styled.input`
  margin-top: ${spaces.smallRegular};
  padding: ${spaces.regular};
  border: 1px solid ${color.mediumGray.hex};
  :focus-visible {
    outline-color: ${color.green.hex};
    outline-offset: ${spaces.micro};
  }
`;

export const StoreSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SaveButton = styled(Button)`
  &.button {
    padding: ${spaces.large} ${spaces.smallHuge};
    width: auto;
    margin-left: auto;
  }
`;

export const SaveWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${color.white.hex};
  border-top: ${borderCss};
`;

export const Footer = styled.div`
  padding: ${spaces.smallRegular};
  background: ${color.white.hex};
  box-shadow: 0 -5px 5px -5px ${color.mediumGray.hex};
  position: sticky;
  bottom: 0;
  ${MQ("tablet")} {
    display: flex;
    justify-content: end;
    padding: ${spaces.smallRegular} ${spaces.large};
  }
`;
