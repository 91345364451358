import type { FormError } from "@xxl/customers-api";
import { createContext, useContext } from "react";
import type {
  ElevateData,
  ProductTrackingData,
  TrackAddToCartProps,
  TrackCheckoutProp,
  TrackExchangeOrderEvent,
  TrackFavoriteProductProps,
  TrackFavoritesConfirmationClickProps,
  TrackFavoritesSharedLinkGetProps,
  TrackFilterSelectProps,
  TrackMultipackBundleListProps,
  TrackOrderConfirmationData,
  TrackProductClickProps,
  TrackProductImpressionsProps,
  TrackProductListProps,
  TrackProductViewProps,
  TrackSortChangeProps,
} from "../../utils/Tracking";

export type Trackers = {
  sendAddToCartEvent(props: TrackAddToCartProps): void;
  sendAddMultipackBundleEvent(props: TrackMultipackBundleListProps): void;
  sendAddServiceToCartEvent(prop: TrackProductListProps): void;
  sendAddToFavoritesEvent(props: TrackFavoriteProductProps): void;
  sendClickEvent(props: ElevateData): void;
  sendRemoveFromFavoritesEvent(props: TrackFavoriteProductProps): void;
  sendFavoritesConfirmationClickEvent(
    props: TrackFavoritesConfirmationClickProps
  ): void;
  sendSharedFavoritesLinkGetEvent(
    props: TrackFavoritesSharedLinkGetProps
  ): void;
  sendProductImpressionsEvent(props: TrackProductImpressionsProps): void;
  sendCrossSalesCloseEvent(): void;
  sendCrossSalesCheckoutEvent(): void;
  sendAddOnContinueShoppingEvent(): void;
  sendAddOnEditCartEvent(): void;
  sendAddOnCloseAccessoryCartEvent(): void;
  sendClickGoToCheckoutEvent(): void;
  sendCloseMiniCartEvent(): void;
  sendOpenMiniCartEvent(): void;
  sendOrderConfirmationEvent(
    orderConfirmationData: TrackOrderConfirmationData,
    shouldSendElevateEvent?: boolean
  ): void;
  sendReadMoreServicePDPClickEvent(label: string): void;
  sendReadMoreServiceCartClickEvent(label: string): void;
  sendOpenServicesPDPEvent(): void;
  sendProductClickInCartEvent({ product, list }: TrackProductListProps): void;
  sendRemoveFromCartEvent(product: ProductTrackingData): void;
  sendRemoveServiceOnCartEvent(props: TrackProductListProps): void;
  sendRemoveServiceOnPDPEvent(product: ProductTrackingData): void;
  sendSignupSuccessEvent(): void;
  sendSignupErrorEvent(errors?: FormError[]): void;
  sendSelectFilterEvent(props: TrackFilterSelectProps): void;
  sendSearchPageViewEvent(side: string): void;
  sendCampaignPageViewEvent(side: string): void;
  sendSortChangeEvent(props: TrackSortChangeProps): void;
  sendProductClickEvent(props: TrackProductClickProps): void;
  sendCheckoutEvent(props: TrackCheckoutProp): void;
  sendProductViewEvent(props: TrackProductViewProps): void;
  sendExchangeOrderEvent(props: TrackExchangeOrderEvent): void;
};

export const TrackingContext = createContext<Trackers | undefined>(undefined);

export const useTracking = () => {
  const context = useContext(TrackingContext);

  if (context === undefined) {
    throw Error("useTracking must be used within its provider.");
  }

  return context;
};
