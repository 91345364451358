/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { isNotNullOrUndefined } from "@xxl/common-utils";

type FilespinColor = string; // 000000-ffffff or css color name
type FilespinBgColor = string; // 000000-ffffff
type FilespinQuality = number; // 0-100
type FilespinFormat = "jpg" | "png" | "webp" | "gif";
type FilespinTolerance = number; // 0-442
type FilespinAngle = 90 | 180 | 270;
type FilespinFlip = "h" | "v";
type FilespinRoundcornerColor = number; // 0-255, default: 255
type FilespinResize = {
  width: number;
  height: number;
  fill?: FilespinColor;
};
type FilespinCropWithSize = {
  left: number;
  top: number;
  width: number;
  height: number;
};
type FilespinCropWithFeature = {
  width: number;
  height: number;
  feature: string;
};
type FilespinCrop = FilespinCropWithSize | FilespinCropWithFeature;

type FilespinRoundcorner =
  | true
  | {
      radius?: number; // default: 20
    }
  | {
      radius: number; // default: 20
      r: FilespinRoundcornerColor;
      g: FilespinRoundcornerColor;
      b: FilespinRoundcornerColor;
    };
type FileSpinEffects = {
  grayscale: boolean;
  blur: number | boolean; // 0-150, default: 20
  roundcorner: FilespinRoundcorner;
  chalk: boolean;
};

export type FilespinOptions = {
  resize: FilespinResize;
  crop: FilespinCrop;
  bgcolor: FilespinBgColor;
  quality: FilespinQuality;
  format: FilespinFormat;
  trim: FilespinTolerance;
  rotate: FilespinAngle;
  flip: FilespinFlip;
  effects: Partial<FileSpinEffects>;
};

type GetFilespinImageUrlProps = {
  imageBaseUrl: string;
  options?: Partial<FilespinOptions>;
};

/**
 * Get Filespin image url
 * @param {string} imageBaseUrl - image base url
 * @param {Partial<FilespinOptions>} options - Filespin options
 * @returns {string} - Filespin image url
 */
export const getFilespinImageUrl = ({
  imageBaseUrl,
  options,
}: GetFilespinImageUrlProps): string => {
  if (!isNotNullOrUndefined(options)) {
    return imageBaseUrl;
  }

  const urlParams = Object.entries(options)
    .map(([optionName, optionValue]) => {
      const transformedOptionValue =
        typeof optionValue === "object"
          ? Object.entries(optionValue)
              .map(([subOptionName, subOptionValue]) => {
                switch (subOptionName) {
                  case "fill":
                  case "roundcorner":
                  case "blur":
                    return subOptionValue === true
                      ? `${subOptionName}`
                      : `${subOptionName}:${
                          typeof subOptionValue === "object"
                            ? Object.entries(subOptionValue)
                                .map(([, subSubOptionValue]) => {
                                  return `${subSubOptionValue}`;
                                })
                                .join(":")
                            : subOptionValue
                        }`;
                  case "grayscale":
                  case "chalk":
                    return `${subOptionName}`;
                  default:
                    return subOptionValue;
                }
              })
              .join(",")
          : optionValue;
      return `${optionName}=${transformedOptionValue}`;
    })
    .join("&");

  const urlWithParams = `${imageBaseUrl}?${urlParams}`;
  return urlWithParams;
};
