import chunk from "lodash/chunk";
import type { OptionalPriceDisplaysProps, PriceDisplaysProps } from "./types";
import type { PricesApi } from "@xxl/frontend-api";

const PRODUCT_CODE_ATTRIBUTE_NAME = "productStyle";

enum BannerSize {
  XS = "xs",
  SMALL = "small",
  MEDIUM = "medium",
}

const getProductCodes = (productPricesElements: HTMLDivElement[]): string[] =>
  productPricesElements
    .map(({ dataset }) => dataset[PRODUCT_CODE_ATTRIBUTE_NAME] ?? "")
    .filter((productCode) => productCode);

const mergePriceDisplayObjects = (
  previous: PriceDisplaysProps,
  current: PriceDisplaysProps
) => ({ ...previous, ...current });

/**
 * Fetches price display data for given array of product codes
 * Product codes are divided into chunks with separate API requests for better performance
 */
const fetchPriceDisplays = async (
  productCodes: string[],
  pricesApi: PricesApi
): Promise<OptionalPriceDisplaysProps> => {
  const maxAllowedProductCodesPerRequest = 40; //HARDCODED because we want to use it for Next.js as well, but we still have dependency to Spring
  const uniqueListOfProductCodes = [...new Set(productCodes)];
  const chunksOfProductCodes = chunk(
    uniqueListOfProductCodes,
    maxAllowedProductCodesPerRequest
  );
  return (
    await Promise.all(
      chunksOfProductCodes.map((chunk) =>
        pricesApi.pricesControllerGetPricesJSONPOST(chunk)
      )
    )
  )
    .map(({ data }) => data)
    .reduce(mergePriceDisplayObjects, {});
};

export {
  fetchPriceDisplays,
  getProductCodes,
  PRODUCT_CODE_ATTRIBUTE_NAME,
  BannerSize,
};
