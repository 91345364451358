import { MISSING_PRODUCT_IMAGE_URL } from "../../constants";
import { AlternatePrice } from "../Product/Price/SellingPrice/Alternate";
import { SellingPrice } from "../Product/Price/SellingPrice/Selling";
import { getFileSpinUrl } from "../Product/ProductImage/product-image-helper";
import { StarsDisplay } from "../RatingsAndReviews/AverageRating/StarsDisplay";
import { BoldedSpan } from "./BoldedSpan";
import {
  Image,
  PriceDetails,
  ProductBrand,
  ProductDetails,
  ProductInfo,
  ProductLink,
  ProductName,
  ProductWrapper,
} from "./Product.styled";

export type ProductProps = {
  onClick: (ticket?: string) => void;
  product: {
    price: {
      selling: {
        isDiscounted: boolean;
        price: string;
        label?: string;
      };
      alternate?: {
        hasUndeductedDiscount: boolean;
        price: string;
        label?: string;
      };
    };
    averageRating?: number;
    brand?: { name: string };
    name?: string;
    url?: string;
    primaryImage?: string;
    ticket?: string;
  };
  foundPhrase?: string;
};

export const Product = ({ onClick, product, foundPhrase }: ProductProps) => {
  const { ticket } = product;
  const imageUrl =
    product.primaryImage !== undefined
      ? getFileSpinUrl({ baseUrl: product.primaryImage, size: 88 })
      : `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
  const {
    price: { alternate, selling },
  } = product;

  return (
    <ProductWrapper>
      <ProductLink href={product.url} onClick={() => onClick(ticket)}>
        <ProductDetails>
          <Image
            src={imageUrl}
            alt={product.name}
            onError={(event: React.InvalidEvent<HTMLImageElement>) => {
              event.target.src = `${window.location.origin}${MISSING_PRODUCT_IMAGE_URL}`;
            }}
          />
          <ProductInfo>
            <ProductBrand className="auto-suggest__brand">
              <BoldedSpan
                text={product.brand?.name ?? ""}
                foundPhrase={foundPhrase}
              />
            </ProductBrand>
            <ProductName className="auto-suggest__name">
              <BoldedSpan
                text={product.name as string}
                foundPhrase={foundPhrase}
              />
            </ProductName>
            {product.averageRating !== undefined && (
              <StarsDisplay
                rating={product.averageRating}
                size="xxs"
                gap={2}
                useBottomMargin={false}
              />
            )}
          </ProductInfo>
        </ProductDetails>
        <PriceDetails>
          <SellingPrice
            isDiscounted={selling.isDiscounted}
            priceFormatted={selling.price}
            salesPriceDisclaimer={selling.label}
          />
          {alternate !== undefined && (
            <AlternatePrice
              hasUndeductedDiscount={alternate.hasUndeductedDiscount}
              isSellingPriceDiscounted={selling.isDiscounted}
              priceFormatted={alternate.price}
              label={alternate.label}
            />
          )}
        </PriceDetails>
      </ProductLink>
    </ProductWrapper>
  );
};
