import type { ProductData } from "@xxl/recommendations-api";
import type { CategoryData } from "@xxl/search-api";
import type {
  DeleteSubscriptionStatusMutation,
  Gender,
  GetAccountQuery,
  GetRecommendedScanProductsQuery,
  GetRecommendedScanProductsQueryVariables,
  GetRewardsQuery,
  GetVoyadoContactInput,
  InitiateOTPChallengeInput,
  InitiateOTPChallengeMutation,
  PendingBonusPointsQuery,
  PutSubscriptionStatusMutation,
  RespondToOTPChallengeInput,
  RespondToOTPChallengeMutation,
  SubscriptionStatusInput,
  UpdateAccountInput,
  UpdateConsentInput,
  UpdateConsentMutation,
  UpdateConsentMutationVariables,
  UpdateContactInput,
  UpdateContactMutation,
  UpdateContactMutationVariables,
  UpdateEmailInput,
  UpdateSocialSecurityNumberInput,
  UpdateSocialSecurityNumberMutation,
  UpdateSocialSecurityNumberMutationVariables,
  UserProductReviewsInput,
  createAccountMutation,
  createAccountMutationVariables,
  getVoyadoContactQuery,
  requestMembershipMutation,
  requestMembershipMutationVariables,
} from "../../generated/graphql-code-generator";
import { callGraphQL, type GraphQLResult } from "../../graphql/graphqlApi";
import type { PersonalInfoData } from "./PersonalInfoModal/PersonalInfoModalHelper";

export type FitstationScan = {
  scanId: string;
  storeName: string;
  scanPersonName: string | null;
  scanTime: number;
};

export type InitiateOTPData = {
  session: string;
  timestamp?: number;
  expires?: number;
};

export type InitiateOTPChallengeReponse = {
  initiateOTPChallenge: InitiateOTPData;
};

export type GetAccountProps = NonNullable<GetAccountQuery["account"]>;
export type RewardsProps = Pick<GetAccountProps, "rewards">["rewards"];
export type MemberRewardsProps = Pick<
  NonNullable<RewardsProps>,
  "memberRewards"
>["memberRewards"];
export type FavoritesType = { styleId: string }[] | null;
export interface UserAccountProps {
  consents?: { value: boolean }[] | null;
  acceptsEmail?: boolean;
  acceptsSms?: boolean;
  email?: string;
  favorites?: FavoritesType;
  firstName?: string;
  fitstationScans: FitstationScan[] | null;
  lastName?: string;
  mobilePhone?: string;
  SK?: string;
  memberNumber?: string | null;
  rewards?: RewardsProps | null;
  socialSecurityNumber?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  gender?: Gender;
  birthDay?: string;
  returnFee?: {
    numberOfFreeReturns?: number;
    returnFeeApplied?: boolean;
    returnRate?: number;
  };
}

export interface PendingPointsProps {
  value?: number;
}

export interface AccountResponse {
  account: UserAccountProps | null;
}

export interface PendingPointsResponse {
  pendingBonusPoints: PendingBonusPointsQuery["pendingBonusPoints"];
}

export interface UpdateAccountResponse {
  updateContact: UpdateContactMutation["updateContact"];
}

export type RecommendedScanProductsInput = {
  categoryCode?: string | null;
  scanId: string;
  userGroupIds?: string | null;
};

export type VoyadoContact = NonNullable<
  getVoyadoContactQuery["getVoyadoContact"]
>;

export type InitiateOPTChallengeResponseData =
  InitiateOTPChallengeMutation["initiateOTPChallenge"];

export const accountQuery = /* GraphQL */ `
  query GetAccount {
    account {
      SK
      email
      firstName
      lastName
      mobilePhone
      acceptsEmail
      acceptsSms
      memberNumber
      socialSecurityNumber
      street
      city
      zipCode
      gender
      birthDay
      consents {
        value
      }
      fitstationScans {
        scanId
        storeName
        scanPersonName
        scanTime
      }
      returnFee {
        numberOfFreeReturns
        returnFeeApplied
        returnRate
      }
      rewards {
        profileCompleteness {
          bonusPoints
          completeProfileAttributes
          percentageComplete
          totalRemainingBonusPoints
        }
        bonusPoints
        memberRewards {
          expiresOn
          id
          name
          personalOffer {
            description
            heading
            imageUrl
            link
          }
          type
          value {
            value
            valueType
          }
        }
        bonusOverview {
          nextBonus {
            checkValue
            pointsRequired
          }
          recentBonusPoints
          remainder
          maxBonusStep
          threshold
        }
      }
      favorites {
        styleId
      }
    }
  }
`;

export const RewardsQuery = /* GraphQL */ `
  query GetRewards {
    account {
      rewards {
        profileCompleteness {
          bonusPoints
          completeProfileAttributes
          percentageComplete
          totalRemainingBonusPoints
        }
        bonusPoints
        memberRewards {
          expiresOn
          id
          name
          personalOffer {
            description
            heading
            imageUrl
            link
          }
          type
          value {
            value
            valueType
          }
        }
        bonusOverview {
          nextBonus {
            checkValue
            pointsRequired
          }
          recentBonusPoints
          remainder
          maxBonusStep
          threshold
        }
      }
    }
  }
`;

export const getRewardsData = async (
  graphQLEndpointUrl: string
): Promise<GraphQLResult<GetRewardsQuery>> => {
  return callGraphQL({ query: RewardsQuery }, graphQLEndpointUrl);
};

export const getPendingBonusPointsQuery = /* GraphQL */ `
  query PendingBonusPoints {
    pendingBonusPoints {
      value
    }
  }
`;

export const useUserApi = (graphQLEndpointUrl: string) => {
  const getAccount = async (): Promise<GraphQLResult<AccountResponse>> => {
    return callGraphQL({ query: accountQuery }, graphQLEndpointUrl);
  };

  return { getAccount };
};

export const getAccount = async (
  graphQLEndpointUrl: string
): Promise<GraphQLResult<AccountResponse>> => {
  return callGraphQL({ query: accountQuery }, graphQLEndpointUrl);
};

export const getPendingBonusPoints = async (
  graphQLEndpointUrl: string
): Promise<GraphQLResult<PendingPointsResponse>> => {
  return callGraphQL({ query: getPendingBonusPointsQuery }, graphQLEndpointUrl);
};

export const updateContactMutation = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      message
      mobilePhone
      result
    }
  }
`;

export const updateContact = (
  mutationInput: UpdateContactInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<UpdateContactMutation>> => {
  const variables: UpdateContactMutationVariables = { input: mutationInput };

  return callGraphQL(
    { query: updateContactMutation, variables },
    graphQLEndpointUrl
  );
};

export const updateEmailMutation = /* GraphQL */ `
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input)
  }
`;

export const updateEmail = (
  mutationInput: UpdateEmailInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<{ updateEmail: boolean }>> => {
  const variables = {
    input: mutationInput,
  };

  return callGraphQL(
    { query: updateEmailMutation, variables },
    graphQLEndpointUrl
  );
};

export const updateSocialSecurityNumberMutation = /* GraphQL */ `
  mutation UpdateSocialSecurityNumber(
    $input: UpdateSocialSecurityNumberInput!
  ) {
    updateSocialSecurityNumber(input: $input) {
      status
    }
  }
`;

export const updateSocialSecurityNumber = (
  mutationInput: UpdateSocialSecurityNumberInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<UpdateSocialSecurityNumberMutation>> => {
  const variables: UpdateSocialSecurityNumberMutationVariables = {
    input: mutationInput,
  };

  return callGraphQL(
    { query: updateSocialSecurityNumberMutation, variables },
    graphQLEndpointUrl
  );
};

export const updateConsentMutation = /* GraphQL */ `
  mutation UpdateConsent($input: UpdateConsentInput!) {
    updateConsent(input: $input) {
      status
    }
  }
`;

export const updateConsent = (
  mutationInput: UpdateConsentInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<UpdateConsentMutation>> => {
  const variables: UpdateConsentMutationVariables = { input: mutationInput };

  return callGraphQL(
    { query: updateConsentMutation, variables },
    graphQLEndpointUrl
  );
};

export const subscriptionStatusMutationQuery = /* GraphQL */ `
  mutation PutSubscriptionStatus(
    $acceptsEmail: Boolean!
    $acceptsSms: Boolean!
  ) {
    putSubscriptionStatus(
      input: { acceptsEmail: $acceptsEmail, acceptsSms: $acceptsSms }
    ) {
      message
    }
  }
`;

export const putSubscriptionStatus = (
  variables: SubscriptionStatusInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<PutSubscriptionStatusMutation>> => {
  return callGraphQL(
    { query: subscriptionStatusMutationQuery, variables },
    graphQLEndpointUrl
  );
};

export const deleteSubscriptionStatus = (
  graphQLEndpointUrl: string
): Promise<GraphQLResult<DeleteSubscriptionStatusMutation>> => {
  const mutation = /* GraphQL */ `
    mutation DeleteSubscriptionStatus {
      deleteSubscriptionStatus {
        message
      }
    }
  `;

  return callGraphQL({ query: mutation }, graphQLEndpointUrl);
};

export type RecommendedScanProductsResponse = {
  getRecommendedScanProducts?: {
    categories?: CategoryData[];
    products?: ProductData[];
  };
};

const getRecommendedScansProductsQuery = /* GraphQL */ `
  query GetRecommendedScanProducts($input: RecommendedScanProductsInput) {
    getRecommendedScanProducts(input: $input) {
      categories {
        code
        name
      }
      products {
        isAtLeastOneRecommendedSizeInStock
        averageRating
        baseProductCode
        brand {
          name
        }
        brandLogoURL
        campaignData
        campaignSplashValue
        categoryName
        code
        colorsAvailable
        description
        ean
        price {
          currencyIso
          invertedPrice
          invertedPriceFormatted
          label
          previousPrice
          previousPriceFormatted
          priceDisclaimer
          priceSplash
          salesPrice
          salesPriceFormatted
          type
          userGroupId
          colourTheme {
            backgroundColour
            foregroundColour
            name
          }
        }
        priceDisplay {
          colourTheme {
            backgroundColour
            foregroundColour
            name
          }
          currencyIso
          invertedPrice
          invertedPriceFormatted
          label
          previousPrice
          previousPriceFormatted
          priceDisclaimer
          priceSplash
          salesPrice
          salesPriceFormatted
          type
          userGroupId
        }
        sizeOptions {
          code
          ean
          isInStock {
            at
            dk
            fi
            no
            se
          }
          size
        }
        style
        styleOptions {
          code
          colourData {
            hex
            name
          }
          inStock
          primaryImage
          secondaryImageURLList
          stockStatus
          thumbnailImageURL
          url
        }
        isPossibleToBuy
        formattedPrice
        formattedRecPrice
        isPossibleToBuy
        lastModified
        name

        originalPrice
        originalPriceValue
        pk
        primaryImage
        primaryProductThumbnail
        productImages
        productType
        recPrice
        serviceProduct
        style
        summary
        supplierColour
        url
        violatesGooglePolicy
      }
    }
  }
`;

export const getRecommendedScanProducts = (
  input: RecommendedScanProductsInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<GetRecommendedScanProductsQuery>> => {
  const variables: GetRecommendedScanProductsQueryVariables = { input };

  return callGraphQL(
    { query: getRecommendedScansProductsQuery, variables },
    graphQLEndpointUrl
  );
};

export const requestMembershipMutationQuery = /* GraphQL */ `
  mutation requestMembership($phoneNumber: String!, $siteId: String) {
    requestMembership(input: { phoneNumber: $phoneNumber, siteId: $siteId }) {
      success
    }
  }
`;

export const requestMembership = (
  input: requestMembershipMutationVariables,
  graphQLEndpointUrl: string,
  apiKey: string
): Promise<GraphQLResult<requestMembershipMutation>> => {
  const variables: requestMembershipMutationVariables = input;

  return callGraphQL(
    { query: requestMembershipMutationQuery, variables },
    graphQLEndpointUrl,
    apiKey
  );
};

export const createAccountMutationQuery = /* GraphQL */ `
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      PK
    }
  }
`;

export const createAccount = (
  input: createAccountMutationVariables["input"],
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<createAccountMutation, AccountError>> => {
  return callGraphQL(
    { query: createAccountMutationQuery, variables: { input } },
    graphQLEndpointUrl,
    graphQLApiKey
  );
};

export const voyadoContactQuery = /* GraphQL */ `
  query getVoyadoContact($uuid: String!, $site: String!) {
    getVoyadoContact(input: { uuid: $uuid, site: $site }) {
      email
      firstName
      lastName
      phoneNumber
      socialSecurityNumber
    }
  }
`;

export const getVoyadoContact = (
  input: GetVoyadoContactInput,
  graphQLEndpointUrl: string,
  apiKey: string
): Promise<GraphQLResult<getVoyadoContactQuery>> => {
  const variables: GetVoyadoContactInput = input;

  return callGraphQL(
    { query: voyadoContactQuery, variables, forceAnonymous: true },
    graphQLEndpointUrl,
    apiKey
  );
};

export const updateAccountQuery = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      firstName
      lastName
      city
      street
      zipCode
    }
  }
`;

export const updateAccount = async (
  input: UpdateAccountInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<PersonalInfoData, AccountError>> => {
  const variables = {
    input,
  };

  return callGraphQL(
    { query: updateAccountQuery, variables },
    graphQLEndpointUrl
  );
};

export const initiateOTPChallengeQuery = /* GraphQL */ `
  mutation InitiateOTPChallenge(
    $phoneNumber: String!
    $reCaptchaV3Token: String!
    $site: String!
  ) {
    initiateOTPChallenge(
      input: {
        phoneNumber: $phoneNumber
        reCaptchaV3Token: $reCaptchaV3Token
        site: $site
      }
    ) {
      session
      timestamp
      expires
    }
  }
`;

export const initiateOTPChallenge = async (
  variables: InitiateOTPChallengeInput,
  graphQLEndpointUrl: string,
  apiKey: string
): Promise<GraphQLResult<InitiateOTPChallengeReponse>> => {
  return callGraphQL(
    { query: initiateOTPChallengeQuery, variables, forceAnonymous: true },
    graphQLEndpointUrl,
    apiKey
  );
};

export const respondOTPChallengeQuery = /* GraphQL */ `
  mutation RespondToOTPChallenge(
    $oneTimePassword: String!
    $phoneNumber: String!
    $session: String!
    $site: String!
  ) {
    respondToOTPChallenge(
      input: {
        oneTimePassword: $oneTimePassword
        phoneNumber: $phoneNumber
        session: $session
        site: $site
      }
    ) {
      challengeResult
      session
    }
  }
`;

export const respondOTPChallenge = async (
  variables: RespondToOTPChallengeInput,
  graphQLEndpointUrl: string,
  apiKey: string
): Promise<GraphQLResult<RespondToOTPChallengeMutation>> => {
  return callGraphQL(
    { query: respondOTPChallengeQuery, variables, forceAnonymous: true },
    graphQLEndpointUrl,
    apiKey
  );
};

export const userProductReviewsQuery = /* GraphQL */ `
  query UserProductReviews($input: UserProductReviewsInput!) {
    userProductReviews(input: $input) {
      userReviews {
        reviewedProducts {
          articleNumber
          baseColor
          brandName
          eanCode
          image
          name
          serviceProduct
          size
          url
          sizeFitnessRelevant
        }
        unreviewedProducts {
          articleNumber
          baseColor
          brandName
          eanCode
          image
          name
          serviceProduct
          size
          url
          sizeFitnessRelevant
        }
      }
      nextToken
    }
  }
`;

export type TPurchaseProduct = {
  articleNumber: string;
  baseColor: string;
  brandName: string;
  eanCode: string;
  image: string;
  name: string;
  serviceProduct: boolean;
  size: string;
  url: string;
  sizeFitnessRelevant: boolean;
};

type UserProductReviewsResponse = {
  userProductReviews?: {
    userReviews: {
      reviewedProducts: TPurchaseProduct[];
      unreviewedProducts: TPurchaseProduct[];
    };
    nextToken: string | null;
  } | null;
};

export const getUserProductReviews = async (
  input: UserProductReviewsInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<UserProductReviewsResponse>> => {
  const variables = {
    input,
  };
  return callGraphQL(
    { query: userProductReviewsQuery, variables },
    graphQLEndpointUrl
  );
};

export interface AccountDataValidationError {
  errorDescription: string | null;
  field: string;
  validationErrorCode: string;
}

export type AccountDataError = {
  statusCode: number;
  validationErrors?: AccountDataValidationError[];
};

export type AccountUnknownError = {
  error: unknown;
  result: unknown;
};

export type AccountError = AccountDataError | AccountUnknownError;
