import { IMAGE_BASE_SOURCE_URL } from "react-app/src/constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const IMAGE_QUALITY_DEFAULT = 75;
const IMAGE_PADDING_DEFAULT = 0;

type ImageLoader = {
  src: string;
  width: number;
  quality?: number;
};

const isFilespinImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.filespin);
const isSanityImageUrl = (imageSrc: string) =>
  imageSrc.includes(IMAGE_BASE_SOURCE_URL.sanity);

const addSizeParameter = (
  imageSource: string,
  imageWidth: number,
  params: URLSearchParams
): URLSearchParams => {
  if (isFilespinImageUrl(imageSource)) {
    params.append("resize", `${imageWidth},${imageWidth}`);
  } else if (isSanityImageUrl(imageSource)) {
    params.append("w", imageWidth.toString());
  }

  return params;
};

const addFocalpointParameters = (
  focalPoints: { x: number; y: number },
  params: URLSearchParams
): URLSearchParams => {
  params.append("fit", "crop");
  params.append("crop", "focalpoint");
  params.append("fp-x", focalPoints.x.toString());
  params.append("fp-y", focalPoints.y.toString());

  return params;
};

export const imageLoader =
  (hotspot?: { x: number; y: number }, padding?: number) =>
  ({ src, width, quality }: ImageLoader) => {
    const params = new URLSearchParams();
    params.append("quality", (quality ?? IMAGE_QUALITY_DEFAULT).toString());
    src.startsWith(IMAGE_BASE_SOURCE_URL.sanity) &&
      params.append("auto", "format");
    params.append("pad", (padding ?? IMAGE_PADDING_DEFAULT).toString());
    addSizeParameter(src, width, params);
    isNotNullOrUndefined(hotspot) &&
      isNotNullOrUndefined(hotspot.x) &&
      isNotNullOrUndefined(hotspot.y) &&
      addFocalpointParameters(hotspot, params);

    return `${src}?${params.toString()}`;
  };
