import { RelinkSource } from "react-relink";
import { FavoritesConfirmationEnum } from "./FavoritesState";

export const FavoritesSource = new RelinkSource({
  key: "favoritesCount",
  default: 0,
});

export const FavoritesConfirmationSource = new RelinkSource({
  key: "favoritesConfirmation",
  default: FavoritesConfirmationEnum.Hidden,
});
