import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import type {
  BaseCampaignData,
  CategoryLevelOneLink,
  CategoryLevelTwoLink,
  Image,
  MegaMenuContentData,
  BrandData,
} from "@xxl/content-api";
import type {
  BaseBrandData,
  BaseCampaignData as CampaignData,
  ImageData,
  MegaMenuContentData as MegaMenuContent,
  MegaMenuLevelOneLink,
  MegaMenuLevelTwoLink,
  MenuCategoryData,
} from "@xxl/frontend-api";
import {
  PimApi,
  Configuration as PimApiConfiguration,
  type CategoryData,
} from "@xxl/pim-api";
import {
  getContentApiConfiguration,
  getEnvVar,
  getLegacySiteUid,
  getPimApiConfiguration,
  getSiteUid,
} from "../environment-variables";
import { getDiscountedProductCountInCategory } from "@/utils/apis/solr-api";
import { translateMessage } from "@/utils/translations/translateMessage";
import { CACHE_TTL_15_MIN, STALE_TTL_3_MIN } from "@/utils/apis/constants";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  ContentApi,
  Configuration as ContentApiConfiguration,
} from "@xxl/content-api";

const discountSubCategorySlugTranslationKey =
  "category.page.discounts.subcategory.slug";
const discountSubCategorySuffix = "promo";

const contentApi = new ContentApi(
  new ContentApiConfiguration(getContentApiConfiguration())
);

const pimApi = new PimApi(new PimApiConfiguration(getPimApiConfiguration()));

const getDiscountCategoryForCategoryData = async (
  categoryData: CategoryData
): Promise<CategoryData | null> => {
  if (categoryData.code === undefined) {
    return null;
  }
  const productCount = await getDiscountedProductCountInCategory(
    categoryData.code,
    getSiteUid()
  );
  if (productCount === 0) {
    return null;
  }
  const slugName = await translateMessage(
    discountSubCategorySlugTranslationKey
  );
  const name = slugName.charAt(0).toUpperCase() + slugName.slice(1);
  const url = categoryData.url?.replace("/c/", `/${slugName}/c/`);
  const code = categoryData.code + discountSubCategorySuffix;
  const categoryLevel = (categoryData.categoryLevel ?? 0) + 1;
  return {
    code,
    name,
    url,
    categoryLevel,
    productCount,
    breadcrumbs: [
      ...(categoryData.breadcrumbs ?? []),
      {
        code,
        name,
        url,
        categoryLevel,
      },
    ],
    subCategories: [],
  };
};

const fetchCategoryData = async (
  categoryCode: string | undefined
): Promise<MenuCategoryData | undefined> => {
  if (!categoryCode) {
    return;
  }
  const { data } = await pimApi.getCategories(getLegacySiteUid(), categoryCode);
  if (data.length === 0) {
    return;
  }
  const [categoryData] = data;
  const discountCategory =
    await getDiscountCategoryForCategoryData(categoryData);
  if (discountCategory !== null) {
    categoryData.subCategories?.push(discountCategory);
  }

  const subCategories = categoryData.subCategories
    ?.filter(({ productCount }) => (productCount ?? 0) > 0)
    .map(({ name, url }) => ({ name, url }));
  return categoryData
    ? {
        code: categoryCode,
        url: categoryData.url,
        subCategories: subCategories,
      }
    : { code: categoryCode };
};

const mapImage = (image: Image | undefined): ImageData | undefined =>
  image?.url
    ? {
        ...{ ...image, url: undefined },
        baseUrl: image.url,
      }
    : undefined;

const mapCampaign = (
  content: Image | undefined,
  campaign: BaseCampaignData | undefined
): CampaignData | undefined =>
  content === undefined &&
  isNotNullOrUndefined(campaign) &&
  (!campaign.fromDate || new Date(campaign.fromDate).getTime() < Date.now()) &&
  (!campaign.toDate || new Date(campaign.toDate).getTime() > Date.now())
    ? {
        ...{ ...campaign, fromDate: undefined, toDate: undefined },
        bannerImage: mapImage(campaign.bannerImage),
        ...(campaign.campaignId
          ? {
              url: `${getEnvVar(
                "REQUEST_MAPPING_CAMPAIGNHUBPAGE"
              )}/${encodeURIComponent(campaign.campaignId)}`,
            }
          : undefined),
      }
    : undefined;

const mapLink = async (
  link: CategoryLevelTwoLink
): Promise<MegaMenuLevelTwoLink> => ({
  displayName: link.displayName,
  category: link.categoryCode
    ? await fetchCategoryData(link.categoryCode)
    : undefined,
  content: mapImage(link.content),
});

const mapBrandLinks = (brandLinks: BrandData[]): BaseBrandData[] => {
  return brandLinks.map(({ brandCode, brandName }) => ({
    brandCode,
    brandName,
    url: `/b/${brandCode ?? ""}/${brandName ?? ""}`,
  }));
};

const mapLinks = async (
  rawLinks: CategoryLevelOneLink[]
): Promise<MegaMenuLevelOneLink[]> => {
  const links = await Promise.all(
    rawLinks.map(
      async (link): Promise<MegaMenuLevelOneLink> => ({
        ...(await mapLink(link)),
        links: await Promise.all(link.links?.map(mapLink) ?? []),
        campaign: mapCampaign(link.content, link.campaign),
        brandLinks: mapBrandLinks(link.brandLinks ?? []),
        buttonText: link.buttonText,
        contentText: link.contentText,
        link: link.link,
      })
    )
  );
  return links;
};

const mapToContent = async (
  menuContent: MegaMenuContentData
): Promise<MegaMenuContent> => ({
  campaignHubLink: menuContent.campaignHubLink,
  links: await mapLinks(menuContent.categoryLinks ?? []),
  content: mapImage(menuContent.content),
});

/**
 * Server-side only fetching of mega menu content
 */
const fetchMegaMenu = memoize(
  async () => {
    const {
      data: { result: [menuContent] = [] },
    } = await contentApi.getMegaMenu(getSiteUid());
    if (!menuContent) {
      return;
    }
    return await mapToContent(menuContent);
  },
  new Keyv({ namespace: "mega-menu" }),
  {
    ttl: CACHE_TTL_15_MIN,
    staleTtl: STALE_TTL_3_MIN,
  }
);

export { fetchMegaMenu };
export type { MegaMenuContent };
