import { useEffect, useState } from "react";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import type { SizeGuide } from "@xxl/content-api";
import { StatusCodes } from "http-status-codes";

const { NO_CONTENT } = StatusCodes;

export const useSizeGuides = (brandCode: string, categoryCodes: string[]) => {
  const [data, setData] = useState<SizeGuide | undefined>(undefined);
  const {
    nextFrontendApi: { getSizeGuide },
  } = useApiClients();

  useEffect(() => {
    const fetchSizeGuides = async () => {
      try {
        const { data: sizeGuideData, status } = await getSizeGuide(
          brandCode,
          categoryCodes
        );
        if (status === NO_CONTENT) {
          return;
        }
        setData(sizeGuideData);
        return;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    };
    void fetchSizeGuides();
  }, []);

  return data;
};
