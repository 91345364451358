import { caching, type MemoryCache } from "cache-manager";
import { logError } from "react-app/src/utils/xxl-log";

let xxlCache: MemoryCache | null | undefined;

const getXxlCache = async (): Promise<MemoryCache | null> => {
  if (xxlCache === undefined) {
    xxlCache = await caching("memory").catch((error: unknown) => {
      logError({
        error,
        message: "Failed to create server side cache.",
      });
      return null;
    });
  }
  return xxlCache;
};

export default getXxlCache;
