const iconSize = {
  mini: "12px",
  tiny: "16px",
  small: "18px",
  medium: "20px",
  big: "24px",
  huge: "40px",
} as const;

export default iconSize;
