import { Text } from "react-app/src/components/Text";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { SizeHeadingSection } from "./ProductSizeSelector.styled";
import { Icon } from "react-app/src/components/Icon/Icon";
import stringFormat from "string-format";
import { useSizeRecommendation } from "./useSizeRecommendation";
import type { TranslationKey } from "react-app/src/translations";
import type { SizeFitness } from "src/generated/graphql-code-generator";

export const SizeRecommendationComponent = ({
  productId = "",
}: {
  productId?: string;
}) => {
  const { t } = useTranslations();
  const sizeRecommendation = useSizeRecommendation(productId);
  if (sizeRecommendation === undefined) return null;

  const sizeFitnessTranslationKeyMap: Record<SizeFitness, TranslationKey> = {
    SMALLER_FIT: "product.details.customer.size.rating.small",
    BIGGER_FIT: "product.details.customer.size.rating.big",
    TRUE_TO_SIZE: "product.details.customer.size.rating.true",
  };

  const translationString =
    sizeFitnessTranslationKeyMap[sizeRecommendation.sizeFitness];

  return (
    <SizeHeadingSection>
      <Icon name="Star" size={12} />
      <Text as="span" fontFamily="medium" size="small">
        {stringFormat(
          t(translationString),
          sizeRecommendation.percentage.toString()
        )}
      </Text>
    </SizeHeadingSection>
  );
};
