import { useEffect, useState } from "react";
import { getProductSizesStockStatus } from "react-app/src/components/ClickAndCollect/api/api";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { StockStatus } from "react-app/src/generated/graphql-code-generator";

export type ProductSizesStockStatus = {
  ean: string;
  onlineStockStatus: StockStatus;
  storeStockStatus: StockStatus | null;
  storesWithStockCount: number;
};

export const useProductSizesStockStatus = (
  productId?: string,
  storeId?: string
) => {
  const [productSizesStockStatus, setProductSizesStockStatus] = useState<
    ProductSizesStockStatus[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    configuration: {
      amplifyConfig: { aws_appsync_graphqlEndpoint, aws_appsync_apiKey },
    },
  } = useSharedData().data;

  useEffect(() => {
    if (productId === undefined || storeId === undefined) return;
    setIsLoading(true);
    const fetchProductSizesStockStatus = async () => {
      try {
        const res = await getProductSizesStockStatus({
          productCode: productId.toString(),
          storeId: storeId.toString(),
          graphQLEndpointUrl: aws_appsync_graphqlEndpoint,
          graphQLApiKey: aws_appsync_apiKey,
        });
        setProductSizesStockStatus(res);
      } catch (e) {
        console.error("error", e);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchProductSizesStockStatus();
  }, [aws_appsync_apiKey, aws_appsync_graphqlEndpoint, productId, storeId]);

  return {
    productSizesStockStatus,
    isLoadingProductSizesStockStatus: isLoading,
  };
};
