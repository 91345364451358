import styled from "@emotion/styled/macro";
import parse from "html-react-parser";
import { RibbonHeading } from "../RibbonHeading";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { resolutionSizes } from "react-app/src/config";
import type { StrictClassification, ProductLink } from "../types";
import { SECTION_NAMES } from "../constants";
import { SectionWithHeaderOffset } from "../../common/SectionWithHeaderOffset/SectionWithHeaderOffset.styled";
import { useClassifications } from "../../../hooks/useClassifications";
import { Article, Description, Ul } from "./ProductDescriptions.styled";
import type { ServicePromotion } from "@xxl/content-api";
import { isNotEmpty } from "@xxl/common-utils";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { Text } from "react-app/src/components/Text/Text";
import { ProductLinkComponent } from "./ProductLinkComponent";

const ProductDescriptionGrid = styled.div`
  display: grid;
  grid-gap: ${xxlTheme.spaces.regular};
  row-gap: ${xxlTheme.spaces.large};
  @media (min-width: ${resolutionSizes.laptop}px) {
    grid-template-columns: 1fr 1fr;
    gap: ${xxlTheme.spaces.big};
  }
`;

type Props = {
  classifications: StrictClassification[];
  description: string | null;
  servicePromotions: ServicePromotion[];
  productLink: ProductLink | null;
};
export const ProductDescriptions = ({
  classifications = [],
  description,
  servicePromotions,
  productLink,
}: Props) => {
  const { t } = useTranslations();

  const formattedClassifications = useClassifications(classifications, {
    limit: classifications.length,
    positiveOnly: false,
  });

  return (
    <ProductDescriptionGrid>
      {isNotEmpty(description) ? (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.description}>
            <RibbonHeading>
              {t("product.details.description.tab")}
            </RibbonHeading>
            <Article>
              <ProductLinkComponent productLink={productLink} />
              <Description>{parse(description)}</Description>
            </Article>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      ) : null}
      {classifications.length === 0 ? null : (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.specifications}>
            <RibbonHeading>
              {t("product.details.specification.tab")}
            </RibbonHeading>
            <Ul>
              {formattedClassifications.map(([name, value]) => (
                <li key={name}>
                  <Text typography="baseBold" as="span">
                    {name}:&nbsp;
                  </Text>
                  <Text as="span">{value}</Text>
                </li>
              ))}
            </Ul>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      )}
      {servicePromotions.length === 0 ? null : (
        <ErrorBoundary>
          <SectionWithHeaderOffset id={SECTION_NAMES.servicePromotions}>
            <RibbonHeading>
              {t("product.details.servicepromotion.tab")}
            </RibbonHeading>
            <>
              {servicePromotions.map(({ content }, index) => {
                if (content === undefined) {
                  return null;
                }
                return (
                  <Article key={index}>
                    <Text>{parse(content)}</Text>
                  </Article>
                );
              })}
            </>
          </SectionWithHeaderOffset>
        </ErrorBoundary>
      )}
    </ProductDescriptionGrid>
  );
};
