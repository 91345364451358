import noop from "lodash/noop";
import { ProductStyleSelectorComponent } from "./ProductStyleSelectorComponent";
import type { ProductData } from "@xxl/pim-api";
import type { Style } from "./types";
import type { MouseEvent } from "react";
import { STOCK_STATUS } from "react-app/src/constants";

type Props = {
  onClick?: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => void;
  styleOptions: ProductData["styleOptions"];
};

export const ProductStyleSelector = ({
  onClick = noop,
  styleOptions = [],
}: Props) => {
  const { length: StyleOptionsLength } = styleOptions;

  if (StyleOptionsLength <= 1) return null;

  const styles = styleOptions
    .map((style) => {
      const {
        code,
        pk,
        primaryImage,
        url,
        colourData,
        name,
        stockStatus,
        selected,
      } = style;
      if (code === undefined) return null;
      if (primaryImage === undefined) return null;
      if (name === undefined) return null;
      if (colourData === undefined) return null;
      if (colourData.name === undefined) return null;
      if (pk === undefined) return null;
      if (stockStatus === undefined) return null;
      if (url === undefined) return null;
      return {
        code,
        primaryImage,
        name,
        colourDataName: colourData.name,
        pk,
        isInStock:
          stockStatus === STOCK_STATUS.INSTOCK ||
          stockStatus === STOCK_STATUS.LOWSTOCK,
        url,
        selected,
      };
    })
    .filter(Boolean) as Style[];

  return <ProductStyleSelectorComponent onClick={onClick} styles={styles} />;
};
