import { Icon } from "../../../components/Icon";
import { Text } from "../../../components/Text";
import { color } from "@xxl/theme";
import styled from "@emotion/styled/macro";
import { XxlStack } from "../../../components/Common/XxlStack";
import { xxlTheme } from "../../../styles/xxl-theme";

const StyledContainer = styled.div`
  background-color: ${color.lightGreen.hex};
  display: flex;
  justify-content: space-between;
  padding: ${xxlTheme.spaces.miniRegular};
  gap: ${xxlTheme.spaces.miniRegular};
`;

const AddServiceButton = styled.button`
  display: flex;
  align-items: end;
  gap: ${xxlTheme.spaces.line};
  background-color: ${color.lightGreen.hex};
  border: none;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
`;

export const AddService = ({
  handleClick,
  descriptionText,
  addServiceText,
}: {
  handleClick: () => void;
  descriptionText: string;
  addServiceText: string;
}) => {
  return (
    <StyledContainer>
      <XxlStack direction="row" gap={"6px"}>
        <XxlStack minWidth={"22px"}>
          <Icon name="Workshop" size={20} />
        </XxlStack>
        <Text size="small">{descriptionText}</Text>
      </XxlStack>
      <AddServiceButton onClick={handleClick}>
        <Icon name="Plus" />
        <Text underline={true} typography="smallMedium">
          {addServiceText}
        </Text>
      </AddServiceButton>
    </StyledContainer>
  );
};
