import type { SetStorageItem } from "../../global";
import { getCookieInformationConsent } from "../Cookie/XXLCookie";

export const storageKeys = {
  PREFERRED_PLP_COLUMNS_NUMBER_MOBILE:
    "xxl-preferred-plp-columns-number-mobile",
  PREFERRED_PLP_COLUMNS_NUMBER_TABLET:
    "xxl-preferred-plp-columns-number-tablet",
  PREFERRED_PLP_COLUMNS_NUMBER_DESKTOP:
    "xxl-preferred-plp-columns-number-desktop",
  RETURN_EXCHANGE_TMP_DATA: "xxl-return-exchange",
};

export const getLocalStorageParsedItem = (key: string): unknown => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return null;
};

export const setStorageItem: SetStorageItem = (key, value, type = "local") => {
  const cookieInformationConsent = getCookieInformationConsent();

  if (
    cookieInformationConsent !== null &&
    (cookieInformationConsent.consents_rejected === undefined ||
      cookieInformationConsent.consents_rejected.length === 0)
  ) {
    const storage = type === "local" ? localStorage : sessionStorage;
    storage.setItem(key, value);
  } else {
    console.info(`${type} storage item not set: ${key}`);
  }
};

export const setLocalStorageItem = (key: string, item: unknown) => {
  if (typeof item !== "string") {
    const stringifiedItem = JSON.stringify(item);
    setStorageItem(key, stringifiedItem);
  } else {
    setStorageItem(key, item);
  }
};

export const setSessionStorageItem = (key: string, item: unknown) => {
  const itemToSet = typeof item !== "string" ? JSON.stringify(item) : item;
  setStorageItem(key, itemToSet, "session");
};

export const getSessionStorageItem = (key: string): unknown => {
  const item = sessionStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return null;
};

if (typeof window !== "undefined") {
  window.XxlHelpers = {
    ...window.XxlHelpers,
    setStorageItem,
  };
}
