import { useEffect, useState } from "react";
import { A_B_TEST_MAP } from "./constants";
import type { SymplifyActivatedProject } from "../../global";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const TIMEOUT = 200;

type TestNames = keyof typeof A_B_TEST_MAP;

export const useSymplify = (testName: TestNames, campaignId?: string) => {
  const [activeProjects, setActiveProjects] = useState<
    SymplifyActivatedProject[]
  >([]);
  const [isTestVariant, setIsTestVariant] = useState(false);
  const [shouldCheckSymplify, setShouldCheckSymplify] = useState(true);

  useEffect(() => {
    const setSymplifyProjects = () => {
      if (isNotNullOrUndefined(window.symplify)) {
        setActiveProjects(window.symplify.getActivatedProjects());
      } else {
        setTimeout(() => {
          setShouldCheckSymplify(true);
        }, TIMEOUT);
      }
    };

    if (shouldCheckSymplify) {
      setShouldCheckSymplify(false);
      setSymplifyProjects();
    }
  }, [shouldCheckSymplify]);

  useEffect(() => {
    if (activeProjects.length > 0) {
      if (
        testName === "CART_SERVICE_ADD_ON" ||
        testName === "PDP_PRODUCT_DESCRIPTION"
      ) {
        const { PROJECT_ID, VARIATION_ID } = A_B_TEST_MAP[testName];
        setIsTestVariant(
          activeProjects.some(
            (p) => p.projectId === PROJECT_ID && p.variationId === VARIATION_ID
          )
        );
      } else if (testName === "HOMEPAGE_SANITY_TEST") {
        setIsTestVariant(
          activeProjects.some((p) =>
            p.projectName
              .toLowerCase()
              .includes(A_B_TEST_MAP.HOMEPAGE_SANITY_TEST.PROJECT_NAME)
          )
        );
      } else if (isNotNullOrUndefined(campaignId)) {
        setIsTestVariant(
          activeProjects.some(
            (p) =>
              p.projectName
                .toLowerCase()
                .includes(
                  A_B_TEST_MAP.CAMPAIGN_PAGE_SANITY_TEST.PROJECT_NAME
                ) && p.projectName.includes(campaignId)
          )
        );
      }
    }
  }, [activeProjects]);

  return isTestVariant;
};
