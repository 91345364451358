/**
 * Add your A/B test here
 * @param {string} PROJECT_NAME - Name of the project returned from Symplify
 * @param {number} PROJECT_ID - The ID of the project
 * @param {string} VARIATION_NAME - The name of the variation in Symplify
 */
export const A_B_TEST_MAP = {
  CART_SERVICE_ADD_ON: {
    PROJECT_ID: 192527366,
    PROJECT_NAME: "cart-services-add-on",
    VARIATION_ID: 11324364,
    VARIATION_NAME: "cart-services-add",
  },
  PDP_PRODUCT_DESCRIPTION: {
    PROJECT_ID: 192527437,
    PROJECT_NAME: "product-description-test",
    VARIATION_ID: 11324512,
    VARIATION_NAME: "product-description-test",
  },
  HOMEPAGE_SANITY_TEST: {
    PROJECT_NAME: "homepage",
  },
  CAMPAIGN_PAGE_SANITY_TEST: {
    PROJECT_NAME: "category",
  },
} as const;

export const SERVICE_CODE = {
  IMBOX: "1122148_1_Style",
  BIKE: "1220069_1_Style",
} as const;
