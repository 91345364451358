import { SolrApi, Configuration } from "@xxl/solr-api";
import { getSolrApiConfiguration } from "../environment-variables";

const solrApi = new SolrApi(new Configuration(getSolrApiConfiguration()));

export const getDiscountedProductCountInCategory = async (
  categoryCode: string,
  siteId: string
): Promise<number> => {
  const {
    data: {
      facet_counts: {
        facet_fields: { category_string_mv },
      },
    },
  } = await solrApi.getCategoriesPromotionProductsCount(siteId);
  const facetIndex = category_string_mv.indexOf(categoryCode);
  return facetIndex === -1
    ? 0
    : parseInt(category_string_mv[facetIndex + 1] ?? "0");
};
