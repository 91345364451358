import { Price } from "../Price/Price";
import { Stack } from "@mui/material";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Attributes } from "./Attributes";
import { OverviewGridArea } from "../ProductDetailsPage.styles";
import { ToggleFeature } from "../../../../../react-app/src/components/Common/FeatureToggle";
import { FavoriteProductHeart } from "../../../../../react-app/src/components/Favorites/FavoriteProductHeart";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useClient } from "../../../../../react-app/src/hooks/useClient/useClient";
import { AverageRating } from "./AverageRating";
import type { PriceProps } from "../Price/Price";
import type { StrictClassification } from "../types";
import { SeeDescription } from "./SeeDescription";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { ProductHeading } from "./ProductHeading";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import type { UspItem } from "@xxl/pim-api";
import { useState, useEffect } from "react";
import { useSharedData } from "react-app/src/contexts/SharedData";

type Props = {
  averageRating: number | null;
  baseProductName: string;
  brandName: string;
  brandUrl: string;
  brandLogoUrl: string;
  classifications: StrictClassification[];
  priceData: PriceProps;
  shouldDisplaySeeFullDescriptionLink?: boolean;
  widget?: JSX.Element;
  onRatingsClick?: () => void;
  productStyle?: string;
  recommendationPercentage?: number | null;
  shouldShowWriteReviewLink?: boolean;
  usp?: UspItem[];
};

export const ProductOverview = ({
  brandName,
  brandUrl,
  brandLogoUrl,
  baseProductName,
  onRatingsClick,
  priceData,
  classifications,
  averageRating,
  shouldDisplaySeeFullDescriptionLink = true,
  widget,
  productStyle,
  recommendationPercentage,
  shouldShowWriteReviewLink = true,
  usp,
}: Props) => {
  const isClient = useClient();
  const [usps, setUsps] = useState<UspItem[]>([]);
  const {
    featureToggles: { toggle_products_as_package_quantity, toggle_videoly },
  } = useSharedData().data;

  useEffect(() => {
    if (isNotNullOrUndefined(usp) && usp.length > 0) {
      setUsps(usp);
    }
  }, [toggle_products_as_package_quantity, usp]);

  return (
    <OverviewGridArea
      spacing={xxlTheme.spaces.regular}
      className="pdp-overview"
    >
      <XxlStack flexDirection={"row"} justifyContent={"space-between"}>
        <ProductHeading
          baseProductName={baseProductName}
          brandName={brandName}
          brandLogoUrl={brandLogoUrl}
          brandUrl={brandUrl}
        />
        <ErrorBoundary>
          {isNotNullOrUndefined(productStyle) && isClient && (
            <ToggleFeature flag="toggle_favorites">
              <FavoriteProductHeart
                productStyle={productStyle}
                isInPDP={true}
              />
            </ToggleFeature>
          )}
        </ErrorBoundary>
      </XxlStack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"start"}
        gap={xxlTheme.spaces.extraLarge}
      >
        <Attributes usps={usps} classifications={classifications} />
        <AverageRating
          onClick={onRatingsClick}
          averageRating={averageRating}
          shouldShowWriteReviewLink={shouldShowWriteReviewLink}
          recommendationPercentage={recommendationPercentage}
        />
      </Stack>
      {shouldDisplaySeeFullDescriptionLink && <SeeDescription />}
      {toggle_videoly && <div id="videoly-videobox-placeholder"></div>}
      <ErrorBoundary>
        <Price
          hasUndeductedRewardDiscount={priceData.hasUndeductedRewardDiscount}
          isDiscountedMainPrice={priceData.isDiscountedMainPrice}
          isInvertedPrice={priceData.isInvertedPrice}
          latestPriceFormatted={priceData.latestPriceFormatted}
          secondaryPriceFormatted={priceData.secondaryPriceFormatted}
          salesPriceFormatted={priceData.salesPriceFormatted}
          secondaryPriceDisclaimer={priceData.secondaryPriceDisclaimer}
          priceDisclaimer={priceData.priceDisclaimer}
          userGroupId={priceData.userGroupId}
          widget={widget}
        />
      </ErrorBoundary>
    </OverviewGridArea>
  );
};
