import React from "react";
import { Banner } from "./Banner";
import { HeaderLink } from "./CategorySection.styled";
import { Link } from "./Link.styled";
import {
  LastGridChild,
  StyledGridTemporary as GridTemporary,
} from "./Grid.styled";
import type { ContentSectionData } from "./content-section-helper";
import { getImageUrl } from "../../../utils/xxl-image";
import { StyledImage } from "./ContentSection.styled";
import { SectionHeaderTemporary } from "./SectionHeaderTemporary.styled";
import { contentSectionStyles } from "./mega-menu-helper";

type ContentSectionProps = {
  content: ContentSectionData;
};

export const ContentSection: React.FunctionComponent<ContentSectionProps> = ({
  content,
}) => {
  const linkStyle: React.CSSProperties = { position: "relative" };
  const { title, description, href, alt, image, banner } = content;

  return (
    <LastGridChild>
      {title !== undefined && (
        <HeaderLink nrOfColumns={1} style={contentSectionStyles} href={href}>
          {title}
        </HeaderLink>
      )}
      <Link style={linkStyle} {...{ href }}>
        {description !== null && (
          <span>
            {description}
            <br />
          </span>
        )}
        {image !== undefined && (
          <StyledImage
            src={getImageUrl(image, 232, 132)}
            alt={alt}
            loading="lazy"
            withDescription={Boolean(description)}
          />
        )}
        {banner !== null && <Banner text={banner} />}
      </Link>
    </LastGridChild>
  );
};

export const ContentSectionTemporary: React.FunctionComponent<
  ContentSectionProps
> = ({ content }) => {
  const linkStyle: React.CSSProperties = { position: "relative" };
  const { title, description, href, alt, image, banner } = content;

  return (
    <GridTemporary itemType="picture">
      {title !== undefined && (
        <SectionHeaderTemporary style={{ fontSize: 24 }} {...{ href }}>
          {title}
        </SectionHeaderTemporary>
      )}
      <Link style={linkStyle} {...{ href }}>
        {description !== null && (
          <span>
            {description}
            <br />
          </span>
        )}
        {image !== undefined && (
          <StyledImage
            src={getImageUrl(image, 232, 132)}
            alt={alt}
            loading="lazy"
            withDescription={Boolean(description)}
          />
        )}
        {banner !== null && <Banner text={banner} />}
      </Link>
    </GridTemporary>
  );
};
