import { hasNoValue, hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import React, { useState } from "react";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import {
  PRODUCT_CARD_IMAGE_SIZE_DESKTOP,
  PRODUCT_CARD_IMAGE_SIZE_MOBILE,
} from "../constants";
import { getFileSpinUrl } from "./product-image-helper";
import { Image } from "./ProductImage.styled";

type Props = {
  alt?: string;
  columnAmount?: number;
  fetchPriority?: "auto" | "high" | "low";
  focusImageUrl?: string;
  imageUrl?: string;
  isFocused?: boolean;
  loading?: "eager" | "lazy";
  size?: number;
  addImageParameters?: boolean;
};

export const ProductImage = ({
  alt = "",
  focusImageUrl,
  imageUrl,
  isFocused = false,
  size = 292,
  fetchPriority = "low",
  loading,
  addImageParameters = true,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isTabletOrDesktop = useXxlMediaQuery("TabletAndDesktopMediaQuery");

  const initializeImage = (url: string | undefined): string | undefined => {
    if (!isNotNullOrUndefined(url)) {
      return undefined;
    }

    if (addImageParameters) {
      return getFileSpinUrl({
        baseUrl: url,
        size: isMobile
          ? PRODUCT_CARD_IMAGE_SIZE_MOBILE
          : PRODUCT_CARD_IMAGE_SIZE_DESKTOP,
      });
    }
    return url;
  };

  const imageSrc = initializeImage(imageUrl);
  const focusImageSrc = initializeImage(focusImageUrl);
  const shouldShowHoverImage =
    isTabletOrDesktop && isFocused && hasValue(focusImageSrc);

  if (hasNoValue(imageSrc)) {
    return null;
  }

  return (
    <figure>
      <Image
        alt={alt}
        height={size}
        key={shouldShowHoverImage ? focusImageSrc : imageSrc}
        width={size}
        isVisible={isVisible}
        onLoad={() => setIsVisible(true)}
        src={shouldShowHoverImage ? focusImageSrc : imageSrc}
        fetchPriority={fetchPriority}
        loading={loading}
      />
      {isTabletOrDesktop && hasValue(focusImageSrc) && (
        <Image
          alt={alt}
          height={size}
          width={size}
          isVisible={false}
          src={focusImageSrc}
          fetchPriority="low"
          loading="lazy"
          style={{ position: "absolute" }}
        />
      )}
    </figure>
  );
};
