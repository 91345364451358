import { colorsAsCssVariable } from "react-app/src/styles/theme/colors";
import type { CampaignCountdownProps } from "./ProductImageSlider/CampaignSplash/CampaignCountdown/CampaignCountdown";
import type { CountdownTimerSettings } from "@xxl/frontend-api";
import type {
  Classification,
  ProductLink,
  ProductPriceDisplayData,
} from "@xxl/pim-api";
import type { StrictPriceDisplay, StrictClassification } from "./types";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { useState } from "react";
import {
  isEmpty,
  isNotNullOrUndefined,
  isNotUndefined,
} from "@xxl/common-utils";
import { PERSONALIZED_PRODUCT_LIST_QUERY_NAME } from "react-app/src/components/Product/constants";
import type { Trackers } from "react-app/src/contexts/Tracking";
import { StockLevels } from "react-app/src/components/ClickAndCollect/api/api";
import type { GetProductSizesStockStatusQuery } from "react-app/src/generated/graphql-code-generator";

const { IN_STOCK, LOW_STOCK, OUT_OF_STOCK } = StockLevels;

export const createTimerProps = (
  settings: CountdownTimerSettings | null,
  fontColor?: string
): CampaignCountdownProps | null => {
  if (settings === null) {
    return null;
  }
  const { date, startDate, isOnlyHours } = settings;

  if (date === undefined) {
    console.error("Countdown property 'date' is undefined");
    return null;
  }

  return {
    convertDaysToHours: isOnlyHours ?? false,
    endDate: new Date(date),
    fontColor:
      fontColor !== undefined ? fontColor : colorsAsCssVariable.xxlBlack,
    startDate: startDate !== undefined ? new Date(startDate) : undefined,
  };
};

export const isStrictPriceDisplay = (
  ppd: unknown
): ppd is StrictPriceDisplay => {
  const { salesPrice, salesPriceFormatted, type } =
    ppd as ProductPriceDisplayData;
  return (
    salesPrice !== undefined &&
    salesPriceFormatted !== undefined &&
    type !== undefined
  );
};

export const createFrontendPriceDisplays = (
  priceDisplays: ProductPriceDisplayData[]
): StrictPriceDisplay[] => {
  return priceDisplays.map((ppd) => {
    if (isStrictPriceDisplay(ppd)) {
      return ppd;
    }

    throw Error("Price display is missing required properties.");
  });
};

export const createClassifications = (
  classifications: Classification[]
): StrictClassification[] => {
  if (classifications.length === 0) {
    return [];
  }

  return classifications
    .map(({ name, value, valueType, id, unit }) => {
      if (
        name === undefined ||
        value === undefined ||
        valueType === undefined ||
        id === undefined
      ) {
        console.error(
          `Missing classification properties. ${typeof name}, ${typeof value}, ${typeof valueType}, ${typeof id},`
        );
        return;
      }
      return {
        id,
        name,
        value,
        valueType,
        unit: unit ?? null,
      };
    })
    .filter(isNotUndefined);
};

export const getColorTheme = (priceDisplay: {
  colourTheme?: {
    backgroundColour?: string;
    foregroundColour?: string;
    name?: string;
  };
  colorTheme?: {
    backgroundColor?: string;
    foregroundColor?: string;
    name?: string;
  };
}) => {
  const colors = {
    name: "",
    backgroundColor: "",
    foregroundColor: "",
  };
  if ("colourTheme" in priceDisplay) {
    if (
      priceDisplay.colourTheme?.backgroundColour !== undefined &&
      priceDisplay.colourTheme.foregroundColour !== undefined
    ) {
      colors.backgroundColor = priceDisplay.colourTheme.backgroundColour;
      colors.foregroundColor = priceDisplay.colourTheme.foregroundColour;
      colors.name = priceDisplay.colourTheme.name ?? "";
      return colors;
    }
  }
  if (isNotNullOrUndefined(priceDisplay.colorTheme)) {
    if (
      priceDisplay.colorTheme.backgroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== undefined &&
      priceDisplay.colorTheme.foregroundColor !== ""
    ) {
      colors.backgroundColor = priceDisplay.colorTheme.backgroundColor;
      colors.foregroundColor = priceDisplay.colorTheme.foregroundColor;
      colors.name = priceDisplay.colorTheme.name ?? "";
      return colors;
    }
  }
  return colors;
};

export const getComponentSpacing = ({
  isLaptopSize,
}: {
  isLaptopSize: boolean;
}) => (isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular);

export const useIsSizeSelectDrawerOpen = () => {
  const [isSizeSelectDrawerOpen, setIsSizeSelectDrawerOpen] = useState(false);
  const toggleIsSizeSelectDrawerOpen = () =>
    setIsSizeSelectDrawerOpen(!isSizeSelectDrawerOpen);
  return {
    isSizeSelectDrawerOpen,
    setIsSizeSelectDrawerOpen,
    toggleIsSizeSelectDrawerOpen,
  };
};

export const getProductListName = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(PERSONALIZED_PRODUCT_LIST_QUERY_NAME);
};

export const convertProductLink = (productLink?: ProductLink) =>
  isNotNullOrUndefined(productLink) &&
  isNotNullOrUndefined(productLink.title) &&
  isNotNullOrUndefined(productLink.url)
    ? { title: productLink.title, url: productLink.url }
    : null;

export const pushProductViewEvent = ({
  trackers,
  brandName,
  googleCategory,
  productCode,
  productName,
  productStyle,
  salesChannelAvailability,
  salesPrice,
  listName,
}: {
  trackers: Trackers;
  brandName: string;
  googleCategory: string;
  productCode: string;
  productName: string;
  productStyle: string;
  salesChannelAvailability: string;
  salesPrice?: number;
  listName: string | null;
}) => {
  const product = {
    name: productName,
    id: productCode,
    price: salesPrice ?? 0,
    brand: brandName,
    category: googleCategory,
    variant: productStyle,
    salesChannelAvailability,
  };
  trackers.sendProductViewEvent({
    product,
    listName: listName ?? "",
  });
};

export const getSalesChannelAvailability = ({
  isEverySizeOutOfStockOnlineAndInStores,
  multiChannelAvailability,
  onlyAvailableInStoreNoClickAndCollect,
}: {
  isEverySizeOutOfStockOnlineAndInStores: boolean;
  multiChannelAvailability: string[];
  onlyAvailableInStoreNoClickAndCollect: boolean;
}) => {
  const EXPECTED_NUMBER_OF_ITEMS = 2;
  if (isEverySizeOutOfStockOnlineAndInStores) {
    return "Archived";
  }
  if (
    isEmpty(multiChannelAvailability) ||
    multiChannelAvailability.length > EXPECTED_NUMBER_OF_ITEMS
  ) {
    return "Unknown";
  }
  if (multiChannelAvailability.every((item) => item === "ONLINE")) {
    return "Online only";
  }
  if (multiChannelAvailability.every((item) => item === "STORE")) {
    return `Store only ${
      onlyAvailableInStoreNoClickAndCollect ? "without" : "with"
    } Click and Collect`;
  }
  return "Online and store";
};

const getInitialStockStatus = ({
  key,
  productSizesStockStatus,
}: {
  key: "onlineStockStatus" | "storeStockStatus";
  productSizesStockStatus: GetProductSizesStockStatusQuery["productSizesStockStatus"];
}) => {
  if (productSizesStockStatus.some((status) => status[key] === "IN_STOCK")) {
    return IN_STOCK;
  }
  if (productSizesStockStatus.some((status) => status[key] === "LOW_STOCK")) {
    return LOW_STOCK;
  }
  return OUT_OF_STOCK;
};

export const getInitialStockLevels = ({
  productSizesStockStatus,
}: {
  productSizesStockStatus: GetProductSizesStockStatusQuery["productSizesStockStatus"];
}) =>
  isEmpty(productSizesStockStatus)
    ? null
    : {
        onlineStockStatus: getInitialStockStatus({
          key: "onlineStockStatus",
          productSizesStockStatus,
        }),
        storeStockStatus: getInitialStockStatus({
          key: "storeStockStatus",
          productSizesStockStatus,
        }),
      };
