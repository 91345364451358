import { FacetTypeEnum } from "@xxl/search-api";
import {
  ADD_SELECTED_DISTINCT_FILTER,
  REMOVE_SELECTED_DISTINCT_FILTER,
  UPDATE_RANGE_FILTER,
} from "../Search/Constants";
import { SET_EXPANDED_TOP_FILTER } from "./FilterState";
import type { Action, Facet, ToggleFilter } from "../Search/SearchState";
import type { Action as FilterAction } from "./FilterState";
import {
  addDistinctFilter,
  removeDistinctFilter,
} from "../Search/SearchStateFilterHelper";
import { UrlPath, isDistinctFacetParameter } from "../Search/UrlPath";
import type { LongTailData } from "@xxl/frontend-api";
import { showStickyHeader } from "../../utils/xxl-toggle-sticky-header";
import { scrollToFilterBar } from "../../utils/xxl-scroll-to-filter-bar";
import type { RangeValue } from "react-app/src/components/Common/RangeSlider/RangeSlider.types";
import type {
  DistinctFacet,
  DistinctFacetParameter,
  Filter,
  RangeFacet,
} from "../../utils/data-types";

export const isAnyFilterSelected = (
  selectedFilters: Filter[],
  attributeName?: string
): boolean => {
  const selectedFilter = selectedFilters.find(
    (filterParam) => filterParam.attributeName === attributeName
  );

  if (selectedFilter === undefined) {
    return false;
  }

  return isDistinctFacetParameter(selectedFilter)
    ? (selectedFilter.selected?.length ?? 0) > 0
    : selectedFilter.selected !== undefined;
};

export const isFilterItemSelected = (
  filters: DistinctFacetParameter[],
  id: string | number | boolean
) =>
  filters
    .flatMap((f) => f.selected)
    .some((f) => f?.toString() === id.toString());

export const isDistinctFacetFilterItemSelected = (
  attributeName: string,
  filters: Filter[],
  id: string | number | boolean
): boolean =>
  isFilterItemSelected(
    filters
      .filter(isDistinctFacetParameter)
      .filter((f) => f.attributeName === attributeName),
    id
  );

export const getDistinctFilterItemHref = (
  baseUrlString: string,
  selectedFilters: Filter[],
  isSelected: boolean,
  attributeName = "",
  value = "",
  longTailFacets?: LongTailData[],
  longTailPattern?: string
): string | undefined => {
  const url = new URL(baseUrlString);
  const filter = {
    attributeName,
    selected: [value],
  } as ToggleFilter;
  const newFilters = isSelected
    ? removeDistinctFilter(selectedFilters, filter)
    : addDistinctFilter(selectedFilters, filter);
  const urlPath = new UrlPath(url, newFilters, longTailFacets, longTailPattern);
  return urlPath.shouldFacetBeIndexed(attributeName)
    ? urlPath.getUpdatedLongTailPath()
    : undefined;
};

export const updateDistinctFilter = (
  attributeName: string,
  dispatch: React.Dispatch<Action>,
  action: "add" | "remove",
  item: string
): void => {
  const type =
    action === "add"
      ? ADD_SELECTED_DISTINCT_FILTER
      : REMOVE_SELECTED_DISTINCT_FILTER;
  dispatch({
    type,
    payload: {
      attributeName,
      selected: [item.toString()],
    },
  });
};

export const updateRangeFilter = (
  attributeName: string,
  dispatch: React.Dispatch<Action>,
  range: RangeValue
): void => {
  const { max, min } = range;
  dispatch({
    type: UPDATE_RANGE_FILTER,
    payload: {
      attributeName,
      range: { max, min },
    },
  });
};

export const isRangeFacet = (facet: Facet): facet is RangeFacet => {
  return facet.type === FacetTypeEnum.range;
};

export const isDistinctFacet = (facet: Facet): facet is DistinctFacet => {
  return facet.type === FacetTypeEnum.distinct;
};

export const closeDropdownFilter = (
  dispatch: React.Dispatch<FilterAction>
): void => {
  dispatch({
    type: SET_EXPANDED_TOP_FILTER,
    payload: "",
  });
};

export const removeStickyFilter = (): void => {
  scrollToFilterBar();
  showStickyHeader();
};

export const getSelectedFiltersCount = (filters: Filter[]): number =>
  filters.map(({ selected }) => selected).flat().length;
