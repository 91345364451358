import React from "react";
import { Wrapper, Size, Checkmark } from "./HeaderProduct.styled";
import { ProductImage } from "../../../components/Product/ProductImage";
import {
  BrandAndName,
  Column,
  CrossSalesProductBrand,
  Row,
} from "../CrossSales.styled";
import { SmallProductPrice } from "../../Cart/Components/SmallProductPrice";
import { colorsAsCssVariable } from "../../../styles/theme/colors";
import type { ProductType } from "@xxl/pim-api";

type HeaderProductProps = {
  product: {
    brand: string;
    imageUrl: string;
    name: string;
    productType?: ProductType;
    salesPrice: number;
    salesPriceFormatted: string;
    size: string;
  };
};

const HeaderProduct = ({ product }: HeaderProductProps) => {
  const {
    brand,
    imageUrl,
    name,
    productType = "NORMAL",
    salesPrice,
    salesPriceFormatted,
    size,
  } = product;
  const productImageData = {
    primaryImage: imageUrl,
    alt: name,
  };
  const productPrice = {
    salesPrice,
    salesPriceFormatted,
  };
  return (
    <Wrapper>
      <ProductImage
        imageUrl={productImageData.primaryImage}
        alt={productImageData.alt}
        size={60}
        addImageParameters={false}
      />
      <Column>
        <Row>
          <BrandAndName>
            <CrossSalesProductBrand>{brand}</CrossSalesProductBrand>
            {name}
          </BrandAndName>
          <SmallProductPrice
            priceDisplay={productPrice}
            productType={productType}
          />
        </Row>
        <Row>
          <Size>{size}</Size>
          <Checkmark fontSize={24} color={colorsAsCssVariable.xxlGreen} />
        </Row>
      </Column>
    </Wrapper>
  );
};

export { HeaderProduct };
