import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { Configuration, ContentApi } from "@xxl/content-api";
import type { TranslationKey } from "react-app/src/translations";
import * as fallbackTranslations from "./fallback.json";
import {
  getContentApiConfiguration,
  getSiteUid,
} from "../environment-variables";

interface TranslationOptions {
  /**
   * The translation key to resolve
   */
  key: TranslationKey;
  /**
   * Fallback value if no string found. Defaults to returning the passed key.
   */
  fallback?: string;
  /**
   * Values to replace {0} and {1} etc. with. Defaults to empty array.
   */
  messageArguments?: string[];
}

const contentApi = new ContentApi(
  new Configuration(getContentApiConfiguration())
);

export const getTranslations = memoize(
  async () => {
    const {
      data: { result },
    } = await contentApi.getTranslations(getSiteUid(), "web");
    return (result ?? []).filter(
      ({ key, value }) => typeof key === "string" && typeof value === "string"
    );
  },
  new Keyv({ namespace: "translations" }),
  {
    ttl: 5 * 60 * 1000,
    staleTtl: 60 * 1000,
  }
);

/**
 * Server-side only translation function
 */
const translateMessage = async (
  baseInput: TranslationOptions | TranslationKey
): Promise<string> => {
  const input = typeof baseInput === "string" ? { key: baseInput } : baseInput;
  const { key, fallback, messageArguments = [] } = input;
  const translation =
    (await getTranslations()).find(
      ({ key: translationKey }) => key === translationKey
    )?.value ??
    fallback ??
    fallbackTranslations[key] ??
    key;
  if (messageArguments.length > 0) {
    return translation.replace(
      /\{(\d+)\}/gu,
      (_, pos: string) => messageArguments[parseInt(pos, 10)] ?? ""
    );
  } else {
    return translation;
  }
};

export { translateMessage };
