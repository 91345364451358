import type { MegaMenuContentData } from "@xxl/content-api";
import type { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import type { XXLAppData } from "../../global";
import type { FooterContent } from "../apis/content-footer-api";
import { getFooterContent } from "../apis/content-footer-api";
import type { HeaderContent } from "../apis/content-header-api";
import { getHeaderContent } from "../apis/content-header-api";
import type { InfoMessage } from "../apis/content-info-messages";
import { getInfoMessage } from "../apis/content-info-messages";
import { getMegaMenuContent } from "../apis/frontend-mega-menu-api";

export type GetServerSidePropsCallback<T, U = undefined> = (
  context: GetServerSidePropsContext,
  props: XXLAppData,
  userKeys: {
    customerKey: string;
    sessionKey: string;
  },
  additionalData?: U
) => Promise<GetServerSidePropsResult<T>>;

export type LayoutProps = {
  footerContent: FooterContent;
  headerContent: HeaderContent;
  infoMessage: InfoMessage | null;
  megaMenuContent: MegaMenuContentData;
};

export const getLayoutProps = async (): Promise<LayoutProps> => {
  const getHeaderContentPromise = getHeaderContent();
  const getFooterContentPromise = getFooterContent();
  const getInfoMessagePromise = getInfoMessage();
  const getMegaMenuContentPromise = getMegaMenuContent();

  const [footerContent, headerContent, infoMessage, megaMenuContent] =
    await Promise.all([
      getFooterContentPromise,
      getHeaderContentPromise,
      getInfoMessagePromise,
      getMegaMenuContentPromise,
    ]);

  return {
    footerContent,
    headerContent,
    infoMessage,
    megaMenuContent,
  };
};
