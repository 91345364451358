import React from "react";
import { Drawer as MuiDrawer, useMediaQuery } from "@mui/material";
import {
  BodyContainer,
  CloseButton,
  DrawerContainer,
  DrawerHeading,
  StickyFooterContainer,
  StickyHeading,
} from "./Drawer.styles";
import { laptopMediaQuery } from "../../utils/xxl-screen";
import { XxlButton } from "../Common/XxlButton/XxlButton";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Icon } from "../Icon/Icon";

const DRAWER_ANCHOR = {
  MOBILE: "bottom",
  DESKTOP: "right",
} as const;

type Props = {
  onClose: () => void;
  isLoading?: boolean;
  heading: string;
  closeButtonText?: string;
  open: boolean;
  children: React.ReactNode;
  closeButton?: boolean;
  customPadding?: string;
};

export const Drawer = ({
  open,
  onClose,
  isLoading = false,
  closeButtonText,
  heading,
  children,
  closeButton = true,
  customPadding,
}: Props) => {
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const { t } = useTranslations();

  const closeButtonLabel = closeButtonText ?? t("sorts.close.button.text");

  const drawerAnchor = isLaptopSize
    ? DRAWER_ANCHOR.DESKTOP
    : DRAWER_ANCHOR.MOBILE;

  return (
    <MuiDrawer anchor={drawerAnchor} open={open} onClose={onClose}>
      <DrawerContainer mobile={!isLaptopSize}>
        <StickyHeading>
          <DrawerHeading>{heading}</DrawerHeading>
          <CloseButton onClick={onClose}>
            <Icon size={34} name="Close" />
          </CloseButton>
        </StickyHeading>
        <BodyContainer customPadding={customPadding}>{children}</BodyContainer>
        {closeButton && (
          <StickyFooterContainer>
            <XxlButton
              loading={isLoading}
              variant="secondary"
              onClick={onClose}
            >
              {closeButtonLabel}
            </XxlButton>
          </StickyFooterContainer>
        )}
      </DrawerContainer>
    </MuiDrawer>
  );
};
