import React from "react";
import type { BaseCampaignData } from "@xxl/frontend-api";
import { CampaignLink, Date, Heading } from "./Campaign.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { getMonthDay } from "../../../utils/xxl-format-date";
import { CampaignImage } from "./CampaignImage";

const CampaignListItem: React.FunctionComponent<{
  campaign: BaseCampaignData;
}> = ({ campaign }) => {
  const { t } = useTranslations();
  const { bannerHeader, bannerImage, toDate, url } = campaign;

  return (
    <li>
      <CampaignLink href={url}>
        <Heading>{t("mega.menu.campaign.header")}</Heading>
        {bannerHeader}
        {bannerImage !== undefined && <CampaignImage imageData={bannerImage} />}
        {toDate !== undefined && (
          <Date>{`${t("mega.menu.campaign.until")} ${getMonthDay(
            toDate
          )}`}</Date>
        )}
      </CampaignLink>
    </li>
  );
};

export { CampaignListItem };
