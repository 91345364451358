import { isEmpty, isNullOrUndefined } from "@xxl/common-utils";
import type { AxiosResponse } from "axios";
import axios from "axios";
import { Buffer } from "buffer";
import Cookies from "js-cookie";
import type { AVAILABILITY } from "../../components/Search/SearchFetchProductsHelper.types";
import type { CartDetailsCookie, GiosgProduct, XXLCookie } from "../../global";
import { windowAccess } from "../Window";
import { xxlCookieInit } from "./XXLCookieInit";
import type {
  ConsentCookie,
  CookieName,
  PreferredStoreIds,
  XXLSessionCookie,
} from "./types";
import { cookieCategories, cookieNames } from "./types";

xxlCookieInit();

type CartCookie = (GiosgProduct & { productCode: string })[];

type PreferredStores = {
  ids: PreferredStoreIds;
  availability: AVAILABILITY[];
};

const MAX_RECENTLY_VIEWED_PRODUCTS = 24;

const DAYS_IN_A_YEAR = 365;

const getCookieRaw = (name: CookieName): string | null =>
  Cookies.get(name) ?? null;

const parseCookie = <T>(
  cookieValue: string | undefined,
  cookieName: string
): T | null => {
  try {
    if (isNullOrUndefined(cookieValue) || isEmpty(cookieValue)) {
      console.warn("Cookie name: ", cookieName);
      console.warn("Cookie value: ", cookieValue);
      return null;
    }
    return JSON.parse(cookieValue.replace(/\\/g, "")) as T;
  } catch (error) {
    console.error("Cookie name: ", cookieName);
    console.error("Cookie value: ", cookieValue);
    console.error("Could not parse cookie.", error);
  }
  return null;
};

const getCookieParsed = <T>(name: CookieName): T | null => {
  const cookie = Cookies.get(name) ?? null;
  if (cookie === null) {
    return null;
  }

  return parseCookie<T>(cookie, name);
};

const serializeCookieFromBase64 = (cookieString: string) =>
  Buffer.from(cookieString, "base64").toString("binary");

const getBase64EncodedSerializedCookie = <T>(name: CookieName): T | null => {
  const unparsedCookie = Cookies.get(name) ?? null;

  if (unparsedCookie === null) {
    return null;
  }

  const serializedCookie = serializeCookieFromBase64(unparsedCookie);
  const cookie = parseCookie<T>(serializedCookie, name);

  if (cookie === null) {
    throw Error(`Could not parse ${name} cookie.`);
  }

  return cookie;
};

const setCookie = (name: CookieName, value: string): string | undefined =>
  Cookies.set(name, value, { expires: DAYS_IN_A_YEAR });

const getUserGroups = (xxlCookieObject: XXLCookie): string =>
  xxlCookieObject.userGroups ?? "";

const getUserId = (xxlCookieObject: XXLCookie): string =>
  xxlCookieObject.loopId ?? "";

let setupXXLCookiePromise: null | Promise<AxiosResponse> = null;
const setupXXLCookie = async (baseUrl?: string) =>
  setupXXLCookiePromise ??
  (setupXXLCookiePromise = axios.get(`${baseUrl ?? ""}/cookie/xxl`));

function isCookieValid(xxlCookie: XXLCookie, cookieVersion?: string) {
  return (
    xxlCookie.cookieVersion ===
    (cookieVersion ?? windowAccess()._sharedData.cookieVersion)
  );
}

const getXXLCookie = async (
  cookieVersion?: string
): Promise<XXLCookie | null> => {
  const existingCookie = getCookieParsed<XXLCookie>(cookieNames.XXL);
  if (existingCookie !== null && isCookieValid(existingCookie, cookieVersion)) {
    return Promise.resolve(existingCookie);
  }

  await setupXXLCookie();
  const newCookie = getCookieRaw(cookieNames.XXL);

  if (newCookie === null) {
    console.error(`Not able to ensure cookie: ${cookieNames.XXL}`);
    if (navigator.cookieEnabled) {
      window.location.reload();
    }
    return Promise.resolve(null);
  }

  return parseCookie<XXLCookie>(newCookie, cookieNames.XXL);
};

const getXXLSessionCookie = (): XXLSessionCookie => {
  const cookie = getCookieRaw(cookieNames.XXL_SESSION);
  return cookie !== null
    ? (JSON.parse(cookie) as XXLSessionCookie)
    : {
        recentlyViewedProducts: [],
      };
};

const getCookieConsentValue = (cookieCategory: string): boolean =>
  window.CookieInformation?.getConsentGivenFor(cookieCategory) ?? false;

const isMarketingCookieEnabled = (): boolean =>
  getCookieConsentValue(cookieCategories.MARKETING);

const addRecentlyViewedProduct = (productId: string): void => {
  if (!isMarketingCookieEnabled()) {
    return;
  }

  const cookie = getXXLSessionCookie();
  if (cookie.recentlyViewedProducts === undefined) {
    cookie.recentlyViewedProducts = [];
  }

  const index = cookie.recentlyViewedProducts.indexOf(productId);
  if (index === -1) {
    cookie.recentlyViewedProducts.unshift(productId);
  } else {
    cookie.recentlyViewedProducts.splice(index, 1);
    cookie.recentlyViewedProducts.unshift(productId);
  }
  if (cookie.recentlyViewedProducts.length > MAX_RECENTLY_VIEWED_PRODUCTS) {
    cookie.recentlyViewedProducts.splice(
      MAX_RECENTLY_VIEWED_PRODUCTS,
      cookie.recentlyViewedProducts.length - MAX_RECENTLY_VIEWED_PRODUCTS
    );
  }
  setCookie(cookieNames.XXL_SESSION, JSON.stringify(cookie));
};

const getCookieInformationConsent = (): ConsentCookie | null =>
  getCookieParsed(cookieNames.COOKIE_INFORMATION_CONSENT);

const getPreferredStoresCookie = (): PreferredStores | null =>
  getCookieParsed(cookieNames.PREFERRED_STORES);

const getCartCookie = (): CartCookie | null =>
  getCookieParsed(cookieNames.CART);

const getEventStreamSessionCookie = (): string | null =>
  getCookieRaw(cookieNames.EVENTSTREAM_SESSION);

const isFunctionalCookieEnabled = (): boolean =>
  getCookieConsentValue(cookieCategories.FUNCTIONAL);

const setPreferredStoresCookie = (preferredStores: PreferredStores): void => {
  if (isFunctionalCookieEnabled()) {
    setCookie(cookieNames.PREFERRED_STORES, JSON.stringify(preferredStores));
  }
};

const getCartDetailsCookie = (): CartDetailsCookie | null =>
  getBase64EncodedSerializedCookie<CartDetailsCookie>(cookieNames.CART_DETAILS);

const getCustomerKey = (): string | null => {
  const xxlCookie = getCookieParsed<XXLCookie>(cookieNames.XXL);
  return xxlCookie?.customerKey ?? null;
};

const getMemberNumber = (): string | null =>
  getCookieParsed<string | null>(cookieNames.MEMBER_NUMBER) ?? null;

const mockedConsentCookie = {
  // for unit testing purposes, move to another file if more appropriate
  consents_approved: Object.values<string>(cookieCategories),
  consents_denied: [],
};

const getSessionKeyCookie = (): string | null => {
  const cookie = getCookieRaw(cookieNames.SESSION_KEY);

  if (typeof cookie !== "string") {
    return null;
  }

  return cookie;
};

export {
  addRecentlyViewedProduct,
  getBase64EncodedSerializedCookie,
  getCartCookie,
  getCartDetailsCookie,
  getCookieInformationConsent,
  getCookieParsed,
  getCookieRaw,
  getCustomerKey,
  getEventStreamSessionCookie,
  getMemberNumber,
  getPreferredStoresCookie,
  getSessionKeyCookie,
  getUserGroups,
  getUserId,
  getXXLCookie,
  getXXLSessionCookie,
  isFunctionalCookieEnabled,
  isMarketingCookieEnabled,
  mockedConsentCookie,
  parseCookie,
  serializeCookieFromBase64,
  setCookie,
  setPreferredStoresCookie,
};

export type { PreferredStores };
