const cartQueryData = /* GraphQL */ `
  fragment CartQueryData on Cart {
    id {
      id
    }
    hasDiscounts
    items {
      accessories {
        article {
          brand {
            name
          }
          category {
            code
          }
          color {
            code
            text
          }
          image {
            path
          }
          name
          path {
            localizedPath
            shortPath
          }
          size {
            code
            text
          }
        }
        articleNumber
        ean
        campaignInformation {
          colorTheme {
            backgroundColor
            foregroundColor
            name
          }
          currencyIso
          invertedPrice
          invertedPriceFormatted
          label
          previousPrice
          previousPriceFormatted
          priceDisclaimer
          priceSplash
          salesPrice
          salesPriceFormatted
          type
          userGroupId
        }
        itemId {
          id
          type
        }
        quantity {
          quantity
          unitOfMeasure
        }
        total
      }
      article {
        brand {
          name
        }
        category {
          code
        }
        color {
          code
          text
        }
        image {
          path
        }
        name
        path {
          localizedPath
          shortPath
        }
        size {
          code
          text
        }
      }
      articleNumber
      ean
      unit
      campaignInformation {
        colorTheme {
          backgroundColor
          foregroundColor
          name
        }
        currencyIso
        invertedPrice
        invertedPriceFormatted
        label
        previousPrice
        previousPriceFormatted
        priceDisclaimer
        priceSplash
        salesPrice
        salesPriceFormatted
        type
        userGroupId
      }
      collectStore {
        warning {
          stock
          type
        }
      }
      itemId {
        id
        type
      }
      quantity {
        quantity
        unitOfMeasure
      }
      printConfigurations {
        ean
        erpLabel
        label
        print
        user
      }
      total
      serviceProducts {
        article {
          brand {
            name
          }
          category {
            code
          }
          color {
            code
            text
          }
          image {
            path
          }
          name
          path {
            localizedPath
            shortPath
          }
          size {
            code
            text
          }
        }
        articleNumber
        ean
        total
        campaignInformation {
          colorTheme {
            backgroundColor
            foregroundColor
            name
          }
          currencyIso
          invertedPrice
          invertedPriceFormatted
          label
          previousPrice
          previousPriceFormatted
          priceDisclaimer
          priceSplash
          salesPrice
          salesPriceFormatted
          type
          userGroupId
        }
        itemId {
          id
          type
        }
        quantity {
          quantity
          unitOfMeasure
        }
      }
      configurationProducts {
        article {
          name
          size {
            text
          }
        }
      }
      bundledProducts {
        article {
          brand {
            name
          }
          category {
            code
            name
          }
          color {
            code
            text
          }
          image {
            path
          }
          name
          path {
            localizedPath
            shortPath
          }
          size {
            code
            text
          }
        }
        articleNumber
        ean
        unit
        campaignInformation {
          colorTheme {
            backgroundColor
            foregroundColor
            name
          }
          currencyIso
          invertedPrice
          invertedPriceFormatted
          label
          previousPrice
          previousPriceFormatted
          priceDisclaimer
          priceSplash
          salesPrice
          salesPriceFormatted
          type
          userGroupId
        }
        itemId {
          id
          type
        }
        quantity {
          quantity
          unitOfMeasure
        }
        printConfigurations {
          ean
          erpLabel
          label
          print
          user
        }
        total
      }
    }
    totals {
      cartDiscountTotal
      cartDiscountTotalFormattedPrice
      itemsCount
      paymentTotals {
        giftCards {
          availableBalance
          number
          usedBalance
          validTo
        }
        totalAmount
        totalFormattedPrice
      }
      salesTotalAmount
      salesTotalFormattedPrice
      salesTotalWithoutDiscountsFormattedPrice
      shippingTotalAmount
      shippingTotalFormattedPrice
      taxTotalAmount
      totalPriceAsInteger
    }
    coupons {
      id
      title
      couponFormattedAmount
      code
    }
    offers {
      active
      amount
      description
      enabled
      expiryDate
      id
      imageUrl
      title
      type
    }
    collectStore {
      store {
        name
      }
      error {
        type
      }
    }
  }
`;

export const cartQuery = /* GraphQL */ `
  query Cart($input: CartInput) {
    cart(input: $input) {
      ...CartQueryData
    }
  }
  ${cartQueryData}
`;

export const updateCartLoyaltyMutation = /* GraphQL */ `
  mutation UpdateCartLoyalty($input: UpdateCartLoyaltyInput) {
    updateCartLoyalty(input: $input) {
      ...CartQueryData
    }
  }
  ${cartQueryData}
`;

export const checkoutSnippetQuery = /* GraphQL */ `
  query CheckoutSnippet($input: CheckoutSnippetInput) {
    checkoutSnippet(input: $input) {
      cartId
      htmlSnippet
      paymentProvider
      customerType
      customerTypeOptions
    }
  }
`;

export const paymentWidgetQuery = /* GraphQL */ `
  query PaymentWidget($input: PaymentWidgetInput!) {
    paymentWidget(input: $input) {
      url
      publicToken
      expiresAt
    }
  }
`;

export const checkoutShipmentUpdateMutation = /* GraphQL */ `
  mutation UpdateCartShipping($input: CartInput) {
    updateCartShipping(input: $input) {
      totals {
        cartDiscountTotal
        cartDiscountTotalFormattedPrice
        itemsCount
        paymentTotals {
          giftCards {
            availableBalance
            number
            usedBalance
            validTo
          }
          totalAmount
          totalFormattedPrice
        }
        salesTotalAmount
        salesTotalFormattedPrice
        salesTotalWithoutDiscountsFormattedPrice
        shippingTotalAmount
        shippingTotalFormattedPrice
        taxTotalAmount
        totalPriceAsInteger
      }
    }
  }
`;

export const checkoutConfirmationQuery = /* GraphQL */ `
  query ConfirmationSnippet($input: ConfirmationSnippetInput!) {
    confirmationSnippet(input: $input) {
      checkoutOrderData {
        checkoutId
        currency
        isPickupAtStoreOrder
        items {
          baseProductName
          brandName
          bundleId
          colorName
          ean
          googleCategory
          quantity
          sizeId
          sizeText
          styleId
          unitPrice
        }
        replacementFor
        totalAmount
        totalTaxAmount
        customer {
          email
          firstName
          lastName
          phone
          country
          postalCode
          city
        }
      }
      htmlSnippet
    }
  }
`;

export const addGiftCardsToCartMutation = /* GraphQL */ `
  mutation AddGiftCardsToCart($input: AddGiftCardsToCartInput!) {
    addGiftCardsToCart(input: $input) {
      totals {
        cartDiscountTotal
        cartDiscountTotalFormattedPrice
        itemsCount
        paymentTotals {
          giftCards {
            availableBalance
            number
            usedBalance
            validTo
          }
          totalAmount
          totalFormattedPrice
        }
        salesTotalAmount
        salesTotalFormattedPrice
        salesTotalWithoutDiscountsFormattedPrice
        shippingTotalAmount
        shippingTotalFormattedPrice
        taxTotalAmount
        totalPriceAsInteger
      }
    }
  }
`;

export const removeGiftCardsFromCartMutation = /* GraphQL */ `
  mutation RemoveGiftCardsFromCart($input: RemoveGiftCardsFromCartInput!) {
    removeGiftCardsFromCart(input: $input) {
      totals {
        cartDiscountTotal
        cartDiscountTotalFormattedPrice
        itemsCount
        paymentTotals {
          giftCards {
            availableBalance
            number
            usedBalance
            validTo
          }
          totalAmount
          totalFormattedPrice
        }
        salesTotalAmount
        salesTotalFormattedPrice
        salesTotalWithoutDiscountsFormattedPrice
        shippingTotalAmount
        shippingTotalFormattedPrice
        taxTotalAmount
        totalPriceAsInteger
      }
    }
  }
`;

export const addAccessoriesToCartMutation = /* GraphQL */ `
  mutation AddAccessoriesToCartItems($input: AddAccessoriesToCartItemsInput!) {
    addAccessoriesToCartItems(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        itemId {
          id
          type
        }
        collectStore {
          warning {
            stock
            type
          }
        }
        ean
      }
    }
  }
`;

export const addToCartMutation = /* GraphQL */ `
  mutation AddProductsToCart($input: AddProductsToCartInput!) {
    addProductsToCart(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        itemId {
          id
          type
        }
        collectStore {
          warning {
            stock
            type
          }
        }
        ean
      }
    }
  }
`;

export const addBundleProductsToCartMutation = /* GraphQL */ `
  mutation AddBundleProductsToCart($input: AddBundleProductsToCartInput!) {
    addBundleProductsToCart(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        article {
          name
        }
        itemId {
          id
          type
        }
        ean
        quantity {
          quantity
          unitOfMeasure
        }
        printConfigurations {
          ean
          erpLabel
          label
          print
          user
        }
        templateId
      }
    }
  }
`;

export const addCollectableProductsToCartMutation = /* GraphQL */ `
  mutation AddCollectableProductsToCart(
    $input: AddCollectableProductsToCartInput!
  ) {
    addCollectableProductsToCart(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        itemId {
          id
          type
        }
        collectStore {
          warning {
            stock
            type
          }
        }
        ean
      }
    }
  }
`;

export const addServiceToEgCartMutation = /* GraphQL */ `
  mutation AddServiceToCart($input: AddServiceProductsToCartItemsInput!) {
    addServiceProductsToCartItems(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        itemId {
          id
          type
        }
        ean
        serviceProducts {
          ean
          itemId {
            id
            type
          }
          quantity {
            quantity
          }
        }
      }
    }
  }
`;

export const addConfigurableProductsToCartMutation = /* GraphQL */ `
  mutation AddConfigurableProductsToCart(
    $input: AddConfigurableProductsToCartInput!
  ) {
    addConfigurableProductsToCart(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        ean
        itemId {
          id
          type
        }
      }
    }
  }
`;

export const toggleOfferInCartMutation = /* GraphQL */ `
  mutation ToggleOfferInCart($input: ToggleOfferInCartInput!) {
    toggleOfferInCart(input: $input) {
      id {
        id
      }
      totals {
        cartDiscountTotal
        cartDiscountTotalFormattedPrice
        itemsCount
        paymentTotals {
          giftCards {
            availableBalance
            number
            usedBalance
            validTo
          }
          totalAmount
          totalFormattedPrice
        }
        salesTotalAmount
        salesTotalFormattedPrice
        salesTotalWithoutDiscountsFormattedPrice
        shippingTotalAmount
        taxTotalAmount
        shippingTotalFormattedPrice
        totalPriceAsInteger
      }
      offers {
        active
        amount
        description
        enabled
        expiryDate
        id
        imageUrl
        title
        type
      }
      hasDiscounts
    }
  }
`;

export const modifyCartProductsQuantity = /* GraphQL */ `
  mutation ModifyCartProducts($input: ModifyCartItemInput!) {
    modifyCartItemsQuantity(input: $input) {
      id {
        id
      }
      totals {
        itemsCount
      }
      items {
        itemId {
          id
          type
        }
        ean
        unit
        serviceProducts {
          ean
          itemId {
            id
            type
          }
        }
      }
    }
  }
`;

export const clearCartIdMutation = /* GraphQL */ `
  mutation ClearCartId($input: ClearCartIdInput!) {
    clearCartId(input: $input) {
      cookieCartId
    }
  }
`;

export const addCouponToCartMutation = /* GraphQL */ `
  mutation AddCouponToCart($input: AddCouponInput!) {
    addCouponToCart(input: $input) {
      id {
        id
      }
      coupons {
        id
        code
        couponFormattedAmount
        title
      }
    }
  }
`;

export const removeCouponFromCartMutation = /* GraphQL */ `
  mutation RemoveCouponFromCart($input: RemoveCouponInput!) {
    removeCouponFromCart(input: $input) {
      id {
        id
      }
      coupons {
        id
        code
        couponFormattedAmount
        title
      }
    }
  }
`;

export const getOnSiteMessagingConfigurationForProductsQuery = /* GraphQL */ `
  query OnSiteMessagingConfigurationForProducts(
    $input: OnSiteMessagingConfigurationForProductsInput!
  ) {
    onSiteMessagingConfigurationForProducts(input: $input) {
      clientId
    }
  }
`;

export const getAccountInfo = /* GraphQL */ `
  query TeamAdminAccountInfo {
    account {
      email
      firstName
      lastName
      mobilePhone
    }
  }
`;

export const createRequisitionMutation = /* GraphQL */ `
  mutation CreateRequisition($input: CreateRequisitionInput!) {
    createRequisition(input: $input) {
      progress
    }
  }
`;
