import { useEffect, useState } from "react";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { CampaignInfo } from "next-js-app/src/components/ProductDetailsPage/types";
import { getCampaignData } from "./useCampaignDataForPdp.helper";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";
import { isNotEmpty } from "@xxl/common-utils";
import type { PriceDisplay } from "@xxl/prices-utils";
import isEmpty from "lodash/isEmpty";
import { logError } from "../../utils/xxl-log";

const useCampaignDataForPdp = ({
  priceDisplay,
}: {
  priceDisplay?: PriceDisplay;
}) => {
  const [campaignData, setCampaignData] = useState<CampaignInfo | null>(null);
  const { siteUid } = useSharedData().data;
  const { contentApi } = useApiClients();
  const { campaignId, colorTheme, label } = priceDisplay ?? {};

  useEffect(() => {
    if (!isNotEmpty(campaignId) || isEmpty(colorTheme) || !isNotEmpty(label)) {
      return;
    }

    const fetchCampaignData = async () => {
      try {
        setCampaignData(null);
        const {
          data: { result = [] },
        } = await contentApi.getCampaignContent(
          legacySiteUidToSiteUid(siteUid),
          campaignId
        );
        if (isEmpty(result)) {
          return;
        }
        const [data] = result;
        const { description, timer, title } = getCampaignData(data);
        const { backgroundColor: background, foregroundColor: foreground } =
          colorTheme;
        setCampaignData({
          colorTheme: {
            background,
            foreground,
          },
          description,
          id: campaignId,
          name: label,
          timer,
          title,
        });
      } catch (error) {
        logError(error);
      }
    };

    void fetchCampaignData();
  }, [campaignId, contentApi, siteUid]);

  return campaignData;
};

export { useCampaignDataForPdp };
