import React, { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import {
  HeadingButton,
  HeadingButtonAnimationWrapper,
  Wrapper,
} from "../Styles/PersonalOffers.styled";
import {
  GiftCardNameTitle,
  InputsWrapper,
  RowWrapper,
  FormWrapper,
  InputWrapper,
  List,
  ListItem,
  ListItemText,
  GiftCardText,
  GiftCardBalanceText,
  RemoveButton,
  ListWrapper,
} from "../Styles/GiftcardPayment.styled";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { CaretIcon } from "../../Common/Icons/Caret";
import { Giftcard, Trash } from "@xxl/icons";
import { Formik, Form, Field, type FieldProps } from "formik";
import * as yup from "yup";
import {
  giftCardPinRE,
  giftCardNumberRE,
} from "../../GiftCardBalanceCheckForm/GiftCardBalanceCheckForm";
import { FormErrorMessage, Input } from "../../../styled";
import { XxlButton } from "../../Common/XxlButton";
import { useSharedData } from "../../../contexts/SharedData";
import type { AddGiftCardsToCartGiftCardDataInput } from "../../../generated/graphql-code-generator";
import { addGiftCardsToCart, removeGiftCardsFromCart } from "../Api/CartAPI";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  CART_CONTENT_EDITING_LOCK,
  CART_CONTENT_EDITING_UNLOCK,
  UPDATE_PAYMENT_VALUES,
  useCartContext,
} from "../CartState";
import { formatOptionalPrice } from "../../../utils/xxl-price-format";
import type { TranslationKey } from "../../../translations";
import { updateCheckoutSnippet } from "../Services/updateCartQuantity";

const GIFT_CARDS_LIMIT = 10; // AX misses more than 10 giftcards added to order

export const GiftcardPayment: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const {
    configuration: {
      amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
    },
    siteCurrency,
  } = useSharedData().data;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showAddAnotherCardButton, setShowAddAnotherCardButton] =
    useState(false);
  const [graphqlErrors, setGraphqlErrors] = useState<
    (string | undefined | null)[]
  >([]);
  const [graphqlRemoveCardErrors, setGraphqlRemoveCardErrors] = useState<
    (string | undefined | null)[]
  >([]);
  const { state, dispatch } = useCartContext();
  const [isInProgress, setIsInProgress] = useState(false);

  const removeErrors = () => {
    setGraphqlErrors([]);
    setGraphqlRemoveCardErrors([]);
  };

  const expandForm = () => {
    setIsExpanded(!isExpanded);
    setIsAnimating(true);
  };

  const submitAddGiftCardToCart = async (
    values: AddGiftCardsToCartGiftCardDataInput,
    setSubmittingFunction: (isSubmitting: boolean) => void
  ) => {
    window.walley?.checkout.api.suspend();
    dispatch({
      type: CART_CONTENT_EDITING_LOCK,
    });
    try {
      setIsInProgress(true);
      removeErrors();
      const response = await addGiftCardsToCart(
        values,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      if (isNotNullOrUndefined(response.data.data)) {
        setShowAddAnotherCardButton(true);
        dispatch({
          type: UPDATE_PAYMENT_VALUES,
          payload: response.data.data.addGiftCardsToCart.totals,
        });
        void updateCheckoutSnippet(
          dispatch,
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey,
          state.customerTypeOptions,
          state.customerType
        );
      } else if (isNotNullOrUndefined(response.data.errors)) {
        const errors = response.data.errors.map((error) =>
          isNotNullOrUndefined(error.errorType)
            ? t(
                `checkout.page.gift.card.error.graphql.add.${error.errorType}` as TranslationKey
              )
            : undefined
        );
        setGraphqlErrors(errors);
      }
    } catch (error) {
      console.error("Cannot submit gift card", error);
    } finally {
      setSubmittingFunction(false);
      setIsInProgress(false);
      window.walley?.checkout.api.resume();
      dispatch({
        type: CART_CONTENT_EDITING_UNLOCK,
      });
    }
  };

  const removeGiftCard = async (number: string) => {
    window.walley?.checkout.api.suspend();
    dispatch({
      type: CART_CONTENT_EDITING_LOCK,
    });
    try {
      setIsInProgress(true);
      removeErrors();
      const response = await removeGiftCardsFromCart(
        number,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      if (isNotNullOrUndefined(response.data.data)) {
        dispatch({
          type: UPDATE_PAYMENT_VALUES,
          payload: response.data.data.removeGiftCardsFromCart.totals,
        });
        void updateCheckoutSnippet(
          dispatch,
          aws_appsync_graphqlEndpoint,
          aws_appsync_apiKey,
          state.customerTypeOptions,
          state.customerType
        );
      } else if (isNotNullOrUndefined(response.data.errors)) {
        const errors = response.data.errors.map((error) =>
          isNotNullOrUndefined(error.errorType)
            ? t(
                `checkout.page.gift.card.error.graphql.remove.${error.errorType}` as TranslationKey
              )
            : undefined
        );
        setGraphqlRemoveCardErrors(errors);
      }
    } catch (error) {
      console.error("Cannot remove card from cart", error);
    } finally {
      setIsInProgress(false);
      window.walley?.checkout.api.resume();
      dispatch({
        type: CART_CONTENT_EDITING_UNLOCK,
      });
    }
  };

  const buyGiftCardsSchema = yup.object({
    number: yup
      .string()
      .matches(
        giftCardNumberRE,
        t("checkout.page.gift.card.error.number.lenght")
      )
      .required(t("checkout.page.gift.card.error.number.required")),
    pin: yup
      .string()
      .matches(giftCardPinRE, t("checkout.page.gift.card.error.pin.lenght"))
      .required(t("checkout.page.gift.card.error.pin.required")),
  });

  useEffect(() => {
    if (state.displayCart?.giftCards !== undefined) {
      setShowAddAnotherCardButton(state.displayCart.giftCards.length > 0);
    }
  }, [state.displayCart]);

  useEffect(() => {
    setIsExpanded(
      state.displayCart?.giftCards !== undefined &&
        state.displayCart.giftCards.length > 0
    );
  }, [state.displayCart?.giftCards]);

  return (
    <Wrapper
      data-testid="walley-giftcard-payment-snippet"
      isLoading={isInProgress || state.isCartContentLocked}
    >
      <HeadingButton type="button" onClick={expandForm}>
        <GiftCardNameTitle>
          <Giftcard />
          {t("checkout.page.gift.card.title.prefix")}&nbsp;
          <span>{t("checkout.page.gift.card.name")}</span>
        </GiftCardNameTitle>
        <CaretIcon direction={isExpanded ? "up" : "down"} />
        <HeadingButtonAnimationWrapper
          isAnimating={isAnimating}
          onAnimationEnd={() => {
            setIsAnimating(false);
          }}
        />
      </HeadingButton>
      <AnimateHeight height={isExpanded ? "auto" : 0}>
        <InputsWrapper>
          {isNotNullOrUndefined(state.displayCart) &&
            state.displayCart.giftCards.length > 0 && (
              <ListWrapper>
                <List>
                  {state.displayCart.giftCards.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText>
                        <GiftCardText>
                          {t("checkout.page.gift.card.name")}:&nbsp;
                          <strong>{item.number}</strong>
                        </GiftCardText>
                        <GiftCardBalanceText>
                          {t("checkout.page.gift.card.balance.used")}{" "}
                          {formatOptionalPrice(item.usedBalance, siteCurrency)}/
                          {formatOptionalPrice(
                            item.availableBalance,
                            siteCurrency
                          )}
                        </GiftCardBalanceText>
                      </ListItemText>
                      <RemoveButton
                        type="button"
                        onClick={() => {
                          void removeGiftCard(item.number);
                        }}
                        aria-label={t("checkout.page.gift.card.remove.button")}
                      >
                        <Trash />
                      </RemoveButton>
                    </ListItem>
                  ))}
                </List>
                {graphqlRemoveCardErrors.map((error, index) =>
                  isNotNullOrUndefined(error) ? (
                    <FormErrorMessage key={index}>{error}</FormErrorMessage>
                  ) : null
                )}
              </ListWrapper>
            )}
          {isNotNullOrUndefined(state.displayCart) &&
            state.displayCart.giftCards.length < GIFT_CARDS_LIMIT && (
              <>
                {showAddAnotherCardButton ? (
                  <XxlButton
                    type="button"
                    onClick={() => setShowAddAnotherCardButton(false)}
                    variant="outlined"
                    icon="Plus"
                    size="small"
                  >
                    {t("checkout.page.gift.card.add.another.card.button")}
                  </XxlButton>
                ) : (
                  <Formik
                    key="add-gift-card-payment"
                    initialValues={{
                      number: "",
                      pin: "",
                    }}
                    onSubmit={(values, actions) => {
                      buyGiftCardsSchema
                        .validate(values)
                        .then((valid) => {
                          return void submitAddGiftCardToCart(
                            valid,
                            actions.setSubmitting
                          );
                        })
                        .catch((error) => {
                          console.error("Schema is not valid", error);
                        });
                    }}
                    validateOnBlur={true}
                    validationSchema={buyGiftCardsSchema}
                  >
                    {({ isValid, isSubmitting }) => {
                      return (
                        <Form>
                          <FormWrapper>
                            <RowWrapper>
                              <label htmlFor="number">
                                {t("checkout.page.gift.card.number.label")}
                              </label>
                              <Field name="number">
                                {
                                  (({ field, meta }) => (
                                    <>
                                      <Input
                                        type="string"
                                        id="number"
                                        placeholder={t(
                                          "checkout.page.gift.card.number.placeholder"
                                        )}
                                        {...field}
                                        required={true}
                                        onFocus={() => {
                                          if (graphqlErrors.length > 0) {
                                            removeErrors();
                                          }
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {meta.touched && Boolean(meta.error)
                                          ? meta.error
                                          : null}
                                      </FormErrorMessage>
                                      {graphqlErrors.map((error, index) =>
                                        isNotNullOrUndefined(error) ? (
                                          <FormErrorMessage key={index}>
                                            {error}
                                          </FormErrorMessage>
                                        ) : null
                                      )}
                                    </>
                                  )) as (props: FieldProps) => React.ReactNode
                                }
                              </Field>
                            </RowWrapper>
                            <RowWrapper isColumn={false}>
                              <Field name="pin">
                                {
                                  (({ field, meta }) => (
                                    <InputWrapper>
                                      <Input
                                        type="string"
                                        id="pin"
                                        placeholder={t(
                                          "checkout.page.gift.card.pin.placeholder"
                                        )}
                                        {...field}
                                        style={{
                                          height: "50px",
                                          width: "100%",
                                          boxSizing: "border-box",
                                        }}
                                        onFocus={() => {
                                          if (graphqlErrors.length > 0) {
                                            removeErrors();
                                          }
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {meta.touched && Boolean(meta.error)
                                          ? meta.error
                                          : null}
                                      </FormErrorMessage>
                                    </InputWrapper>
                                  )) as (props: FieldProps) => React.ReactNode
                                }
                              </Field>
                              <XxlButton
                                type="submit"
                                variant="secondary"
                                disabled={!isValid}
                                loading={isSubmitting}
                                size="small"
                                style={{
                                  width: "auto",
                                  minWidth: "150px",
                                }}
                              >
                                {t("checkout.page.gift.card.confirm.button")}
                              </XxlButton>
                            </RowWrapper>
                          </FormWrapper>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </>
            )}
        </InputsWrapper>
      </AnimateHeight>
    </Wrapper>
  );
};
