export const cookieCategories = {
  NECESSARY: "cookie_cat_necessary",
  MARKETING: "cookie_cat_marketing",
  FUNCTIONAL: "cookie_cat_functional",
  STATISTIC: "cookie_cat_statistic",
  UNCLASSIFIED: "cookie_cat_unclassified",
} as const;
export type CookieCategory =
  (typeof cookieCategories)[keyof typeof cookieCategories];

export type ConsentCookie = {
  consents_approved?: CookieCategory[];
  consents_rejected?: CookieCategory[];
};

export const cookieNames = {
  CART_DETAILS: "cartDetails",
  CART_ID: "cartId",
  CART: "xxlcart",
  COOKIE_INFORMATION_CONSENT: "CookieInformationConsent",
  EVENTSTREAM_SESSION: "eventstream-session",
  FBC: "_fbc",
  FBP: "_fbp",
  MEMBER_NUMBER: "memberNumber",
  PREFERRED_STORES: "xxl-preferred-stores",
  SESSION_KEY: "sessionKey",
  XXL_AUTH: "xxl_auth",
  XXL_SESSION: "xxl-session",
  XXL_NUMBER_OF_COLUMNS_PER_DEVICE_ON_PLP:
    "xxl-number-of-columns-per-device-on-plp",
  XXL: "xxl",
} as const;
export type CookieName = (typeof cookieNames)[keyof typeof cookieNames];

export type XXLSessionCookie = {
  recentlyViewedProducts?: string[];
};

export type PreferredStoreIds = string[];
