export const scrollToFilterBar = (): void => {
  const SCROLL_OFFSET = 100;

  if (typeof window !== "undefined") {
    const filterBarContainer = document.getElementById(
      "js-filter-bar-container"
    );
    const filterBarContainerPosition =
      filterBarContainer !== null
        ? filterBarContainer.offsetTop - SCROLL_OFFSET
        : 0;

    window.scrollTo({
      top: filterBarContainerPosition,
      behavior: "smooth",
    });
  }
};
