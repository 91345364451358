import { Text } from "react-app/src/components/Text";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { SlideLink, StyledImage } from "./ProductStyleSelector.styles";
import type { Style } from "./types";
import { type MouseEvent, useState, useEffect } from "react";
import { IMAGE_SIZE } from "../constants";
import { Slider } from "react-app/src/components/Common/Slider/Slider";
import { useBreakpoint } from "react-app/src/hooks/useBreakpoint/useBreakpoint";
import { prepareSliderStyles } from "../../../utils/product-style-selector-slider-styles";

const MAX_STYLE_SLIDER_LIMIT = 6;

type Props = {
  onClick: ({
    code,
    event,
  }: {
    code: string;
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => void;
  styles: Style[];
};

type SliderStyle = {
  perView: number;
  spacing: number;
};

export const ProductStyleSelectorComponent = ({ onClick, styles }: Props) => {
  const styleItems = styles.map(
    ({
      code,
      primaryImage,
      name,
      colourDataName,
      pk,
      isInStock,
      url,
      selected = false,
    }) => (
      <SlideLink
        key={pk}
        disabled={!isInStock}
        href={url}
        onClick={(event) => onClick({ code, event })}
      >
        <StyledImage
          src={primaryImage}
          height={IMAGE_SIZE.STYLE}
          width={IMAGE_SIZE.STYLE}
          alt={`${colourDataName}-${name} `}
          priority={true}
          selected={selected}
        />
        <Text size="tiny">{colourDataName}</Text>
      </SlideLink>
    )
  );
  const breakpoint = useBreakpoint();
  const [sliderStyle, setSliderStyle] = useState<SliderStyle | undefined>();

  useEffect(() => {
    setSliderStyle(prepareSliderStyles(breakpoint));
  }, [breakpoint]);

  const shouldUseSlider = styles.length > MAX_STYLE_SLIDER_LIMIT;

  if (shouldUseSlider) {
    return <Slider items={styleItems} slidesConfig={sliderStyle} />;
  }

  return (
    <XxlStack direction="row" gap="10px">
      {styleItems}
    </XxlStack>
  );
};
