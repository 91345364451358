import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  BadgesData,
  BaseProductData,
  CampaignBadgeData,
  ImageData,
  PriceInfoData,
  PriceTranslationKeyType,
  ProductData,
  USPData,
  VariantData,
} from "@xxl/product-search-api";
import type { BadgeType, ProductType, StockStatus } from "..//data-types";

type StyleOption = {
  primaryImageURL?: string;
  url?: string;
};

type PriceDataBase = {
  value: number;
  valueFormatted: string;
};

export type PriceData =
  | (PriceDataBase & {
      labelType: "TranslatedLabel";
      label: string;
    })
  | (PriceDataBase & {
      labelType: "TranslationKey";
      translationKey: PriceTranslationKeyType;
    })
  | (PriceDataBase & {
      labelType: "None";
    });

type ProductCardBase = {
  badges: BadgeType[];
  code: string;
  name: string;
  primaryImage: string;
  productImages: string[];
  styleOptions: StyleOption[];
  ticket: string;
  url: string;
  variant: ProductType;
  stockStatus: StockStatus;
  averageRating?: number;
  brandName?: string;
};

export type ProductCardDataV2 = ProductCardBase & {
  version: 2;
  campaignHighlightedLabel?: CampaignBadgeData;
  campaignRibbon?: CampaignBadgeData;
  price: { selling: PriceData; alternate?: PriceData };
  selectedStoreStockStatus: StockStatus;
  usps?: USPData[];
};

type StockStatusType = {
  stockStatus: StockStatus;
  selectedStoreStockStatus: StockStatus;
};

const statusReduceFunction = (
  prevStatus: StockStatus,
  { stockStatus }: { stockStatus: StockStatus }
): StockStatus =>
  stockStatus === "INSTOCK" || prevStatus === "INSTOCK"
    ? "INSTOCK"
    : stockStatus === "LOWSTOCK" || prevStatus === "LOWSTOCK"
      ? "LOWSTOCK"
      : "OUTOFSTOCK";

export const getStockStatus = (variants: VariantData[]): StockStatusType =>
  variants.reduce(
    (status, variant) => {
      if (hasValue(variant.availability)) {
        return {
          stockStatus: variant.availability
            .filter(({ channel }) => channel === "ONLINE")
            .reduce(statusReduceFunction, status.stockStatus),
          selectedStoreStockStatus: variant.availability
            .filter(({ channel }) => channel === "STORE")
            .reduce(statusReduceFunction, status.selectedStoreStockStatus),
        };
      }
      const fallbackStatus = statusReduceFunction(status.stockStatus, {
        stockStatus: variant.stockStatus,
      });
      return {
        stockStatus: fallbackStatus,
        selectedStoreStockStatus: fallbackStatus,
      };
    },
    {
      stockStatus: "OUTOFSTOCK",
      selectedStoreStockStatus: "OUTOFSTOCK",
    } as StockStatusType
  );

export const getPrimaryImageUrl = (
  images: ImageData[],
  productCode: string
) => {
  const primaryImage = images.find(({ tags }) => tags.includes("primary"));

  if (primaryImage === undefined) {
    console.error(
      `Product with code ${productCode} is missing a primary image.`
    );
    return "/not-found"; // fallback to non existing route to avoid calling existing base route.
  }

  const [firstAvailable] = primaryImage.sources;

  return firstAvailable.url;
};

export const getSecondaryImageUrl = (
  images: ImageData[],
  productCode: string
) => {
  const secondaryImage = images.find(({ tags }) => tags.includes("secondary"));

  if (secondaryImage === undefined) {
    console.error(
      `Product with code ${productCode} is missing a secondary image.`
    );
    return getPrimaryImageUrl(images, productCode);
  }

  const [firstAvailable] = secondaryImage.sources;

  return firstAvailable.url;
};

const getBadgeTypeForAttribute = (attribute: string): BadgeType | null => {
  switch (attribute) {
    case "NEW":
      return "NEW";
    case "custom.automatedAlmostSoldOut":
    case "ALMOST_SOLD_OUT":
      return "ALMOST_SOLD_OUT";
    case "custom.automatedBestSeller":
    case "BEST_SELLER":
      return "BEST_SELLER";
    case "custom.automatedPopular":
    case "POPULAR":
      return "POPULAR";
    case "custom.automatedPresale":
    case "PRESALE":
      return "PRESALE";
    case "custom.automatedReward":
    case "REWARD":
      return "REWARD";
    case "custom.automatedRecommends":
    case "XXL_RECOMMENDS":
      return "XXL_RECOMMENDS";
    case "custom.automatedOnlyOnline":
      return "ONLINE";
    case "custom.automatedOnlyInStore":
      return "STORE";
    default:
      return null;
  }
};

// eslint-disable-next-line import/no-unused-modules
export const getAutomatedBadges = (
  badges: BadgesData | undefined
): BadgeType[] => {
  if (badges === undefined) {
    return [];
  }
  const automatedBadges = Object.values(badges)
    .flat()
    .filter(({ variant }) => variant === "LABEL")
    .map(({ attribute }) => getBadgeTypeForAttribute(attribute))
    .filter(isNotNullOrUndefined);
  return automatedBadges;
};

const getCampaignRibbon = (
  badges: BadgesData | undefined
): CampaignBadgeData | null => {
  if (badges === undefined) {
    return null;
  }
  const campaignRibbon = Object.values(badges)
    .flat()
    .find(({ attribute }) => attribute === "custom.campaignRibbon") as
    | CampaignBadgeData
    | undefined;
  return campaignRibbon ?? null;
};

const getCampaignHighlightedLabel = (
  badges: BadgesData | undefined
): CampaignBadgeData | null => {
  if (badges === undefined) {
    return null;
  }
  const campaignRibbon = Object.values(badges)
    .flat()
    .find(({ attribute }) => attribute === "custom.campaignMessage") as
    | CampaignBadgeData
    | undefined;
  return campaignRibbon ?? null;
};

const toStyleOptions = ({
  code,
  imageInfo,
  url,
}: ProductData): StyleOption => ({
  primaryImageURL: getPrimaryImageUrl(imageInfo.images, code),
  url,
});

const getPriceData = (price: PriceInfoData): PriceData => {
  const { labelType, range } = price;

  const baseData = {
    value: range.min.value,
    valueFormatted: range.min.formatted,
  };

  if (labelType === "TranslatedLabel") {
    return {
      ...baseData,
      ...{
        label: price.label,
        labelType,
      },
    };
  }

  if (labelType === "TranslationKey") {
    return {
      ...baseData,
      ...{
        translationKey: price.translationKey,
        labelType,
      },
    };
  }

  return {
    ...baseData,
    ...{
      labelType: "None",
    },
  };
};

const toProductCardDataFromProduct = (
  product: ProductData,
  styleOptions: StyleOption[]
): ProductCardDataV2 => {
  const {
    badges,
    brand,
    code,
    imageInfo: { images },
    price,
    rating,
    ticket,
    title,
    url,
    usps,
    variants,
  } = product;

  const { alternate, selling } = price;

  const { stockStatus, selectedStoreStockStatus } = getStockStatus(variants);
  const primaryImage = getPrimaryImageUrl(images, code);
  const secondaryImage = getSecondaryImageUrl(images, code);
  const automatedBadges = getAutomatedBadges(badges);
  const campaignRibbon = getCampaignRibbon(badges);
  const campaignHighlightedLabel = getCampaignHighlightedLabel(badges);

  return {
    ...(rating !== undefined && { averageRating: rating }),
    badges: automatedBadges,
    ...(brand !== undefined && { brandName: brand.name }),
    ...(campaignHighlightedLabel !== null && { campaignHighlightedLabel }),
    ...(campaignRibbon !== null && { campaignRibbon }),
    code,
    name: title,
    price: {
      selling: getPriceData(selling),
      ...(isNotNullOrUndefined(alternate) && {
        alternate: getPriceData(alternate),
      }),
    },
    primaryImage,
    productImages: [primaryImage, secondaryImage],
    selectedStoreStockStatus,
    stockStatus,
    styleOptions,
    ticket,
    url,
    usps,
    variant: "NORMAL", // todo
    version: 2,
  };
};

export const toProductCardDataFromBase = (
  baseProduct: BaseProductData
): ProductCardDataV2 => {
  const { products } = baseProduct;
  const [primaryProduct] = products;
  const shouldIncludeStyleOptions = products.length > 1;
  const styleOptions = shouldIncludeStyleOptions
    ? products.map(toStyleOptions)
    : [];

  return toProductCardDataFromProduct(primaryProduct, styleOptions);
};

const getUnifiedQueryString = (a: string) =>
  decodeURIComponent(a.slice(a.indexOf("?") + 1));
// @param a - path with query string
// @param b - path with query string
export const areQueryParamsTheSame = (a: string, b: string) =>
  getUnifiedQueryString(a) === getUnifiedQueryString(b);

const getCategoryPathPart = (a: string) =>
  a.includes(".") ? a.slice(0, a.indexOf(".")) : a;
// @param a - next.js path
// @param a - path
export const isCategoryTheSame = (a: string, b: string) => {
  return getCategoryPathPart(a) === getCategoryPathPart(b);
};
