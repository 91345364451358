import { getAccount } from "../../components/UserDetails/UserAPI";
import type { Customer } from "@xxl/deliverystream-api";
import { logError } from "../xxl-log";

export const getCustomerDetails = async (
  isLoggedIn: boolean,
  graphQLEndpointUrl: string
): Promise<Customer> => {
  if (!isLoggedIn) {
    return {};
  }

  try {
    const { data } = await getAccount(graphQLEndpointUrl);
    return {
      email: data?.account?.email,
      firstName: data?.account?.firstName,
      lastName: data?.account?.lastName,
      phoneNumber: data?.account?.mobilePhone,
      memberNumber: data?.account?.memberNumber ?? undefined,
    };
  } catch (error) {
    logError("Could not fetch customer details", error);
    return {};
  }
};
