import { isEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import type { ToggleCookie } from "react-app/src/components/XXLDynamicToggle/types";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import type { CartDetailsCookie } from "react-app/src/global";
import {
  parseCookie,
  serializeCookieFromBase64,
} from "react-app/src/utils/Cookie";
import {
  COMPANY_NAME_KEY,
  DEFAULT_COMPANY_NAME,
  XXL_CART_DETAILS_COOKIE_NAME,
  XXL_TOGGLE_COOKIE_NAME,
} from "./constants";
import type {
  IncomingMessageWithCookies,
  ParsedCartDetailsCookie,
} from "./types";
import type { GetServerSidePropsContext } from "next";

export const cleanNextDataUrl = (url: string) => {
  const nextDataRegex = /\/_next\/data\/[^/]+\/(.*?)(\.json)?$/;

  const match = nextDataRegex.exec(url);
  if (isNotNullOrUndefined(match) && isNotNullOrUndefined(match[1])) {
    return `/${match[1].replace(".json", "")}`;
  }

  return url;
};

export type queryParamType = string | string[] | undefined;

export const urlParameterIsString = (
  urlParameter: queryParamType
): urlParameter is string => {
  return !Array.isArray(urlParameter) && urlParameter !== undefined;
};

export const getXXLToggleCookieFromRequest = (
  req: IncomingMessageWithCookies
): ToggleCookie | null => {
  const unparsedCookie = req.cookies[XXL_TOGGLE_COOKIE_NAME];
  if (unparsedCookie === undefined) {
    return null;
  }

  const serializedCookie = serializeCookieFromBase64(unparsedCookie);
  const cookie = parseCookie<ToggleCookie>(
    serializedCookie,
    XXL_TOGGLE_COOKIE_NAME
  );

  if (cookie === null) {
    throw Error(`Could not parse ${XXL_TOGGLE_COOKIE_NAME} cookie.`);
  }

  return cookie;
};

// eslint-disable-next-line import/no-unused-modules
export const getCartDetailsCookieFromRequest = (
  req: IncomingMessageWithCookies
): ParsedCartDetailsCookie | null => {
  const unparsedCookie = req.cookies[XXL_CART_DETAILS_COOKIE_NAME];

  if (unparsedCookie === undefined) {
    return null;
  }

  const serializedCookie = serializeCookieFromBase64(unparsedCookie);
  const cookie = parseCookie<CartDetailsCookie>(
    serializedCookie,
    XXL_CART_DETAILS_COOKIE_NAME
  );

  if (cookie === null) {
    throw Error(`Could not parse ${XXL_CART_DETAILS_COOKIE_NAME} cookie.`);
  }

  return cookie;
};

/**
 * Check if the current environment is running as a localhost setup.
 *
 * @param host
 * @returns true if running on localhost environment, otherwise false */
export const isLocalhost = (host: string) => host.startsWith("localhost");

/**
 * Returns full page title
 *
 * @param {string} title - first part of full title, placed before "|"
 * @param {Translate} t - translate function
 * @returns {string} full page title, including company title part after pipe "|" */
export const getFullPageTitle = (title: string, t: Translate) => {
  const translatedTitle = t(COMPANY_NAME_KEY);
  const companyTitle = isEmpty(translatedTitle)
    ? DEFAULT_COMPANY_NAME
    : translatedTitle;
  return `${title} | ${companyTitle}`;
};

export const createCanonicalLink = (
  request: GetServerSidePropsContext["req"]
) => {
  const {
    headers: { host },
    url,
  } = request;

  if (host === undefined || url === undefined) {
    console.error(
      `Could not create canonical link. Host: ${typeof host}. URL: ${typeof url}`
    );
  }

  try {
    const { hostname, pathname } = new URL(`https://${host ?? ""}${url ?? ""}`);
    return `https://${hostname}${pathname}`;
  } catch (error) {
    console.error({
      error,
      message: `Could not create canonical link. Host: ${typeof host}. URL: ${typeof url}`,
    });
    return "";
  }
};

export const isInternalNextRequest = (requestUrl: string) =>
  requestUrl.includes("_next");
