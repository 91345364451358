import { useEffect, useState } from "react";
import type { FrontendProductData } from "./useProductData.types";
import { useApiClients } from "../../contexts/ApiClients";
import { useSharedData } from "../../contexts/SharedData";
import { dispatchEvent } from "../../utils/xxl-event";
import { xxlTheme } from "../../styles/xxl-theme";
import { useSessionSource } from "../../contexts/Session";
import { getPriceDisplay } from "@xxl/prices-utils";
import { createFrontendPriceDisplays } from "next-js-app/src/components/ProductDetailsPage/ProductDetailsPage.helper";
import { isNotEmpty } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { getFrontendPriceDisplayData } from "../../utils/PriceDisplay/price-display";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import {
  getIsPurchaseAllowed,
  isProductFrom3rdParty,
} from "./useProductData.helper";
import { useRelinkValue } from "react-relink";
import { logError } from "../../utils/xxl-log";

const {
  colors: { xxlWebBlack, xxlWhite },
} = xxlTheme;

const useProductData = ({
  onError = noop,
  productCode,
  shouldEvaluateProductConfiguratorPrice,
}: {
  onError?: () => void;
  productCode: string;
  shouldEvaluateProductConfiguratorPrice: boolean;
}) => {
  const { t } = useTranslations();
  const [productData, setProductData] = useState<FrontendProductData | null>(
    null
  );
  const { siteUid } = useSharedData().data;
  const { pimApi } = useApiClients();
  const isLoggedIn = useRelinkValue(useSessionSource);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setProductData(null);
        const { data: optionalData } = await pimApi.getProducts(
          siteUid,
          productCode
        );
        if (optionalData.length === 0) {
          throw Error("Empty data from PIM api");
        }
        const [{ baseProductCode = "", sizeOptions = [], ...data }] =
          optionalData;
        const updatedDataWithFixForBadgesBeingNull = {
          ...data,
          badges: data.badges ?? undefined,
        };
        const { priceDisplays, salesMethod } = data;
        if (!isNotEmpty(priceDisplays)) {
          throw Error("Empty price displays in useProductData hook");
        }
        const priceDisplay = getPriceDisplay({
          isLoggedIn,
          priceDisplays: createFrontendPriceDisplays(priceDisplays),
        });
        if (isEmpty(priceDisplay)) {
          throw Error("Empty price display in useProductData hook");
        }
        const frontendPriceDisplay = getFrontendPriceDisplayData({
          isLoggedIn,
          priceDisplay,
          shouldEvaluateProductConfiguratorPrice,
          siteUid,
          t,
        });
        const [categoryCode] =
          data.breadcrumbs
            ?.map(({ code }) => code ?? "")
            .reverse()
            .filter(Boolean) ?? [];
        if (isEmpty(categoryCode)) {
          throw Error("Empty category code in useProductData hook");
        }
        if (isEmpty(baseProductCode)) {
          throw Error("Empty baseProductCode in useProductData");
        }
        const [productId] = baseProductCode.split("_");
        const isPurchaseAllowed = getIsPurchaseAllowed(data);
        setProductData({
          ...updatedDataWithFixForBadgesBeingNull,
          baseProductCode,
          categoryCode,
          frontendPriceDisplay,
          isProductFrom3rdParty: isProductFrom3rdParty(salesMethod),
          isPurchaseAllowed,
          priceDisplay,
          productId,
          sizeOptions,
        });
      } catch (error) {
        logError(error);
        dispatchEvent("xxl-show-message", {
          msgs: [
            {
              background: xxlWebBlack,
              textColor: xxlWhite,
              key: "general.error",
            },
          ],
        });
        onError();
      }
    };
    void fetchProductData();
  }, [
    isLoggedIn,
    onError,
    pimApi,
    productCode,
    shouldEvaluateProductConfiguratorPrice,
    siteUid,
    t,
  ]);

  return productData;
};

export { useProductData };
