export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
  AWSTimestamp: any;
};

export type AcceptReturnInput = {
  acceptReturnBody?: InputMaybe<AcceptReturnInputBody>;
  packageNumber?: InputMaybe<Scalars["String"]>;
};

export type AcceptReturnInputBody = {
  employeeId?: InputMaybe<Scalars["String"]>;
  lines?: InputMaybe<Array<InputMaybe<AcceptReturnInputBodyLine>>>;
};

export type AcceptReturnInputBodyLine = {
  acceptedReturnQuantity?: InputMaybe<Scalars["Int"]>;
  ean?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<Scalars["String"]>;
};

export type AccountsInput = {
  email: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  marketId: Scalars["String"];
};

export type AddAccessoriesToCartItemInput = {
  ean: Scalars["String"];
  parentId: CartItemIdInput;
  quantity: AddProductsToCartQuantityItemInput;
  templateId?: InputMaybe<Scalars["String"]>;
};

export type AddAccessoriesToCartItemsDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddAccessoriesToCartItemInput>;
};

export type AddAccessoriesToCartItemsInput = {
  cart: AddAccessoriesToCartItemsDataInput;
};

export type AddBundleProductsToCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddBundleProductsToCartItemInput>;
};

export type AddBundleProductsToCartInput = {
  cart: AddBundleProductsToCartDataInput;
};

export type AddBundleProductsToCartItemInput = {
  bundledProducts: Array<AddBundledProductsToCartItemInput>;
  configurations: Array<AddConfigurationsToCartItemInput>;
  ean?: InputMaybe<Scalars["String"]>;
  templateId?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
};

export type AddBundledProductsToCartItemInput = {
  ean: Scalars["String"];
  printConfigurations?: InputMaybe<Array<PrintConfigurationInput>>;
  quantity: Scalars["Int"];
};

export type AddCollectableProductsToCartDataInput = {
  collectStore: CollectStoreInput;
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddProductsToCartItemInput>;
};

export type AddCollectableProductsToCartInput = {
  cart: AddCollectableProductsToCartDataInput;
};

export type AddConfigurableProductsToCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddConfigurableProductsToCartItemInput>;
};

export type AddConfigurableProductsToCartInput = {
  cart: AddConfigurableProductsToCartDataInput;
};

export type AddConfigurableProductsToCartItemInput = {
  configurations: Array<AddConfigurationsToCartItemInput>;
  ean: Scalars["String"];
};

export type AddConfigurationsToCartItemInput = {
  ean: Scalars["String"];
};

export type AddCouponCodeInput = {
  couponCode: Scalars["String"];
};

export type AddCouponInput = {
  cart: AddCouponToCartDataInput;
};

export type AddCouponToCartDataInput = {
  coupons: Array<AddCouponCodeInput>;
  id?: InputMaybe<CartIdentificationInput>;
};

export type AddGiftCardsToCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  totals: AddGiftCardsToCartTotalsDataInput;
};

export type AddGiftCardsToCartGiftCardDataInput = {
  number: Scalars["String"];
  pin: Scalars["String"];
};

export type AddGiftCardsToCartInput = {
  cart: AddGiftCardsToCartDataInput;
};

export type AddGiftCardsToCartPaymentTotalsDataInput = {
  giftCards: Array<AddGiftCardsToCartGiftCardDataInput>;
};

export type AddGiftCardsToCartTotalsDataInput = {
  paymentTotals: AddGiftCardsToCartPaymentTotalsDataInput;
};

export type AddProductsToCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddProductsToCartItemInput>;
};

export type AddProductsToCartInput = {
  cart: AddProductsToCartDataInput;
};

export type AddProductsToCartItemInput = {
  ean: Scalars["String"];
  quantity: AddProductsToCartQuantityItemInput;
  unit?: InputMaybe<Scalars["String"]>;
};

export type AddProductsToCartQuantityItemInput = {
  quantity: Scalars["Int"];
};

export type AddServiceProductsToCartItemInput = {
  ean: Scalars["String"];
  parentId: CartItemIdInput;
  quantity: AddProductsToCartQuantityItemInput;
  templateId?: InputMaybe<Scalars["String"]>;
};

export type AddServiceProductsToCartItemsDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  items: Array<AddServiceProductsToCartItemInput>;
};

export type AddServiceProductsToCartItemsInput = {
  cart: AddServiceProductsToCartItemsDataInput;
};

export type AggregatedRatingFilter = {
  articleNumber: StringFilterInput;
};

export type AnonymousEncryptedPurchaseViewInput = {
  encryptedOrderId: Scalars["String"];
  market: Market;
};

export type AnonymousPurchaseInput = {
  code: Scalars["String"];
  email: Scalars["String"];
};

export type ApproveReviewInput = {
  reviewId: Scalars["String"];
};

export type AssignClubAdminRoleInput = {
  clubId: Scalars["String"];
  email: Scalars["String"];
};

export type BonusPointTransactionsInput = {
  count?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type BundlesConfigurationInput = {
  bundleIds: Array<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type CartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
};

export type CartIdentificationInput = {
  id: Scalars["String"];
  revision?: InputMaybe<Scalars["Int"]>;
};

export type CartInput = {
  cart?: InputMaybe<CartDataInput>;
  forceRefresh?: InputMaybe<Scalars["Boolean"]>;
};

export type CartItemIdInput = {
  id: Scalars["Int"];
  type: CartItemType;
};

export const CartItemType = {
  ACCESSORY: "ACCESSORY",
  BUNDLE: "BUNDLE",
  PRINT: "PRINT",
  SERVICE: "SERVICE",
  SINGLE: "SINGLE",
} as const;

export type CartItemType = (typeof CartItemType)[keyof typeof CartItemType];
export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  username: Scalars["String"];
};

export const ChangePasswordStatus = {
  ERROR: "ERROR",
  NOT_FOUND: "NOT_FOUND",
  SUCCESS: "SUCCESS",
} as const;

export type ChangePasswordStatus =
  (typeof ChangePasswordStatus)[keyof typeof ChangePasswordStatus];
export type CheckoutSnippetInput = {
  allowExternalPaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  cartId?: InputMaybe<CartIdentificationInput>;
  customerType?: InputMaybe<CustomerType>;
  origin?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  replacementFor?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export type CircuitBreakerInput = {
  circuitState?: InputMaybe<CircuitBreakerState>;
  id?: InputMaybe<Scalars["String"]>;
};

export const CircuitBreakerState = {
  CLOSED: "CLOSED",
  HALF: "HALF",
  OPEN: "OPEN",
} as const;

export type CircuitBreakerState =
  (typeof CircuitBreakerState)[keyof typeof CircuitBreakerState];
export type ClearCartIdCartDataInput = {
  id: CartIdentificationInput;
};

export type ClearCartIdInput = {
  cart: ClearCartIdCartDataInput;
};

export type ClubInput = {
  clubId?: InputMaybe<Scalars["String"]>;
};

export type ClubsByCustomerInput = {
  cacheKey?: InputMaybe<Scalars["AWSTimestamp"]>;
  itemType?: InputMaybe<Scalars["String"]>;
};

export type ClubsInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type CollectStoreDataInput = {
  id: Scalars["String"];
};

export type CollectStoreInput = {
  store: CollectStoreDataInput;
};

export const CollectStoreWarningType = {
  CNC_DISABLED: "CNC_DISABLED",
  LOCAL_OUT_OF_STOCK: "LOCAL_OUT_OF_STOCK",
  LOCAL_PARTIAL_STOCK: "LOCAL_PARTIAL_STOCK",
} as const;

export type CollectStoreWarningType =
  (typeof CollectStoreWarningType)[keyof typeof CollectStoreWarningType];
export type CollectableStockInput = {
  ean: Scalars["String"];
};

export const ConfiguratorType = {
  PRODUCT: "PRODUCT",
} as const;

export type ConfiguratorType =
  (typeof ConfiguratorType)[keyof typeof ConfiguratorType];
export type ConfirmationSnippetInput = {
  cartId: Scalars["String"];
  paymentProvider: PaymentProvider;
};

export const ConsentSource = {
  ACCOUNT: "ACCOUNT",
  APP: "APP",
  CHECKOUT: "CHECKOUT",
  SIGN_UP: "SIGN_UP",
} as const;

export type ConsentSource = (typeof ConsentSource)[keyof typeof ConsentSource];
export type ContactInfo = {
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
};

export const Country = {
  AT: "AT",
  DK: "DK",
  FI: "FI",
  NO: "NO",
  SE: "SE",
} as const;

export type Country = (typeof Country)[keyof typeof Country];
export type CreateAccountInput = {
  acceptsEmail?: InputMaybe<Scalars["Boolean"]>;
  acceptsSms?: InputMaybe<Scalars["Boolean"]>;
  birthDay?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  consent: ConsentSource;
  email: Scalars["String"];
  firstName: Scalars["String"];
  gender?: InputMaybe<Gender>;
  lastName: Scalars["String"];
  mobilePhone: Scalars["String"];
  password: Scalars["String"];
  primaryClubId?: InputMaybe<Scalars["String"]>;
  socialSecurityNumber?: InputMaybe<Scalars["String"]>;
  storeId?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type CreateClubInput = {
  clubId: Scalars["String"];
  enabled?: InputMaybe<Scalars["Boolean"]>;
  erpId: Scalars["String"];
  marketId: Scalars["String"];
  name: Scalars["String"];
  vatId: Scalars["String"];
};

export type CreateRequisitionInput = {
  email: Scalars["String"];
  firstname: Scalars["String"];
  invoice: Scalars["String"];
  lastname: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
};

export type CreateReturnInput = {
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  createReturnBody?: InputMaybe<CreateReturnInputBody>;
  numberOfLabels?: InputMaybe<Scalars["Int"]>;
  orderId?: InputMaybe<Scalars["String"]>;
  salesChannel?: InputMaybe<SalesChannel>;
  siteId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type CreateReturnInputBody = {
  currency?: InputMaybe<Currency>;
  email?: InputMaybe<Scalars["String"]>;
  lines: Array<CreateReturnInputBodyLine>;
  memberNumber?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  replacementLines?: InputMaybe<Array<ReplacementLine>>;
  storeId?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["Float"]>;
};

export type CreateReturnInputBodyLine = {
  acceptedReturnQuantity?: InputMaybe<Scalars["Int"]>;
  bundleNumber?: InputMaybe<Scalars["Float"]>;
  comment?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<Currency>;
  ean?: InputMaybe<Scalars["String"]>;
  insuranceId?: InputMaybe<Scalars["String"]>;
  orderEntry?: InputMaybe<Scalars["String"]>;
  productCode?: InputMaybe<Scalars["String"]>;
  productImage?: InputMaybe<Scalars["String"]>;
  productName?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<CreateReturnInputReason>;
  returnedQuantity?: InputMaybe<Scalars["Int"]>;
  serialNumber?: InputMaybe<Scalars["String"]>;
  translatedComment?: InputMaybe<
    Array<InputMaybe<CreateReturnTranslatedComment>>
  >;
  value?: InputMaybe<Scalars["Float"]>;
};

export type CreateReturnInputReason = {
  allowComment?: InputMaybe<Scalars["Boolean"]>;
  code?: InputMaybe<Scalars["String"]>;
  commentLabel?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  minimumCommentLength?: InputMaybe<Scalars["Int"]>;
};

export type CreateReturnTranslatedComment = {
  comment?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
};

export type CreateTeamInput = {
  name: Scalars["String"];
};

export type CreateXrsCartInput = {
  bundleEntries?: InputMaybe<Array<XrsAddToCartBundleEntryInput>>;
  cartId?: InputMaybe<Scalars["String"]>;
  entries?: InputMaybe<Array<XrsAddToCartEntryInput>>;
  market: Market;
  userId?: InputMaybe<Scalars["String"]>;
};

export const Currency = {
  DKK: "DKK",
  EUR: "EUR",
  NOK: "NOK",
  SEK: "SEK",
} as const;

export type Currency = (typeof Currency)[keyof typeof Currency];
export const CustomerType = {
  ORGANIZATION: "ORGANIZATION",
  PERSON: "PERSON",
} as const;

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];
export type EditClubInput = {
  clubId: Scalars["String"];
  enabled?: InputMaybe<Scalars["Boolean"]>;
  erpId?: InputMaybe<Scalars["String"]>;
  marketId: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  vatId?: InputMaybe<Scalars["String"]>;
};

export type EmployeesInput = {
  market: Market;
  query: Scalars["String"];
};

export type ExpertReviewInput = {
  articleNumber: Scalars["String"];
  siteId: Scalars["String"];
};

export type FetchAllProductsInput = {
  count?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
  siteId: Scalars["String"];
};

export const Gender = {
  FEMALE: "FEMALE",
  MALE: "MALE",
  OTHER: "OTHER",
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];
export type GetCheckoutSnippetByOrderDataInput = {
  allowExternalPaymentMethods: Scalars["Boolean"];
  orderBase64JSON: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  site: Scalars["String"];
};

export type GetContactInput = {
  phoneNumber: Scalars["String"];
  site: Scalars["String"];
};

export type GetReplacementInput = {
  allowExternalPaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  customerType?: InputMaybe<CustomerType>;
  email?: InputMaybe<Scalars["String"]>;
  entries: Array<ReplacementEntry>;
  orderCode: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export type GetVoyadoContactInput = {
  site: Scalars["String"];
  uuid: Scalars["String"];
};

export type GiftCardEntry = {
  cardValue: Scalars["Float"];
  quantity: Scalars["Int"];
};

export const GroupEntrySummaryType = {
  BUNDLE: "BUNDLE",
  SERVICED: "SERVICED",
  SINGLE: "SINGLE",
  TEAM_BUNDLE: "TEAM_BUNDLE",
} as const;

export type GroupEntrySummaryType =
  (typeof GroupEntrySummaryType)[keyof typeof GroupEntrySummaryType];
export type InStoreReturnInput = {
  acceptBody?: InputMaybe<AcceptReturnInputBody>;
  returnBody?: InputMaybe<CreateReturnInput>;
};

export type InStoreReturnsInput = {
  bodies: Array<InStoreReturnInput>;
  customer?: InputMaybe<OrderCustomerInput>;
  employeeSignature: Scalars["String"];
  storeReceiptNumber?: InputMaybe<Scalars["String"]>;
  transactionId?: InputMaybe<Scalars["ID"]>;
};

export type InitHppSessionInput = {
  cartId: Scalars["String"];
  contactInfo: ContactInfo;
  enableFreeFreight?: InputMaybe<Scalars["Boolean"]>;
  market: Market;
  source: Scalars["String"];
  userId?: InputMaybe<Scalars["String"]>;
};

export type InitiateGiftCardPurchaseInput = {
  allowExternalPaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  customerType?: InputMaybe<CustomerType>;
  email?: InputMaybe<Scalars["String"]>;
  entries: Array<GiftCardEntry>;
  origin?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export type InitiateOTPChallengeInput = {
  phoneNumber: Scalars["String"];
  reCaptchaV3Token: Scalars["String"];
  site: Scalars["String"];
};

export type MarkReviewHelpfulInput = {
  reviewId: Scalars["String"];
  sessionId: Scalars["String"];
};

export type MarkReviewUnhelpfulInput = {
  reviewId: Scalars["String"];
  sessionId: Scalars["String"];
};

export const Market = {
  AT: "AT",
  DK: "DK",
  FI: "FI",
  NO: "NO",
  SE: "SE",
} as const;

export type Market = (typeof Market)[keyof typeof Market];
export const MemberRewardType = {
  BONUSCHECK: "BONUSCHECK",
  PERSONALOFFER: "PERSONALOFFER",
} as const;

export type MemberRewardType =
  (typeof MemberRewardType)[keyof typeof MemberRewardType];
export const MemberRewardValueType = {
  EXTERNALOFFER: "EXTERNALOFFER",
  MANUAL: "MANUAL",
  MONEY: "MONEY",
  PERCENT: "PERCENT",
  SWIPE: "SWIPE",
} as const;

export type MemberRewardValueType =
  (typeof MemberRewardValueType)[keyof typeof MemberRewardValueType];
export type ModifyCartArticleItemQuantityInput = {
  quantity: Scalars["Int"];
};

export type ModifyCartItemInput = {
  cart: ModifyProductsInCartDataInput;
};

export type ModifyProductsInCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  item: ModifyProductsInCartItemInput;
};

export type ModifyProductsInCartItemInput = {
  ean?: InputMaybe<Scalars["String"]>;
  itemId: CartItemIdInput;
  quantity: ModifyCartArticleItemQuantityInput;
  unit?: InputMaybe<Scalars["String"]>;
};

export type NestedPaginationInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type OnSiteMessagingConfigurationForProductInput = {
  articleNumber?: InputMaybe<Scalars["String"]>;
  site: Scalars["String"];
};

export type OnSiteMessagingConfigurationForProductsInput = {
  articleNumbers: Array<Scalars["String"]>;
  site: Scalars["String"];
};

export type OrderAuditByCheckoutIdInput = {
  checkoutId: Scalars["String"];
  count?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type OrderAuditByEmailInput = {
  count?: InputMaybe<Scalars["Int"]>;
  email: Scalars["String"];
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type OrderAuditByOrderCodeInput = {
  code: Scalars["String"];
  count?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
};

export const OrderAuditEventType = {
  ACKNOWLEDGED: "ACKNOWLEDGED",
  CONVERTED: "CONVERTED",
  EXPORTED: "EXPORTED",
  PUSH: "PUSH",
  SAVED: "SAVED",
  SENT: "SENT",
  UPDATED_REFS: "UPDATED_REFS",
  VALIDATION: "VALIDATION",
  VOUCHERS_REDEEMED: "VOUCHERS_REDEEMED",
  VOYADO_RESOLVED: "VOYADO_RESOLVED",
} as const;

export type OrderAuditEventType =
  (typeof OrderAuditEventType)[keyof typeof OrderAuditEventType];
export type OrderAuditInput = {
  checkoutId: Scalars["String"];
  eventType: Scalars["String"];
};

export type OrderByCheckoutIdInput = {
  checkoutId: Scalars["String"];
};

export type OrderByEcomOrderCodeInput = {
  ecomOrderCode?: InputMaybe<Scalars["String"]>;
};

export type OrderCustomerInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars["String"]>;
  erpCustomerIdentifier?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
  street?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type OrderInput = {
  email?: InputMaybe<Scalars["String"]>;
  orderId: Scalars["String"];
  siteId: Scalars["String"];
};

export type OrderInputV2 = {
  market?: InputMaybe<Market>;
  orderId?: InputMaybe<Scalars["String"]>;
};

export const OrderLineType = {
  ADMINISTRATION: "ADMINISTRATION",
  PRODUCT: "PRODUCT",
  PURCHASE: "PURCHASE",
  RETURN: "RETURN",
} as const;

export type OrderLineType = (typeof OrderLineType)[keyof typeof OrderLineType];
export const OrderStatus = {
  DELIVERED: "DELIVERED",
  INVOICED: "INVOICED",
  OPEN: "OPEN",
  OTHER: "OTHER",
  OUT_OF_STOCK: "OUT_OF_STOCK",
  PACKED: "PACKED",
  READY_PICKUP: "READY_PICKUP",
  RETURNED: "RETURNED",
  RETURN_INITIATED: "RETURN_INITIATED",
  RETURN_RECEIVED: "RETURN_RECEIVED",
} as const;

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];
export type OrdersByEmailInput = {
  email?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
  scanIndexForward?: InputMaybe<Scalars["Boolean"]>;
};

export const PaymentProvider = {
  KLARNA: "KLARNA",
  WALLEY: "WALLEY",
} as const;

export type PaymentProvider =
  (typeof PaymentProvider)[keyof typeof PaymentProvider];
export type PaymentWidgetInput = {
  site: Scalars["String"];
};

export const PriceType = {
  CAMPAIGN: "CAMPAIGN",
  ONLY_AVAILABLE_ONLINE: "ONLY_AVAILABLE_ONLINE",
  PREVIOUS_PRICE: "PREVIOUS_PRICE",
  PREVIOUS_PRICE_BUNDLE_SAVING: "PREVIOUS_PRICE_BUNDLE_SAVING",
  PROMOTION: "PROMOTION",
  REGULAR: "REGULAR",
} as const;

export type PriceType = (typeof PriceType)[keyof typeof PriceType];
export type PrintConfigurationInput = {
  ean?: InputMaybe<Scalars["String"]>;
  erpLabel?: InputMaybe<Scalars["String"]>;
  label?: InputMaybe<Scalars["String"]>;
  print?: InputMaybe<Scalars["String"]>;
  user: Scalars["String"];
};

export type ProductConfiguratorPriceChangesInput = {
  csvBase64: Scalars["String"];
  csvDelimiter?: InputMaybe<Scalars["String"]>;
};

export type ProductDeliveryMethodsInput = {
  code: Scalars["String"];
  quantity: Scalars["Int"];
};

export type ProductSizesStockStatusInput = {
  productCode: Scalars["String"];
  storeId?: InputMaybe<Scalars["String"]>;
};

export const ProductStatus = {
  DELIVERED: "DELIVERED",
  OPEN: "OPEN",
  OTHER: "OTHER",
  OUT_OF_STOCK: "OUT_OF_STOCK",
  PACKED: "PACKED",
  READY_PICKUP: "READY_PICKUP",
  RETURNED: "RETURNED",
  RETURN_INITIATED: "RETURN_INITIATED",
  RETURN_RECEIVED: "RETURN_RECEIVED",
} as const;

export type ProductStatus = (typeof ProductStatus)[keyof typeof ProductStatus];
export type ProductsByIdsInput = {
  productIds: Array<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type ProductsDeliveryMethodsInput = {
  filterPickUpAtStore: Scalars["Boolean"];
  postalCode?: InputMaybe<Scalars["String"]>;
  products: Array<ProductDeliveryMethodsInput>;
  site: Scalars["String"];
};

export type PurchaseInput = {
  code?: InputMaybe<Scalars["String"]>;
};

export const PurchaseReturnStatus = {
  ACCEPTED: "ACCEPTED",
  ARRIVED_XXL: "ARRIVED_XXL",
  CREATED: "CREATED",
  IN_TRANSIT: "IN_TRANSIT",
  PARTIALLY_ACCEPTED: "PARTIALLY_ACCEPTED",
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
} as const;

export type PurchaseReturnStatus =
  (typeof PurchaseReturnStatus)[keyof typeof PurchaseReturnStatus];
export const PurchaseSalesChannel = {
  APP: "APP",
  AX: "AX",
  ECOM: "ECOM",
  KIOSK: "KIOSK",
  POS: "POS",
  XRS_EA: "XRS_EA",
} as const;

export type PurchaseSalesChannel =
  (typeof PurchaseSalesChannel)[keyof typeof PurchaseSalesChannel];
export const PurchaseShippingStatus = {
  CANCELLED: "CANCELLED",
  DELIVERED: "DELIVERED",
  DELIVERED_POSTOFFICE: "DELIVERED_POSTOFFICE",
  DELIVERED_XXL_STORE: "DELIVERED_XXL_STORE",
  ORDER_RECEIVED: "ORDER_RECEIVED",
  PACKED: "PACKED",
  PICKED: "PICKED",
  SHIPPED: "SHIPPED",
} as const;

export type PurchaseShippingStatus =
  (typeof PurchaseShippingStatus)[keyof typeof PurchaseShippingStatus];
export type PurchasesInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type PutTeamMemberInput = {
  clubId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["String"];
  firstname: Scalars["String"];
  initials: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  playerNumber: Scalars["String"];
  surname: Scalars["String"];
  teamId: Scalars["String"];
};

export const Rating = {
  FIVE: "FIVE",
  FOUR: "FOUR",
  ONE: "ONE",
  THREE: "THREE",
  TWO: "TWO",
} as const;

export type Rating = (typeof Rating)[keyof typeof Rating];
export type RatingQueryInput = {
  articleNumber: Scalars["String"];
};

export type RatingsQueryInput = {
  count?: InputMaybe<Scalars["Int"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type ReceiptInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type RecommendedScanProductsInput = {
  categoryCode?: InputMaybe<Scalars["String"]>;
  scanId: Scalars["String"];
  userGroupIds?: InputMaybe<Scalars["String"]>;
};

export type RejectRequisitionInput = {
  rejectionCause: RequisitionRejectionCause;
  rejectionMessage?: InputMaybe<Scalars["String"]>;
  requisitionId: Scalars["String"];
};

export type RejectReturnInput = {
  packageNumber?: InputMaybe<Scalars["String"]>;
};

export type RejectReviewInput = {
  reviewId: Scalars["String"];
};

export type ReleaseRequisitionInput = {
  requisitionId: Scalars["String"];
};

export type RemoveCouponCodeInput = {
  cartItemIdentificator: Scalars["Int"];
};

export type RemoveCouponFromCartDataInput = {
  coupons: Array<RemoveCouponCodeInput>;
  id?: InputMaybe<CartIdentificationInput>;
};

export type RemoveCouponInput = {
  cart: RemoveCouponFromCartDataInput;
};

export type RemoveGiftCardsFromCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  totals: RemoveGiftCardsFromCartTotalsDataInput;
};

export type RemoveGiftCardsFromCartGiftCardDataInput = {
  number: Scalars["String"];
};

export type RemoveGiftCardsFromCartInput = {
  cart: RemoveGiftCardsFromCartDataInput;
};

export type RemoveGiftCardsFromCartPaymentTotalsDataInput = {
  giftCards: Array<RemoveGiftCardsFromCartGiftCardDataInput>;
};

export type RemoveGiftCardsFromCartTotalsDataInput = {
  paymentTotals: RemoveGiftCardsFromCartPaymentTotalsDataInput;
};

export type RemoveTeamInput = {
  teamId: Scalars["String"];
};

export type RemoveTeamMemberInput = {
  configurationId: Scalars["String"];
  customerId: Scalars["String"];
  teamId: Scalars["String"];
};

export type RenameTeamInput = {
  newName: Scalars["String"];
  teamId: Scalars["String"];
};

export type ReplacementEntry = {
  ean?: InputMaybe<Scalars["String"]>;
  quantity: Scalars["Int"];
  replacedEan?: InputMaybe<Scalars["String"]>;
};

export type ReplacementLine = {
  brand?: InputMaybe<Scalars["String"]>;
  bundleNumber?: InputMaybe<Scalars["Float"]>;
  color?: InputMaybe<Scalars["String"]>;
  defaultURL: Scalars["String"];
  ean: Scalars["String"];
  insuranceId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  pricePerUnit: Scalars["Float"];
  productCode: Scalars["String"];
  productImage: Scalars["String"];
  quantity: Scalars["Int"];
  reasonCode?: InputMaybe<Scalars["String"]>;
  serialNumber?: InputMaybe<Scalars["String"]>;
  size: Scalars["String"];
};

export type RequestClubCreationInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  memberCount: Scalars["Int"];
  message: Scalars["String"];
  organisation: Scalars["String"];
  phoneNumber: Scalars["String"];
  siteId: Scalars["String"];
  sport: Scalars["String"];
};

export type RequestPrefilledRegistrationLinkInput = {
  phoneNumber: Scalars["String"];
  siteId?: InputMaybe<Scalars["String"]>;
};

export type RequisitionAddToCartInput = {
  requisitionId: Scalars["String"];
};

export const RequisitionAddToCartStatus = {
  ADDED: "ADDED",
  FAILED: "FAILED",
  FAILED_PARTIALLY: "FAILED_PARTIALLY",
  NONE: "NONE",
} as const;

export type RequisitionAddToCartStatus =
  (typeof RequisitionAddToCartStatus)[keyof typeof RequisitionAddToCartStatus];
export type RequisitionInput = {
  requisitionId: Scalars["String"];
};

export const RequisitionItemStatus = {
  APPROVED: "APPROVED",
  CREATED: "CREATED",
  PROCESSED: "PROCESSED",
  PROCESSING: "PROCESSING",
  PROCESSING_FAILED: "PROCESSING_FAILED",
  PROCESSING_FAILED_PARTIALLY: "PROCESSING_FAILED_PARTIALLY",
  REJECTED: "REJECTED",
} as const;

export type RequisitionItemStatus =
  (typeof RequisitionItemStatus)[keyof typeof RequisitionItemStatus];
export const RequisitionRejectionCause = {
  FAILED_TO_ADD_TO_CART: "FAILED_TO_ADD_TO_CART",
  REJECTED_BY_ADMIN: "REJECTED_BY_ADMIN",
  REJECTED_BY_TIMEOUT: "REJECTED_BY_TIMEOUT",
} as const;

export type RequisitionRejectionCause =
  (typeof RequisitionRejectionCause)[keyof typeof RequisitionRejectionCause];
export type RequisitionsInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type ResetEmployeePasswordInput = {
  employeeId: Scalars["String"];
};

export const ResetEmployeePasswordResultEnum = {
  ACCEPTED: "ACCEPTED",
} as const;

export type ResetEmployeePasswordResultEnum =
  (typeof ResetEmployeePasswordResultEnum)[keyof typeof ResetEmployeePasswordResultEnum];
export type RespondToOTPChallengeInput = {
  oneTimePassword: Scalars["String"];
  phoneNumber: Scalars["String"];
  session: Scalars["String"];
  site: Scalars["String"];
};

export type ReturnByPackageNumberInput = {
  packageNumber?: InputMaybe<Scalars["String"]>;
};

export const ReturnChannel = {
  APP: "APP",
  ECOM: "ECOM",
  XRS: "XRS",
} as const;

export type ReturnChannel = (typeof ReturnChannel)[keyof typeof ReturnChannel];
export const ReturnReasonType = {
  EXCHANGE: "EXCHANGE",
  RETURN: "RETURN",
} as const;

export type ReturnReasonType =
  (typeof ReturnReasonType)[keyof typeof ReturnReasonType];
export type ReturnReasonsForCategoryInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  siteid?: InputMaybe<Scalars["String"]>;
};

export type ReturnReasonsForProductInput = {
  channel?: InputMaybe<ReturnChannel>;
  ean?: InputMaybe<Scalars["String"]>;
  site?: InputMaybe<Scalars["String"]>;
};

export const ReviewOrderBy = {
  CREATION_DATE: "CREATION_DATE",
  RATING: "RATING",
  RELEVANCE: "RELEVANCE",
} as const;

export type ReviewOrderBy = (typeof ReviewOrderBy)[keyof typeof ReviewOrderBy];
export type ReviewOrderByInput = {
  ascending?: InputMaybe<Scalars["Boolean"]>;
  field?: InputMaybe<ReviewOrderBy>;
};

export const ReviewStatus = {
  ANALYZED: "ANALYZED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
  VALIDATED: "VALIDATED",
} as const;

export type ReviewStatus = (typeof ReviewStatus)[keyof typeof ReviewStatus];
export type ReviewsFilter = {
  articleNumber?: InputMaybe<StringFilterInput>;
};

export const RewardsTransactionType = {
  ADJUSTMENT: "ADJUSTMENT",
  BONUSBALANCEADJUSTMENT: "BONUSBALANCEADJUSTMENT",
  BONUSCHECK: "BONUSCHECK",
  BONUSPROMOTION: "BONUSPROMOTION",
  BONUSPROMOTIONRETURN: "BONUSPROMOTIONRETURN",
  DUEDATE: "DUEDATE",
  FROMAUTOMATION: "FROMAUTOMATION",
  PURCHASE: "PURCHASE",
  PURCHASEREDUCTION: "PURCHASEREDUCTION",
  PURCHASEWITHPOINTS: "PURCHASEWITHPOINTS",
  RECRUITMENT: "RECRUITMENT",
  RETURN: "RETURN",
  STARTBONUS: "STARTBONUS",
} as const;

export type RewardsTransactionType =
  (typeof RewardsTransactionType)[keyof typeof RewardsTransactionType];
export const SalesChannel = {
  APP: "APP",
  AX: "AX",
  ECOM: "ECOM",
  KIOSK: "KIOSK",
  POS: "POS",
  XRS_EA: "XRS_EA",
} as const;

export type SalesChannel = (typeof SalesChannel)[keyof typeof SalesChannel];
export type SalesOrderInput = {
  currency: Scalars["String"];
  customer?: InputMaybe<OrderCustomerInput>;
  employeeId?: InputMaybe<Scalars["String"]>;
  employeeSignature?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Market>;
  notes?: InputMaybe<Scalars["String"]>;
  salesOrderLines?: InputMaybe<Array<InputMaybe<SalesOrderLineInput>>>;
  storeId?: InputMaybe<Scalars["String"]>;
  weaponCard?: InputMaybe<WeaponCardInput>;
};

export type SalesOrderLineInput = {
  bundleId?: InputMaybe<Scalars["Int"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  ean?: InputMaybe<Scalars["String"]>;
  insuranceId?: InputMaybe<Scalars["String"]>;
  productCode?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  reasonCode?: InputMaybe<Scalars["String"]>;
  serialNumber?: InputMaybe<Scalars["String"]>;
  unitPrice?: InputMaybe<Scalars["Float"]>;
};

export type SearchOpenOrdersFilter = {
  workShopView?: InputMaybe<Scalars["Boolean"]>;
};

export type SearchOpenOrdersInput = {
  filter?: InputMaybe<SearchOpenOrdersFilter>;
  query?: InputMaybe<Scalars["String"]>;
  salesChannel?: InputMaybe<SalesChannel>;
  storeId: Scalars["String"];
};

export type SearchOrdersInput = {
  query: Scalars["String"];
  salesChannel?: InputMaybe<SalesChannel>;
};

export type SearchProductsInput = {
  provider: SearchProvider;
  query: Scalars["String"];
  siteId: Scalars["String"];
  skip?: InputMaybe<Scalars["Int"]>;
  take?: InputMaybe<Scalars["Int"]>;
};

export const SearchProvider = {
  LOOP: "LOOP",
  SOLR: "SOLR",
} as const;

export type SearchProvider =
  (typeof SearchProvider)[keyof typeof SearchProvider];
export type SearchReceiptsInput = {
  market?: InputMaybe<Market>;
  query: Scalars["String"];
  salesChannel?: InputMaybe<SalesChannel>;
};

export type SearchReturnInput = {
  searchPhrase?: InputMaybe<Scalars["String"]>;
};

export const Sentiment = {
  MIXED: "MIXED",
  NEGATIVE: "NEGATIVE",
  NEUTRAL: "NEUTRAL",
  POSITIVE: "POSITIVE",
} as const;

export type Sentiment = (typeof Sentiment)[keyof typeof Sentiment];
export const ShootingEquipment = {
  AMMUNITION: "AMMUNITION",
  NO: "NO",
  WEAPON: "WEAPON",
} as const;

export type ShootingEquipment =
  (typeof ShootingEquipment)[keyof typeof ShootingEquipment];
export const SizeFitness = {
  BIGGER_FIT: "BIGGER_FIT",
  SMALLER_FIT: "SMALLER_FIT",
  TRUE_TO_SIZE: "TRUE_TO_SIZE",
} as const;

export type SizeFitness = (typeof SizeFitness)[keyof typeof SizeFitness];
export const SourceType = {
  ECOM: "ECOM",
  ERP: "ERP",
  KLARNA: "KLARNA",
  RETAIL_UNITY: "RETAIL_UNITY",
  VOYADO: "VOYADO",
} as const;

export type SourceType = (typeof SourceType)[keyof typeof SourceType];
export type StockControlDataInput = {
  storeId: Scalars["String"];
};

export const StockStatus = {
  IN_STOCK: "IN_STOCK",
  LOW_STOCK: "LOW_STOCK",
  OUT_OF_STOCK: "OUT_OF_STOCK",
} as const;

export type StockStatus = (typeof StockStatus)[keyof typeof StockStatus];
export type StoreAgentsInput = {
  activate?: InputMaybe<Scalars["Boolean"]>;
  disableStores?: InputMaybe<Array<Scalars["String"]>>;
  listKey: Scalars["String"];
  site: Scalars["String"];
};

export type StringFilterInput = {
  eq?: InputMaybe<Scalars["String"]>;
};

export type SubmitReviewMutationInput = {
  articleNumber: Scalars["String"];
  displayName: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  headline: Scalars["String"];
  isArticleRecommended: Scalars["Boolean"];
  rating: Scalars["Int"];
  sizeFitness?: InputMaybe<SizeFitness>;
  sourceSite?: InputMaybe<Scalars["String"]>;
  text: Scalars["String"];
};

export type SubscriptionStatusInput = {
  acceptsEmail: Scalars["Boolean"];
  acceptsSms: Scalars["Boolean"];
};

export type SwitchTeamAdminRoleInput = {
  customerId: Scalars["String"];
  teamId: Scalars["String"];
};

export type TeamInput = {
  clubId?: InputMaybe<Scalars["String"]>;
  teamId: Scalars["String"];
};

export type TeamPrincipalInput = {
  cacheKey?: InputMaybe<Scalars["AWSTimestamp"]>;
  clubId: Scalars["String"];
  configurationId: Scalars["String"];
  customerId: Scalars["String"];
  teamId: Scalars["String"];
};

export type TeamPrincipalsInput = {
  pagination?: InputMaybe<PaginationInput>;
  teamId: Scalars["String"];
};

export type TeamsInput = {
  pagination?: InputMaybe<PaginationInput>;
};

export type ToggleOfferInCartDataInput = {
  id?: InputMaybe<CartIdentificationInput>;
  offer: ToggleOfferInCartOfferInput;
};

export type ToggleOfferInCartInput = {
  cart: ToggleOfferInCartDataInput;
};

export type ToggleOfferInCartOfferInput = {
  active: Scalars["Boolean"];
  id: Scalars["Int"];
};

export type UnassignClubAdminRoleInput = {
  clubId: Scalars["String"];
  customerId: Scalars["String"];
  marketId: Scalars["String"];
};

export type UnmarkReviewHelpfulnessInput = {
  reviewId: Scalars["String"];
  sessionId: Scalars["String"];
};

export type UpdateAccountInput = {
  acceptsEmail?: InputMaybe<Scalars["Boolean"]>;
  acceptsSms?: InputMaybe<Scalars["Boolean"]>;
  birthDay?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  consent?: InputMaybe<ConsentSource>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  primaryClubId?: InputMaybe<Scalars["String"]>;
  socialSecurityNumber?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateCartLoyaltyInput = {
  cart?: InputMaybe<CartDataInput>;
};

export type UpdateConsentInput = {
  consent: ConsentSource;
};

export const UpdateConsentResultEnum = {
  GENERAL_ERROR: "GENERAL_ERROR",
  SUCCESS: "SUCCESS",
} as const;

export type UpdateConsentResultEnum =
  (typeof UpdateConsentResultEnum)[keyof typeof UpdateConsentResultEnum];
export type UpdateContactConsentInput = {
  source?: InputMaybe<Scalars["String"]>;
};

export type UpdateContactInput = {
  consent?: InputMaybe<UpdateContactConsentInput>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
};

export type UpdateEmailInput = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type UpdateReturnInput = {
  comment?: InputMaybe<Scalars["String"]>;
  packageNumber?: InputMaybe<Scalars["String"]>;
};

export type UpdateSocialSecurityNumberInput = {
  socialSecurityNumber: Scalars["String"];
};

export const UpdateSocialSecurityNumberResultEnum = {
  ALREADY_IN_USE: "ALREADY_IN_USE",
  GENERAL_ERROR: "GENERAL_ERROR",
  SSN_INVALID: "SSN_INVALID",
  SSN_INVALID_AGE_TOO_LOW: "SSN_INVALID_AGE_TOO_LOW",
  SUCCESS: "SUCCESS",
  USER_NOT_FOUND_IN_ECOM: "USER_NOT_FOUND_IN_ECOM",
} as const;

export type UpdateSocialSecurityNumberResultEnum =
  (typeof UpdateSocialSecurityNumberResultEnum)[keyof typeof UpdateSocialSecurityNumberResultEnum];
export type UpdateTeamMemberInput = {
  clubId: Scalars["String"];
  configurationId: Scalars["String"];
  customerId: Scalars["String"];
  firstname?: InputMaybe<Scalars["String"]>;
  initials?: InputMaybe<Scalars["String"]>;
  playerNumber?: InputMaybe<Scalars["String"]>;
  surname?: InputMaybe<Scalars["String"]>;
  teamAdmin?: InputMaybe<Scalars["Boolean"]>;
  teamId: Scalars["String"];
};

export type UserInput = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type UserProductReviewsInput = {
  nextToken?: InputMaybe<Scalars["String"]>;
};

export type ValidateReviewerEmailInput = {
  articleNumber: Scalars["String"];
  email: Scalars["String"];
};

export type VariantBundlesFilterInput = {
  filterUnavailable?: InputMaybe<Scalars["Boolean"]>;
};

export type VariantRequestInput = {
  ean?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["String"]>;
};

export type WeaponCardInput = {
  cardNumber: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
};

export type XrsAddToCartBundleEntryGroupInput = {
  id: Scalars["String"];
  products: Array<XrsAddToCartBundleEntryGroupProductInput>;
};

export type XrsAddToCartBundleEntryGroupProductInput = {
  ean: Scalars["String"];
  groupId?: InputMaybe<Scalars["String"]>;
};

export type XrsAddToCartBundleEntryInput = {
  bundleArticleNumber?: InputMaybe<Scalars["String"]>;
  ean: Scalars["String"];
  groups: Array<XrsAddToCartBundleEntryGroupInput>;
};

export type XrsAddToCartConfigurationEntryInput = {
  configuratorType: ConfiguratorType;
  ean: Scalars["String"];
  qualifier: Scalars["String"];
};

export type XrsAddToCartEntryInput = {
  configurations?: InputMaybe<
    Array<InputMaybe<XrsAddToCartConfigurationEntryInput>>
  >;
  ean: Scalars["String"];
  quantity: Scalars["Int"];
};

export type XrsPrintViewInput = {
  market: Market;
  orderId: Scalars["String"];
};

export type GetLoggedInInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInInfoQuery = {
  account: {
    email: string;
    memberNumber: string | null;
    cartId: { id: string } | null;
  } | null;
};

export type reviewsQueryFieldsFragment = {
  nextToken: string | null;
  reviews: Array<{
    creationDate: any;
    displayName: string;
    headline: string;
    helpfulVoteCount: number;
    id: string;
    isArticleRecommended: boolean;
    rating: number;
    sizeFitness: SizeFitness | null;
    text: string;
    verifiedBuyer: boolean | null;
    unhelpfulVoteCount: number;
    translations: Array<{
      headline: string | null;
      language: string;
      sourceLanguage: string | null;
      text: string;
    } | null>;
  } | null> | null;
};

export type ReviewsAndAggregatedRatingsQueryVariables = Exact<{
  reviewsFilter: ReviewsFilter;
  aggregatedRatingsFilter: AggregatedRatingFilter;
  limit: InputMaybe<Scalars["Int"]>;
  nextToken: InputMaybe<Scalars["String"]>;
}>;

export type ReviewsAndAggregatedRatingsQuery = {
  reviews: {
    nextToken: string | null;
    reviews: Array<{
      creationDate: any;
      displayName: string;
      headline: string;
      helpfulVoteCount: number;
      id: string;
      isArticleRecommended: boolean;
      rating: number;
      sizeFitness: SizeFitness | null;
      text: string;
      verifiedBuyer: boolean | null;
      unhelpfulVoteCount: number;
      translations: Array<{
        headline: string | null;
        language: string;
        sourceLanguage: string | null;
        text: string;
      } | null>;
    } | null> | null;
  } | null;
  aggregatedRating: {
    average: number;
    quantity: number;
    recommendationPercentage: number;
    ratingQuantities: Array<{ quantity: number; rating: Rating } | null>;
    sizeRating: Array<{ percentage: number; sizeFitness: SizeFitness }>;
  } | null;
};

export type SizeRecommendationQueryQueryVariables = Exact<{
  aggregatedRatingsFilter: AggregatedRatingFilter;
}>;

export type SizeRecommendationQueryQuery = {
  aggregatedRating: {
    sizeRating: Array<{ percentage: number; sizeFitness: SizeFitness }>;
  } | null;
};

export type ReviewsQueryVariables = Exact<{
  filter: ReviewsFilter;
  limit: InputMaybe<Scalars["Int"]>;
  nextToken: InputMaybe<Scalars["String"]>;
}>;

export type ReviewsQuery = {
  reviews: {
    nextToken: string | null;
    reviews: Array<{
      creationDate: any;
      displayName: string;
      headline: string;
      helpfulVoteCount: number;
      id: string;
      isArticleRecommended: boolean;
      rating: number;
      sizeFitness: SizeFitness | null;
      text: string;
      verifiedBuyer: boolean | null;
      unhelpfulVoteCount: number;
      translations: Array<{
        headline: string | null;
        language: string;
        sourceLanguage: string | null;
        text: string;
      } | null>;
    } | null> | null;
  } | null;
};

export type ExpertReviewQueryVariables = Exact<{
  input: ExpertReviewInput;
}>;

export type ExpertReviewQuery = {
  expertReview: {
    id: string;
    text: string;
    title: string;
    store: string;
    author: string;
    modifiedTime: any;
    creationDate: any;
    helpfulVoteCount: number;
    unhelpfulVoteCount: number;
  } | null;
};
