import React, { useEffect, useMemo, useState } from "react";
import type { ReactElement, MouseEvent } from "react";
import { ClubLogo, List, MainList, Nav } from "./Navigation.styled";
import { BackButtonListItem } from "./BackButtonListItem";
import { SubCategoryListItem } from "./SubCategoryListItem";
import { MoreLinks } from "./MoreLinks";
import { ShowAllCategoryListItem } from "./ShowAllCategoryListItem";
import type {
  MegaMenuContentData,
  MenuSubCategoryData,
} from "@xxl/frontend-api";
import { LinkListItem } from "./LinkListItem";
import {
  ANIMATION_DURATION,
  CLUB_ASSORTMENT_LINKS_CODE,
  ICON_SIZE_PX,
} from "./constants";
import { CategoryIcon } from "./CategoryIcon";
import { CampaignListItem } from "./CampaignListItem";
import isEmpty from "lodash/isEmpty";
import {
  CustomerService,
  FixedLowPrice,
  Guide,
  NewIn,
  Store,
} from "@xxl/icons";
import {
  getFilteredHeaderLinksData,
  getUrl,
} from "../Desktop/mega-menu-helper";
import type { HeaderLink } from "../../../global";
import { useSharedData } from "../../../contexts/SharedData";
import { ClubAssortmentItem } from "./ClubAssortmentItem";
import { isNotEmpty, isNotNullOrUndefined } from "@xxl/common-utils";
import stringFormat from "string-format";
import { useSessionSource } from "../../../contexts/Session";
import { useClubAssortmentLinks } from "../hooks/useClubAssortmentLinks";
import { useRelinkValue } from "react-relink";

type MegaMenuProps = {
  distanceFromWindowTop: number;
  isOpen: boolean;
  isTeamsales: boolean;
  menuData: MegaMenuContentData;
  urlToCampaignHub: string;
  headerLinks?: HeaderLink[];
};

const getSubCategory = ({ name, url }: MenuSubCategoryData) => {
  if (name === undefined || url === undefined) {
    return [];
  }
  return [
    <LinkListItem href={url} key={`${name}-${url}`}>
      {name}
    </LinkListItem>,
  ];
};

const Navigation: React.FunctionComponent<MegaMenuProps> = ({
  distanceFromWindowTop,
  headerLinks = [],
  isOpen,
  menuData,
  urlToCampaignHub,
  isTeamsales,
}) => {
  const [selectedCategoryLevelOneCode, setSelectedCategoryLevelOneCode] =
    useState<string | undefined>();
  const [selectedCategoryLevelTwoCode, setSelectedCategoryLevelTwoCode] =
    useState<string | undefined>();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { requestMapping } = useSharedData().data;
  const { clubAssortment } = requestMapping;
  const clubAssortmentLinks = useClubAssortmentLinks({
    isLoggedIn,
    isTeamsales,
  });

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSelectedCategoryLevelOneCode(undefined);
        setSelectedCategoryLevelTwoCode(undefined);
      }, ANIMATION_DURATION);
    }
  }, [isOpen]);

  const {
    levelOneCategories,
    listOfLevelTwoCategories,
    listOfLevelThreeCategories,
  } = useMemo(() => {
    const levelOneCategories: ReactElement[] = [];
    const listOfLevelTwoCategories: ReactElement[] = [];
    const listOfLevelThreeCategories: ReactElement[] = [];
    const handleClickLevelOneMenuItem =
      (categoryCode: string) => (event: MouseEvent) => {
        event.preventDefault();
        setSelectedCategoryLevelOneCode(categoryCode);
      };

    menuData.links?.forEach(
      ({ displayName = "", campaign, category, link, links = [] }) => {
        const categoryCode = category?.code;
        if (!categoryCode) {
          return;
        }

        const idLevelOne = `category-code-${categoryCode}`;
        const isLevelOneActive = selectedCategoryLevelOneCode === categoryCode;

        levelOneCategories.push(
          <SubCategoryListItem
            displayName={displayName}
            href={getUrl({ link: category.url })}
            key={`item-${idLevelOne}`}
            icon={<CategoryIcon code={categoryCode} />}
            id={idLevelOne}
            isActive={isLevelOneActive}
            onClick={handleClickLevelOneMenuItem(categoryCode)}
          />
        );

        if (!isLevelOneActive) {
          return;
        }

        const levelTwoCategories = links.flatMap(
          ({ category, displayName: levelTwoDisplayName = "" }) => {
            if (category?.code === undefined) {
              return [];
            }
            const { code, subCategories = [], url } = category;
            const idLevelTwo = `category-code-${code}`;
            const isLevelTwoActive = selectedCategoryLevelTwoCode === code;

            if (isLevelTwoActive) {
              listOfLevelThreeCategories.push(
                <List
                  aria-labelledby={idLevelTwo}
                  aria-orientation="vertical"
                  key={`list-${idLevelOne}-${idLevelTwo}`}
                  historyState={"active"}
                >
                  <BackButtonListItem
                    callback={setSelectedCategoryLevelTwoCode}
                  />
                  <ShowAllCategoryListItem
                    code={code}
                    displayName={levelTwoDisplayName}
                    link={url}
                  />
                  {subCategories.flatMap(getSubCategory)}
                </List>
              );
            }

            return [
              <SubCategoryListItem
                displayName={levelTwoDisplayName}
                key={`item-${idLevelOne}-${idLevelTwo}`}
                icon={<CategoryIcon code={code} />}
                id={idLevelTwo}
                isActive={isLevelTwoActive}
                onClick={() => setSelectedCategoryLevelTwoCode(code)}
              />,
            ];
          }
        );

        listOfLevelTwoCategories.push(
          <List
            aria-hidden={!isEmpty(selectedCategoryLevelTwoCode)}
            aria-labelledby={idLevelOne}
            aria-orientation="vertical"
            key={`list-${categoryCode}`}
            historyState={
              isEmpty(selectedCategoryLevelTwoCode) ? "active" : "past"
            }
          >
            <BackButtonListItem callback={setSelectedCategoryLevelOneCode} />
            <ShowAllCategoryListItem
              code={categoryCode}
              displayName={displayName}
              link={link}
            />
            {levelTwoCategories}
            {isNotNullOrUndefined(campaign) && (
              <CampaignListItem campaign={campaign} />
            )}
          </List>
        );
      }
    );

    return {
      levelOneCategories,
      listOfLevelTwoCategories,
      listOfLevelThreeCategories,
    };
  }, [menuData, selectedCategoryLevelOneCode, selectedCategoryLevelTwoCode]);

  const {
    brandsLink,
    customerServiceLink,
    filteredHeaderLinks,
    outletLink,
    storeFinderLink,
  } = getFilteredHeaderLinksData({
    headerLinks,
    requestMapping,
  });

  return (
    <Nav
      aria-hidden={!isOpen}
      aria-labelledby={"link-for-mobile-mega-menu"}
      aria-orientation="vertical"
      data-testid="mobile-mega-menu"
      distanceFromWindowTop={distanceFromWindowTop}
      isOpen={isOpen}
    >
      <MainList
        aria-hidden={!isEmpty(selectedCategoryLevelOneCode)}
        historyState={isEmpty(selectedCategoryLevelOneCode) ? "active" : "past"}
        isVisible={isOpen}
      >
        {levelOneCategories}
        {isNotNullOrUndefined(outletLink) && (
          <LinkListItem href={outletLink.url}>
            <FixedLowPrice fontSize={ICON_SIZE_PX} />
            {outletLink.displayName}
          </LinkListItem>
        )}
        {isTeamsales
          ? clubAssortmentLinks !== null &&
            isNotEmpty(clubAssortmentLinks) && (
              <ClubAssortmentItem
                clubAssortmentLinks={clubAssortmentLinks}
                isActive={
                  selectedCategoryLevelOneCode === CLUB_ASSORTMENT_LINKS_CODE
                }
                onClick={() =>
                  setSelectedCategoryLevelOneCode(CLUB_ASSORTMENT_LINKS_CODE)
                }
              />
            )
          : isNotNullOrUndefined(menuData.campaignHubLink) &&
            menuData.campaignHubLink.length > 0 && (
              <LinkListItem href={urlToCampaignHub}>
                <Guide fontSize={ICON_SIZE_PX} />
                {menuData.campaignHubLink}
              </LinkListItem>
            )}
        {isNotNullOrUndefined(brandsLink) && (
          <LinkListItem href={brandsLink.url}>
            <NewIn fontSize={ICON_SIZE_PX} />
            {brandsLink.displayName}
          </LinkListItem>
        )}
        {filteredHeaderLinks.length > 0 && (
          <MoreLinks links={filteredHeaderLinks} />
        )}
        {isNotNullOrUndefined(customerServiceLink) && (
          <LinkListItem href={customerServiceLink.url} linkType="secondary">
            <CustomerService fontSize={ICON_SIZE_PX} />
            {customerServiceLink.displayName}
          </LinkListItem>
        )}
        {isNotNullOrUndefined(storeFinderLink) && (
          <LinkListItem href={storeFinderLink.url} linkType="secondary">
            <Store fontSize={ICON_SIZE_PX} />
            {storeFinderLink.displayName}
          </LinkListItem>
        )}
      </MainList>
      {listOfLevelTwoCategories}

      {selectedCategoryLevelOneCode === CLUB_ASSORTMENT_LINKS_CODE && (
        <List
          aria-labelledby={CLUB_ASSORTMENT_LINKS_CODE}
          aria-orientation="vertical"
          historyState="active"
        >
          <BackButtonListItem callback={setSelectedCategoryLevelOneCode} />
          {clubAssortmentLinks?.map(({ logoUrl, name, uid }) => {
            const href = stringFormat(clubAssortment, uid);
            return (
              <LinkListItem href={href} key={name}>
                <ClubLogo src={logoUrl} />
                {name}
              </LinkListItem>
            );
          })}
        </List>
      )}
      {listOfLevelThreeCategories}
    </Nav>
  );
};

export { Navigation };
