import styled from "@emotion/styled/macro";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { Text } from "react-app/src/components/Text";
import { typographyToCss } from "react-app/src/styles/helpers";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { getClassificationsFromUsps } from "react-app/src/utils/ProductAttributes/attributes-helper";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import type { UspItem } from "@xxl/pim-api";
import type { StrictClassification } from "../types";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useClassifications } from "../../../hooks/useClassifications";
import { useRelinkValue } from "react-relink";
import { useSessionSource } from "react-app/src/contexts/Session";

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: ${xxlTheme.spaces.small};
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

const Li = styled.li`
  ${typographyToCss(xxlTheme.typography.mediumRegular)};
  letter-spacing: 0;
`;

type Props = {
  usps: UspItem[];
  classifications: StrictClassification[];
};

const _Attributes = ({ usps, classifications }: Props) => {
  const { t } = useTranslations();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const attributes = getClassificationsFromUsps(usps, isLoggedIn, t);
  const {
    featureToggles: { toggle_products_as_package_quantity },
  } = useSharedData().data;
  const classificationsAttributes = useClassifications(classifications, {
    limit: 3,
    positiveOnly: true,
  });

  if (attributes.length === 0) return <div />;
  return (
    <Ul>
      {toggle_products_as_package_quantity
        ? attributes.map((attribute) => {
            const { id, name, value } = attribute;
            return (
              <Li key={id}>
                <Text as="span">{name}:&nbsp;</Text>
                <Text as="span">{value}</Text>
              </Li>
            );
          })
        : classificationsAttributes.map((item) => {
            const [name, value] = item;
            return (
              <Li key={name}>
                <Text as="span">{name}:&nbsp;</Text>
                <Text as="span">{value}</Text>
              </Li>
            );
          })}
    </Ul>
  );
};

export const Attributes = withErrorBoundary(_Attributes);
