import { RelinkSource } from "react-relink";

export const useSessionSource = new RelinkSource({
  key: "sessionSource",
  default: false,
});
// Goal is to show onboarding modal only after all other modals are finalized / closed (to prevent many modals overlapping each other)
// this object can be used to store flags (eg. hasCompleteData) that are needed to determine if onboarding modal is ready to be shown. If more flags are needed, add them here.
export const useModalsStatusSource = new RelinkSource({
  key: "modalsStatusSource",
  default: {
    isCompleteDataFormVisible: false,
  },
});
