import { isNotNullOrUndefined } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import type { EcomSiteUidLegacy } from "../../global";
import type { PriceDisplayVariant } from "../data-types";
import type {
  FrontendPriceDisplayData,
  GetFrontendPriceDisplayDataProps,
} from "./types";

export const isBundleSavingVariant = (variant?: PriceDisplayVariant) =>
  variant === "PREVIOUS_PRICE_BUNDLE_SAVING";

const LEGACY_UID_NORWAY = "xxl";

const getSalesPriceDisclaimer = (
  siteUid: EcomSiteUidLegacy,
  invertedPriceFormatted?: string,
  priceDisclaimer?: string
) =>
  priceDisclaimer !== undefined &&
  siteUid !== LEGACY_UID_NORWAY &&
  isEmpty(invertedPriceFormatted)
    ? priceDisclaimer
    : undefined;

/**
 * This is a temporary solution to handle the legacy price disclaimer.
 * Can be removed when Norway is using the price disclaimer from the API
 * instead of the translation key.
 */
const handleLegacyNorwayDisclaimer = (
  t: Translate,
  variant: PriceDisplayVariant | undefined,
  priceDisclaimer: string | undefined
) =>
  !isBundleSavingVariant(variant)
    ? priceDisclaimer
    : t("bundle.original.discount");

const getIsRegularOrPreviousOrOnlyOnline = (variant?: PriceDisplayVariant) =>
  variant === "REGULAR" ||
  variant === "PREVIOUS_PRICE" ||
  variant === "ONLY_AVAILABLE_ONLINE";

const getIsPromotionOrCampaign = (variant?: PriceDisplayVariant) =>
  variant === "CAMPAIGN" || variant === "PROMOTION";

const getPriceTranslation = (
  t: Translate,
  variant?: PriceDisplayVariant
): string => {
  if (getIsPromotionOrCampaign(variant)) {
    return t("product.campaign.price");
  }
  if (getIsRegularOrPreviousOrOnlyOnline(variant)) {
    return t("product.was.price");
  }
  if (isBundleSavingVariant(variant)) {
    return t("bundle.original.discount");
  }
  return "";
};

const getOtherPriceDisclaimer = ({
  t,
  siteUid,
  hasLowerProductConfiguratorPriceThanSalesPrice,
  invertedPriceFormatted,
  priceDisclaimer,
  variant,
  isMultiPackProduct,
}: {
  t: Translate;
  siteUid: EcomSiteUidLegacy;
  hasLowerProductConfiguratorPriceThanSalesPrice: boolean;
  invertedPriceFormatted: string | undefined;
  priceDisclaimer: string | undefined;
  variant: PriceDisplayVariant | undefined;
  isMultiPackProduct: boolean;
}) => {
  if (hasLowerProductConfiguratorPriceThanSalesPrice || isMultiPackProduct) {
    return t("configurator.for.product.bundles.bought.separately");
  }

  const hasInvertedPriceFormatted = !isEmpty(invertedPriceFormatted);

  if (hasInvertedPriceFormatted) return priceDisclaimer;

  if (siteUid === LEGACY_UID_NORWAY)
    return handleLegacyNorwayDisclaimer(t, variant, priceDisclaimer);

  return getPriceTranslation(t, variant);
};

const hasOtherPriceInfo = ({
  invertedPriceFormatted,
  previousPriceFormatted,
  priceDisclaimer,
}: {
  invertedPriceFormatted?: string;
  previousPriceFormatted?: string;
  priceDisclaimer?: string;
}) =>
  previousPriceFormatted !== undefined ||
  priceDisclaimer !== undefined ||
  invertedPriceFormatted !== undefined;

const showPreviousPrice = ({
  priceDisclaimer,
  previousPriceFormatted,
  variant,
}: {
  priceDisclaimer: string | undefined;
  previousPriceFormatted: string | undefined;
  variant: PriceDisplayVariant | undefined;
}): boolean => {
  const showForPromotionType = getIsPromotionOrCampaign(variant);
  const showForRegularOrPreviousPrice =
    getIsRegularOrPreviousOrOnlyOnline(variant);
  const showForBundleSaving =
    isBundleSavingVariant(variant) && isEmpty(priceDisclaimer);
  return (
    !isEmpty(previousPriceFormatted) &&
    (showForPromotionType ||
      showForRegularOrPreviousPrice ||
      showForBundleSaving)
  );
};

const getOtherPrice = ({
  hasLowerProductConfiguratorPriceThanSalesPrice = false,
  invertedPriceFormatted,
  previousPriceFormatted,
  priceDisclaimer,
  salesPriceFormatted,
  variant,
}: {
  hasLowerProductConfiguratorPriceThanSalesPrice: boolean | undefined;
  invertedPriceFormatted: string | undefined;
  previousPriceFormatted: string | undefined;
  priceDisclaimer: string | undefined;
  salesPriceFormatted: string | undefined;
  variant: PriceDisplayVariant | undefined;
}): string | undefined => {
  if (hasLowerProductConfiguratorPriceThanSalesPrice) {
    return salesPriceFormatted;
  }

  if (
    !hasOtherPriceInfo({
      invertedPriceFormatted,
      previousPriceFormatted,
      priceDisclaimer,
    })
  ) {
    return undefined;
  }

  const hasPreviousPrice =
    showPreviousPrice({
      priceDisclaimer,
      previousPriceFormatted,
      variant,
    }) && isEmpty(invertedPriceFormatted);

  if (hasPreviousPrice && !isEmpty(previousPriceFormatted)) {
    return previousPriceFormatted;
  }

  return invertedPriceFormatted ?? undefined;
};

const isRegularMultiPackSaving = ({
  variant,
  isMultiPackProduct,
}: {
  variant: PriceDisplayVariant | undefined;
  isMultiPackProduct: boolean;
}): boolean => variant === "REGULAR" && isMultiPackProduct;

const hasUndeductedRewardDiscount = ({
  invertedPrice,
  invertedPriceFormatted,
  variant,
  isMultiPackProduct,
}: {
  invertedPrice: number | undefined;
  invertedPriceFormatted: string | undefined;
  variant: PriceDisplayVariant | undefined;
  isMultiPackProduct: boolean;
}): boolean => {
  if (
    isRegularMultiPackSaving({
      variant,
      isMultiPackProduct,
    })
  ) {
    return false;
  }

  return (
    (isNotNullOrUndefined(invertedPrice) ||
      isNotNullOrUndefined(invertedPriceFormatted)) &&
    variant !== undefined &&
    variant !== "PROMOTION"
  );
};

const isDiscountedMainPrice = ({
  salesPriceFormatted,
  isLoggedInMember,
  invertedPrice,
  invertedPriceFormatted,
  priceDisclaimer,
  previousPriceFormatted,
  variant,
  isMultiPackProduct,
}: {
  salesPriceFormatted: string | undefined;
  isLoggedInMember: boolean;
  invertedPrice: number | undefined;
  invertedPriceFormatted: string | undefined;
  priceDisclaimer: string | undefined;
  previousPriceFormatted: string | undefined;
  variant: PriceDisplayVariant | undefined;
  isMultiPackProduct: boolean;
}) => {
  const hasDiscount =
    salesPriceFormatted !== previousPriceFormatted &&
    (showPreviousPrice({
      priceDisclaimer,
      previousPriceFormatted,
      variant,
    }) ||
      !isEmpty(invertedPriceFormatted));

  if (
    !hasDiscount ||
    isRegularMultiPackSaving({ variant, isMultiPackProduct })
  ) {
    return false;
  }

  const isReward = hasUndeductedRewardDiscount({
    invertedPrice,
    invertedPriceFormatted,
    variant,
    isMultiPackProduct,
  });

  return isReward ? isLoggedInMember : true;
};

const showSalesPriceDisclaimer = (siteUid: EcomSiteUidLegacy): boolean =>
  siteUid === "xxl-fi";

const getSalesPriceData = ({
  invertedPrice,
  isLoggedIn,
  priceDisplay: { salesPrice, salesPriceFormatted, ...priceDisplay },
  shouldEvaluateProductConfiguratorPrice,
}: {
  invertedPrice?: number;
  isLoggedIn: boolean;
  priceDisplay: {
    salesPrice: number | undefined;
    salesPriceFormatted: string | undefined;
    productConfiguratorPrice?: number;
    productConfiguratorPriceFormatted?: string;
  };
  shouldEvaluateProductConfiguratorPrice: boolean;
}) => {
  if (
    typeof salesPrice !== "number" ||
    typeof salesPriceFormatted !== "string"
  ) {
    throw Error("Missing sales price");
  }

  if (
    shouldEvaluateProductConfiguratorPrice === true &&
    "productConfiguratorPrice" in priceDisplay &&
    typeof priceDisplay.productConfiguratorPrice === "number" &&
    "productConfiguratorPriceFormatted" in priceDisplay &&
    typeof priceDisplay.productConfiguratorPriceFormatted === "string"
  ) {
    const { productConfiguratorPrice, productConfiguratorPriceFormatted } =
      priceDisplay;
    if (typeof invertedPrice === "number") {
      // Comparing to 'salesPrice' when logged in because 'salesPrice' will be the lower price
      if (
        (isLoggedIn === true && productConfiguratorPrice < salesPrice) ||
        (isLoggedIn === false && productConfiguratorPrice < invertedPrice)
      ) {
        return {
          hasLowerProductConfiguratorPriceThanSalesPrice: true,
          salesPriceFormatted: productConfiguratorPriceFormatted,
        };
      }
      return {
        hasLowerProductConfiguratorPriceThanSalesPrice: false,
        salesPriceFormatted:
          productConfiguratorPrice < salesPrice
            ? productConfiguratorPriceFormatted
            : salesPriceFormatted,
      };
    }
    // If 'invertedPrice' is not defined, compare product configurator price to 'salesPrice'
    if (productConfiguratorPrice < salesPrice) {
      return {
        hasLowerProductConfiguratorPriceThanSalesPrice: true,
        salesPriceFormatted: productConfiguratorPriceFormatted,
      };
    }
  }
  return {
    hasLowerProductConfiguratorPriceThanSalesPrice: false,
    salesPriceFormatted,
  };
};

const getFrontendPriceDisplayData = ({
  isLoggedIn,
  priceDisplay,
  shouldEvaluateProductConfiguratorPrice = false,
  isMultiPackProduct = false,
  siteUid,
  t,
}: GetFrontendPriceDisplayDataProps): FrontendPriceDisplayData => {
  const {
    invertedPrice,
    invertedPriceFormatted,
    type: variant,
    previousPriceFormatted,
    priceDisclaimer,
    priceSplash,
    userGroupId,
    salesPrice,
    salesPriceFormatted,
  } = priceDisplay;
  const {
    hasLowerProductConfiguratorPriceThanSalesPrice,
    salesPriceFormatted: updatedSalesPriceFormatted,
  } = getSalesPriceData({
    invertedPrice,
    isLoggedIn,
    priceDisplay: {
      salesPrice,
      salesPriceFormatted,
      productConfiguratorPrice:
        "productConfiguratorPrice" in priceDisplay
          ? priceDisplay.productConfiguratorPrice
          : undefined,
      productConfiguratorPriceFormatted:
        "productConfiguratorPriceFormatted" in priceDisplay
          ? priceDisplay.productConfiguratorPriceFormatted
          : undefined,
    },
    shouldEvaluateProductConfiguratorPrice,
  });

  return {
    hasLowerProductConfiguratorPriceThanSalesPrice,
    hasOtherPriceInfo: hasOtherPriceInfo(priceDisplay),
    isDiscountedMainPrice:
      isDiscountedMainPrice({
        salesPriceFormatted,
        isLoggedInMember: isLoggedIn,
        invertedPrice,
        invertedPriceFormatted,
        priceDisclaimer,
        previousPriceFormatted,
        variant,
        isMultiPackProduct,
      }) && hasLowerProductConfiguratorPriceThanSalesPrice === false,
    isInvertedPrice: !isEmpty(invertedPriceFormatted),
    otherPrice: getOtherPrice({
      hasLowerProductConfiguratorPriceThanSalesPrice,
      invertedPriceFormatted,
      previousPriceFormatted,
      priceDisclaimer,
      salesPriceFormatted: updatedSalesPriceFormatted,
      variant,
    }),
    otherPriceDisclaimer: getOtherPriceDisclaimer({
      t,
      siteUid,
      hasLowerProductConfiguratorPriceThanSalesPrice,
      invertedPriceFormatted,
      priceDisclaimer,
      variant,
      isMultiPackProduct,
    }),
    otherPriceType: variant ?? "REGULAR",
    priceSplash: priceSplash,
    salesPrice: updatedSalesPriceFormatted,
    salesPriceDisclaimer: getSalesPriceDisclaimer(
      siteUid,
      invertedPriceFormatted,
      priceDisclaimer
    ),
    userGroupId,
  };
};

const currencySymbols: { [key in EcomSiteUidLegacy]: string } = {
  "xxl-fi": "€",
  xxl: ";-",
  "xxl-se": ":-",
};

export {
  currencySymbols,
  getFrontendPriceDisplayData,
  getIsPromotionOrCampaign,
  getOtherPrice,
  hasOtherPriceInfo,
  hasUndeductedRewardDiscount,
  showSalesPriceDisclaimer,
};
