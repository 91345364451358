import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss } from "../../styles/helpers";
import typography from "./SearchInput.typography";
import { fadeInAnimation } from "../../styled/mixins";
import { MOBILE_HEIGHT_WITH_HEADER_LINKS } from "../Header/constants";

const Z_INDEX_AUTOCOMPLETE = 101;

const { colors, spaces } = xxlTheme;

export const SearchSuggestionsWrapper = styled.div`
  position: relative;
`;

export const SearchInputWrapper = styled.div<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 0 ${spaces.smallRegular};
  color: ${colors.xxlWebBlack};
  background-color: ${colors.xxlWhite};
  border: none;
  position: relative;
  ${({ isActive }) => (isActive ? `z-index: ${Z_INDEX_AUTOCOMPLETE};` : "")}

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  width: 100%;
  margin-left: 6px;
  outline: none;
  ${typographyToCss(typography.input)};

  &:focus-visible::placeholder {
    color: ${colors.xxlWebBlack};
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

export const Button = styled.button`
  color: ${colors.xxlWebBlack};
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: 0;
  outline: 0;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 1px solid ${colors.xxlWebBlack};
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh + ${MOBILE_HEIGHT_WITH_HEADER_LINKS}px);
  inset: 0;
  z-index: ${Z_INDEX_AUTOCOMPLETE};
  background-color: rgba(0, 0, 0, 0.13);
  ${fadeInAnimation("search-auto-suggest-overlay")}
`;
