import type { SiteIdentifier } from "@xxl/common-utils";
import type { DynamicToggle } from "react-app/src/api/ConfigApi";
import type { ToggleCookie } from "react-app/src/components/XXLDynamicToggle/types";
import {
  getEnvVar,
  getSsmApiConfiguration,
  isNotProd,
  isTrue,
} from "../utils/environment-variables";
import { Configuration, SsmApi } from "@xxl/ssm-api";

const ssmApi = new SsmApi(new Configuration(getSsmApiConfiguration()));

const getSsmParameter = async (
  siteId: SiteIdentifier,
  parameterName: string,
  fallback: string
): Promise<string> => {
  try {
    const { data } = await ssmApi.getParameter(parameterName, siteId);
    return data.value ?? fallback;
  } catch (error) {
    if (isNotProd()) {
      throw Error(`Failed to get ${parameterName} from ssm-api`);
    }
    throw error as Error;
  }
};

const getSsmToggle = async (
  siteId: SiteIdentifier,
  parameterName: DynamicToggle,
  fallback = false
) =>
  (await getSsmParameter(siteId, parameterName, fallback.toString()))
    .toLowerCase()
    .trim() === true.toString();

const getIsToggleEnabled = async (
  name: DynamicToggle,
  dynamicToggleValues: ToggleCookie | null,
  siteUid: SiteIdentifier
) =>
  isNotProd() && dynamicToggleValues !== null && name in dynamicToggleValues
    ? dynamicToggleValues[name].includes("true")
    : getSsmToggle(siteUid, name);

const getSsmConfigParameters = async (siteId: SiteIdentifier) => {
  const [
    cookieVersion,
    giosgEnabledString,
    giosgId,
    logRocketApiId,
    serverGtmScriptUrl,
  ] = await Promise.all([
    getSsmParameter(
      siteId,
      "config.site.cookieVersion",
      getEnvVar("CONFIG_SITE_COOKIEVERSION")
    ),
    getSsmParameter(siteId, "config.site.giosgEnabled", "false"),
    getSsmParameter(siteId, "config.site.giosgId", ""),
    getSsmParameter(siteId, "config.site.logRocketApiId", ""),
    getSsmParameter(siteId, "config.site.serverGtmScriptUrl", ""),
  ]);

  return {
    cookieVersion,
    giosg: {
      giosgEnabled: isTrue(giosgEnabledString),
      giosgId,
    },
    logRocketApiId,
    serverGtmScriptUrl,
  };
};

export { getIsToggleEnabled, getSsmConfigParameters, getSsmParameter };
