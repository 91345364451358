import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { resolutionSizes } from "react-app/src/config";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

type IsClickAndCollectEnabled = { isClickAndCollectEnabled: boolean };

const clickCollectGap = xxlTheme.spaces.micro;

const getButtonContainerStyles = ({
  isClickAndCollectEnabled,
}: IsClickAndCollectEnabled) =>
  isClickAndCollectEnabled
    ? css`
        display: grid;
        gap: ${clickCollectGap};
        grid-template-columns: repeat(2, calc(50% - ${clickCollectGap} / 2));
      `
    : css`
        display: flex;
        flex-direction: column;
      `;

export const ProductFormButtonContainer = styled.div<IsClickAndCollectEnabled>`
  width: 100%;
  ${getButtonContainerStyles}

  @media (max-width: ${resolutionSizes.laptop}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SizeOptionsContainer = styled.div<{
  quantityToggleEnabled: boolean;
}>`
  ${({ quantityToggleEnabled }) =>
    quantityToggleEnabled &&
    css`
      display: grid;
      grid-template-columns: 20% 1fr;
      gap: ${xxlTheme.spaces.micro};
    `}
`;

export const ProductFormNumberInput = styled.input`
  text-align: center;
  border: 1px solid ${xxlTheme.colors.xxlMediumGrey};
  padding: 0 ${xxlTheme.spaces.mini};
  ${xxlTheme.typography.standardRegular};
`;
