import React from "react";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { InfoMessage as InfoMessageType } from "../../utils/apis/content-info-messages";
import { createUrl } from "../../components/InfoMessage/info-message-helper";
import { Fallback } from "./Fallback";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";

type InfoMessageProps = {
  message: InfoMessageType | null;
};

const InfoMessage: React.FunctionComponent<InfoMessageProps> = ({
  message,
}) => {
  const {
    requestMapping: { guides, faq },
  } = useSharedData().data;

  if (message === null) {
    return <Fallback />;
  }
  const { linkText, linkType, message: text, type, url } = message;
  const href = createUrl(url, linkType, guides, faq);
  const modifier = `--${type}`;

  return (
    <ErrorBoundary>
      <section className="info-messages">
        <p
          data-testid={"info-message-text"}
          className={`info-messages__message info-messages__message${modifier}`}
        >
          <span className="container" data-testid="product-info-message">
            {text} <a href={href}>{linkText}</a>
          </span>
        </p>
      </section>
    </ErrorBoundary>
  );
};

export { InfoMessage };
