import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { SizeData, StyleData } from "@xxl/frontend-api";
import {
  SizeDataStockStatusEnum,
  StyleDataStockStatusEnum,
} from "@xxl/frontend-api";
import { StockStatus } from "@xxl/search-api";
import { MISSING_PRODUCT_IMAGE_URL } from "../../constants";
import { getFilespinImageUrl } from "../../utils/xxl-filespin-image";
import { OptionNode } from "../Common/SelectBox/OptionNode";
import { StockStatusEnum } from "../../enums";
import type {
  StockStatus as StockStatusFE,
  SizeData as SizeDataFE,
} from "../../utils/data-types";
import { logError } from "../../utils/xxl-log";

function parseToStockStatusEnum(
  stockStatus?:
    | StyleDataStockStatusEnum
    | SizeDataStockStatusEnum
    | StockStatus
    | StockStatusFE
): StockStatusEnum {
  switch (stockStatus) {
    case SizeDataStockStatusEnum.INSTOCK:
    case StyleDataStockStatusEnum.INSTOCK:
    case StockStatus.INSTOCK:
      return StockStatusEnum.INSTOCK;
    case SizeDataStockStatusEnum.LOWSTOCK:
    case StyleDataStockStatusEnum.LOWSTOCK:
    case StockStatus.LOWSTOCK:
      return StockStatusEnum.LOWSTOCK;
    case SizeDataStockStatusEnum.OUTOFSTOCK:
    case StyleDataStockStatusEnum.OUTOFSTOCK:
    case StockStatus.OUTOFSTOCK:
      return StockStatusEnum.OUTOFSTOCK;
    default:
      return StockStatusEnum.OUTOFSTOCK;
  }
}

export function getSizeStockStatus(sizeOption?: {
  stockStatus?: StockStatusFE;
}): StockStatusEnum | undefined {
  if (!isNotNullOrUndefined(sizeOption)) {
    return undefined;
  } else {
    return parseToStockStatusEnum(sizeOption.stockStatus);
  }
}

export function mapSizeToOption(sizeOption: SizeDataFE) {
  if (!isNotNullOrUndefined(sizeOption.code)) {
    logError("Code on size option not set");
  }
  return {
    key: sizeOption.code ?? "",
    value: sizeOption,
    name: (
      <OptionNode
        itemName={sizeOption.size ?? ""}
        productCode={sizeOption.code}
        productEan={sizeOption.ean}
        stockStatus={getSizeStockStatus(sizeOption)}
      />
    ),
  };
}

export function mapStyleToOption(
  styleOption: {
    code?: string;
    colourData?: {
      name?: string;
    };
    name?: string;
    stockStatus?: StockStatusFE;
  },
  idx: number
) {
  return {
    key: styleOption.code ?? idx,
    value: styleOption,
    name: (
      <OptionNode
        itemName={styleOption.name ?? styleOption.colourData?.name ?? ""}
        stockStatus={parseToStockStatusEnum(styleOption.stockStatus)}
        productCode={styleOption.code}
      />
    ),
  };
}

export function getPreselectedSize(
  sizeOptions: SizeData[] = []
): SizeData | undefined {
  const availableSizes = sizeOptions.filter(
    (size) => getSizeStockStatus(size) === StockStatusEnum.INSTOCK
  );
  return availableSizes.length === 1 ? availableSizes[0] : undefined;
}

export function getPreselectedStyle(
  styleOptions?: {
    code?: string;
  }[],
  productCode?: string
): StyleData | undefined {
  if (!isNotNullOrUndefined(styleOptions) || styleOptions.length === 0) {
    return undefined;
  }

  const preselectedStyle = styleOptions.find(
    ({ code }) => code === productCode
  );
  const [firstStyle] = styleOptions;

  return preselectedStyle ?? firstStyle;
}

export function getPriceString(price?: {
  salesPriceFormatted?: string;
  salesPrice?: number;
}) {
  if (!isNotNullOrUndefined(price)) {
    return "";
  }
  if (isNotNullOrUndefined(price.salesPriceFormatted)) {
    return price.salesPriceFormatted;
  }
  if (isNotNullOrUndefined(price.salesPrice)) {
    return `${price.salesPrice}`;
  }
  return "";
}

type GetProductLinkWithTrackingListNameProps = {
  selectedStyleUrl?: string;
  productUrl?: string;
  gtmProductListName: string;
};

export function getProductLinkWithTrackingListName({
  selectedStyleUrl,
  productUrl,
  gtmProductListName,
}: GetProductLinkWithTrackingListNameProps) {
  if (
    (selectedStyleUrl === undefined || selectedStyleUrl === "") &&
    (productUrl === undefined || productUrl === "")
  ) {
    throw new Error(
      "getProductLinkWithTrackingListName: productUrl or selectedStyleUrl must be provided"
    );
  }

  const baseProductUrl = selectedStyleUrl ?? productUrl ?? "";
  const queryParams = new URLSearchParams({ gtmProductListName }).toString();

  return `${baseProductUrl}?${queryParams}`;
}

export function getPrimaryImageLink(
  selectedStylePrimaryImage?: string,
  productPrimaryImage?: string
): string {
  const imageLink = selectedStylePrimaryImage ?? productPrimaryImage;
  const imageLinkWithParams = isNotNullOrUndefined(imageLink)
    ? getFilespinImageUrl({
        imageBaseUrl: imageLink,
        options: {
          resize: { width: 120, height: 120 },
          quality: 95,
          bgcolor: "efefef",
        },
      })
    : MISSING_PRODUCT_IMAGE_URL;

  return imageLinkWithParams;
}
