const BACKGROUND_COLOR = "efefef";
const QUALITY = 90;
const productCardImageSize = {
  default: {
    mobile: 138,
    desktop: 210,
  },
};

type GetFileSpinQueryParametersProps = {
  baseUrl: string;
  size: number;
};
const getFileSpinUrl = ({ baseUrl, size }: GetFileSpinQueryParametersProps) =>
  `${baseUrl}?resize=${size},${size}&quality=${QUALITY}&bgcolor=${BACKGROUND_COLOR}`;

export { getFileSpinUrl, productCardImageSize };
