import { isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  PreviewData,
} from "next";
import type { ParsedUrlQuery } from "querystring";
import type { ToggleCookie } from "react-app/src/components/XXLDynamicToggle/types";
import { PageType, pagePaths } from "react-app/src/constants";
import type { FeatureToggles } from "react-app/src/global";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import type { EnvironmentData, XXLAppData } from "src/global";
import {
  getEnvironmentData,
  getTranslationsContent,
} from "../../utils/common-content";
import { isBot } from "../custom-headers";
import { getEnvironmentVariables, getSiteUid } from "../environment-variables";
import type { GetServerSidePropsCallback } from "../layout/with-layout-page-props";
import { getXXLToggleCookieFromRequest, isLocalhost } from "../page-helper";
import { setCookies } from "../search-page-helper/search-helper";
import {
  getCachedPageData,
  setCachedPageData,
} from "../server-side-cache/server-side-cache";
import { getSsmConfigParameters } from "../ssm-parameters";

export type PageData = {
  environmentData: EnvironmentData;
  giosg: {
    giosgEnabled: boolean;
    giosgId: string;
  };
  headerCode: string;
  isBot: boolean;
  logRocketApiId: string;
  serverGtmScriptUrl: string;
  translations: NextJsTranslations;
};

export const getPageType = (pathName?: string): PageType => {
  const {
    category,
    product,
    checkout,
    orderConfirmationPage,
    brandIndex,
    storeFinder,
    guides,
  } = pagePaths;
  if (isNotNullOrUndefined(pathName)) {
    if (pathName.includes(category)) {
      return PageType.CATEGORY;
    }
    if (pathName.includes(product)) {
      return PageType.PRODUCT;
    }
    if (pathName.includes(checkout)) {
      return PageType.CHECKOUT;
    }
    if (pathName.includes(orderConfirmationPage)) {
      return PageType.ORDER_CONFIRMATION;
    }
    if (pathName.includes(brandIndex)) {
      return PageType.BRAND_INDEX;
    }
    if (pathName.includes(storeFinder)) {
      return PageType.STORE_FINDER;
    }
    if (guides.find((item) => pathName.includes(item)) !== undefined) {
      return PageType.GUIDES;
    }
  }

  return PageType.HOME;
};

const getPageData = async (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
): Promise<PageData> => {
  const { req } = context;
  const { headers, url } = req;
  const { host } = headers;
  const pageType: PageType = getPageType(url);

  if (typeof host !== "string") {
    throw TypeError(`Expected pageHost to be string, got ${typeof host}`);
  }

  if (typeof url !== "string") {
    throw TypeError(`Expected page url to be string, got ${typeof url}`);
  }

  const siteUid = getSiteUid();
  const environmentVariables = getEnvironmentVariables(process.env.ENV_SITE);
  const getTranslationsContentPromise = getTranslationsContent(
    environmentVariables.TOGGLE_TEAMSALES
  );

  const [translations, ssmParams] = await Promise.all([
    getTranslationsContentPromise,
    getSsmConfigParameters(siteUid),
  ]);

  const { cookieVersion, giosg, logRocketApiId, serverGtmScriptUrl } =
    ssmParams;

  const environmentData = await getEnvironmentData({
    cookieVersion,
    environmentVariables,
    isDevelopment: isLocalhost(host),
    dynamicToggleValues: getXXLToggleCookieFromRequest(req),
    siteUid,
    pageType,
  });

  return {
    environmentData,
    giosg,
    headerCode: environmentVariables.CONFIG_SITE_CONTENT_HEADERCODE,
    isBot: isBot(headers),
    logRocketApiId,
    serverGtmScriptUrl,
    translations,
  };
};

const dynamicCookieToFeatureToggles = (
  dynamicToggles: ToggleCookie
): FeatureToggles =>
  Object.keys(dynamicToggles).reduce((toggles, name) => {
    return {
      ...toggles,
      ...{
        [name]:
          name !== "toggle_force_solr_as_provider_on_pages"
            ? dynamicToggles[name][0] === "true"
            : dynamicToggles[name].toString(),
      },
    };
  }, {} as FeatureToggles);

const withPageData =
  <T = undefined>(
    cb: GetServerSidePropsCallback<XXLAppData, T>,
    additionalData?: T | undefined
  ): GetServerSideProps<XXLAppData> =>
  async (context) => {
    const { req, res } = context;
    const { cookies } = req;

    const { customerKey, sessionKey } = await setCookies({
      cookies,
      req,
      res,
    });

    try {
      let commonData: PageData | null = await getCachedPageData();
      if (commonData === null) {
        commonData = await getPageData(context);
        void setCachedPageData(commonData);
      }

      const featureTogglesFromCookie = getXXLToggleCookieFromRequest(req);

      const cachedPropsWithDynamicToggles = {
        ...commonData,
        ...(featureTogglesFromCookie !== null &&
          process.env.ENV_TYPE !== "prod" && {
            environmentData: {
              ...commonData.environmentData,
              ...{
                featureToggles: {
                  ...commonData.environmentData.featureToggles,
                  ...dynamicCookieToFeatureToggles(featureTogglesFromCookie),
                },
              },
            },
          }),
      };

      return cb(
        context,
        cachedPropsWithDynamicToggles,
        {
          customerKey,
          sessionKey,
        },
        additionalData
      );
    } catch (error) {
      console.error(error);
      return {
        notFound: true,
      };
    }
  };

export { withPageData };
