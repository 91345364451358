import React from "react";
import {
  CurrentPrice,
  OriginalPrice,
  PriceDescriptionWidget,
  PriceWrapper,
  SalesPriceDisclaimer,
} from "../Product.styled";
import { OtherPrice } from "../../PriceDisplayPortals/PriceDisplayPortals.styled";
import type { PriceData } from "../../../utils/Search/search-helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Translate } from "../../../contexts/Translations/TranslationsContext";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

type PriceWithLabelsV2Props = {
  selectedColumnsNumber: number;
  priceData: {
    selling: PriceData;
    alternate?: PriceData;
    disclaimer?: string;
  };
  showDisclaimer: boolean;
  isMultiPackProduct?: boolean;
  productCode?: string;
};

const getLabel = (priceData: PriceData, t: Translate) => {
  const { labelType } = priceData;

  if (labelType === "TranslatedLabel") {
    return priceData.label;
  }

  if (labelType === "TranslationKey") {
    return t(priceData.translationKey);
  }

  return undefined;
};

const PriceWithLabelsV2: React.FunctionComponent<PriceWithLabelsV2Props> = ({
  selectedColumnsNumber,
  priceData,
  showDisclaimer,
}) => {
  const { t } = useTranslations();
  const { alternate, disclaimer, selling } = priceData;
  const hasUndeductedRewardDiscount =
    isNotNullOrUndefined(alternate) && alternate.value < selling.value;
  const hasDiscountedSellingPrice =
    isNotNullOrUndefined(alternate) && alternate.value > selling.value;
  const sellingLabel = getLabel(selling, t);
  const alternateLabel = isNotNullOrUndefined(alternate)
    ? getLabel(alternate, t)
    : undefined;

  return (
    <PriceWrapper data-testid="new-product-card-price-display">
      <CurrentPrice
        data-testid="current-price"
        columnAmount={selectedColumnsNumber}
        isDiscountPrice={hasDiscountedSellingPrice}
      >
        {selling.valueFormatted}
        {isNotNullOrUndefined(sellingLabel) && (
          <PriceDescriptionWidget isPDP={false}>
            {sellingLabel}
          </PriceDescriptionWidget>
        )}
      </CurrentPrice>

      <OriginalPrice columnAmount={selectedColumnsNumber}>
        {isNotNullOrUndefined(alternate) ? (
          <>
            {isNotNullOrUndefined(alternateLabel) && (
              <span>{alternateLabel}</span>
            )}
            <OtherPrice
              hasLowerProductConfiguratorPriceThanSalesPrice={
                hasUndeductedRewardDiscount
              }
              hasUndeductedRewardDiscount={hasUndeductedRewardDiscount}
              isInvertedPrice={hasUndeductedRewardDiscount}
            >
              {alternate.valueFormatted}
            </OtherPrice>
          </>
        ) : null}
      </OriginalPrice>
      {showDisclaimer && isNotNullOrUndefined(disclaimer) ? (
        <SalesPriceDisclaimer title={disclaimer}>
          {disclaimer}
        </SalesPriceDisclaimer>
      ) : null}
    </PriceWrapper>
  );
};

export { PriceWithLabelsV2 };
