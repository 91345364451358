import React, { useEffect } from "react";
import { useRelinkState } from "react-relink";
import { FavoritesSource } from "./MiniFavoritesState";
import { MiniFavoritesLink } from "./MiniFavorites.styled";
import { Favorites } from "@xxl/icons";
import { MiniCartCount } from "../Cart/Components/MiniCartCount";
import {
  getFavoritesCountFromLocalStorage,
  getFavoritesFromLocalStorage,
  setFavoritesCountInLocalStorage,
} from "./Favorites.helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";

export const MiniFavorites: React.FunctionComponent = () => {
  const [favoritesCount, setFavoritesCount] = useRelinkState(FavoritesSource);
  const localStorageCount = getFavoritesCountFromLocalStorage();
  const { t } = useTranslations();
  const {
    data: {
      requestMapping: {
        account,
        myAccount: { favorites },
      },
    },
  } = useSharedData();

  useEffect(() => {
    const localStorageArray = getFavoritesFromLocalStorage();
    if (
      favoritesCount === 0 &&
      isNotNullOrUndefined(localStorageCount) &&
      localStorageCount !== favoritesCount &&
      localStorageArray?.length !== 0
    ) {
      void setFavoritesCount(localStorageCount);
    } else if (favoritesCount !== 0 && favoritesCount !== localStorageCount) {
      setFavoritesCountInLocalStorage(favoritesCount);
    } else if (
      favoritesCount === 0 &&
      isNotNullOrUndefined(localStorageArray)
    ) {
      setFavoritesCountInLocalStorage(localStorageArray.length);
      void setFavoritesCount(localStorageArray.length);
    }
  }, [favoritesCount]);

  return (
    <MiniFavoritesLink
      href={`${account}${favorites}`}
      aria-label={t("account.my.favorites")}
    >
      <Favorites />
      <MiniCartCount isLoading={false} count={favoritesCount} />
    </MiniFavoritesLink>
  );
};
