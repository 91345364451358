import styled from "@emotion/styled/macro";
import { color } from "@xxl/theme";
import { XxlStack } from "../../../../components/Common/XxlStack";
import { borderCss } from "../../../../styled/Common.styled";
import { MQ } from "../../../../styles/helpers";
import { colorsAsCssVariable } from "../../../../styles/theme/colors";
import { xxlTheme } from "../../../../styles/xxl-theme";

const { colors, spaces } = xxlTheme;

const GRID_AREAS = {
  SEARCH_INPUT: "input",
  STORE_LIST: "list",
  STORE_DETAILS: "details",
  ACTIONS: "actions",
} as const;

const searchInputSize = `calc(${spaces.huge} + ${spaces.smallRegular})`;
const actionsHeight = spaces.giant;

export const StoreSelectionWrapper = styled.div`
  &::before {
    content: "";
    position: absolute;
    left: 0;
    border-top: ${borderCss};
    border-bottom: ${borderCss};
    width: 100%;
  }

  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: ${searchInputSize} auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "${GRID_AREAS.SEARCH_INPUT}"
    "${GRID_AREAS.STORE_LIST}"
    "${GRID_AREAS.ACTIONS}";
  grid-gap: ${spaces.miniHuge};
  grid-row-gap: 0;

  ${MQ("tablet")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "${GRID_AREAS.SEARCH_INPUT} ${GRID_AREAS.STORE_DETAILS}"
      "${GRID_AREAS.STORE_LIST} ${GRID_AREAS.STORE_DETAILS}"
      "${GRID_AREAS.ACTIONS} ${GRID_AREAS.ACTIONS}";
  }
`;

export const StoreInput = styled.input`
  grid-area: ${GRID_AREAS.SEARCH_INPUT};
  margin-top: ${spaces.large};
  padding: ${spaces.regular};
  border: ${borderCss};
  :focus-visible {
    outline-color: ${color.green.hex};
    outline-offset: ${spaces.micro};
  }
`;

export const StoresWrapper = styled.form`
  grid-area: ${GRID_AREAS.STORE_LIST};
  padding-bottom: ${actionsHeight};
`;

export const DetailsWrapper = styled.div`
  grid-area: ${GRID_AREAS.STORE_DETAILS};
  margin-top: ${spaces.large};
  margin-bottom: ${actionsHeight};
  & > section:not(:last-of-type) {
    margin-bottom: ${spaces.big};
  }
`;

export const SaveSection = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colorsAsCssVariable.xxlWhite};
  border-top: ${borderCss};

  ${MQ("tablet")} {
    position: absolute;
  }
`;

export const SaveButtonContainer = styled.div`
  padding: ${spaces.smallRegular};
  width: 100%;
  ${MQ("tablet")} {
    margin-bottom: 0;
  }
`;

export const StoreDetails = styled.details`
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.xxlMediumGrey};
  padding-bottom: ${spaces.smallRegular};
  padding-top: ${spaces.smallRegular};

  &[open] {
    & > summary {
      padding-bottom: ${spaces.smallRegular};
      ${MQ("tablet")} {
        padding-bottom: 0;
      }
      & > span {
        transform: rotate(270deg);
      }
    }
  }
`;

export const FooterDesktop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: ${spaces.smallRegular} ${spaces.large};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spaces.smallRegular};
  width: 260px;
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
export const ListItem = styled.li`
  list-style-type: none;
  padding: 0;
  padding: ${spaces.smallRegular} 0;

  border-bottom: 1px solid ${colors.xxlLightGrey};
`;

export const StoreItem = styled(XxlStack)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  cursor: pointer;
  &::-webkit-details-marker {
    display: none;
  }

  ${MQ("tablet")} {
    cursor: default;
  }
`;
